<template>
	<div class="content">
		<div class="box paddtb30 paddlr40 boderra10 bg-white">
			<div class="fz-20 font-bold">店铺设置</div>
			<div class="mart50">
				<el-form :model="ruleForm" ref="ruleForm" label-width="110px" class="demo-ruleForm">
					<el-form-item label="店铺头像" prop="imageUrl">
						<el-upload class="avatar-uploader pos-r" action="https://admin.yixiangyipin.com/api/upload"
							:show-file-list="false" :on-success="coverSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							<div class="edit-avatar">编辑头像</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="店铺名称" prop="name">
						<el-col :span="12">
							<el-input v-model="ruleForm.store_name" placeholder="请输入店铺名称"></el-input>
						</el-col>
					</el-form-item>
					<el-form-item label="店铺介绍" prop="desc">
						<el-col :span="12">
							<el-input type="textarea" v-model="ruleForm.store_desc" placeholder="请输入店铺介绍"></el-input>
						</el-col>
					</el-form-item>
					<el-form-item label="店铺背景图" prop="bg">
						<el-col :span="14">
							<div class="flex-row flex-wrap">
								<div @click="ruleForm.store_background = item.id" class="bgtag pointer flex-row-center-start marr24"
									:class="{'bgtagactive':ruleForm.store_background == item.id}"
									v-for="(item,index) in colorList" :key="index">
									<div class="colortag" :style="('background:'+item.color)"></div>
									<span class="fz-14 marl10">{{item.txt}}</span>
								</div>
							</div>
						</el-col>
					</el-form-item>
					<el-form-item label="店铺地址" prop="desc">
						<el-col :span="12">
							<el-row>
								<el-col :span="5">
									<el-select v-model="ruleForm.store_province_id" placeholder="请选择省" @change="provinceChange">
										<el-option v-for="item in province" :key="item.name" :label="item.name"
											:value="item.id">
										</el-option>
									</el-select>
								</el-col>
								<el-col :span="5" :offset="1">
									<el-select v-model="ruleForm.store_city_id" placeholder="请选择市" @change="cityChange">
										<el-option v-for="item in city" :key="item.name" :label="item.name"
											:value="item.id">
										</el-option>
									</el-select>
								</el-col>
								<el-col :span="5" :offset="1">
									<el-select v-model="ruleForm.store_area_id" placeholder="请选择区" @change="areaChange">
										<el-option v-for="item in area" :key="item.name" :label="item.name"
											:value="item.id">
										</el-option>
									</el-select>
								</el-col>
							</el-row>
						</el-col>
					</el-form-item>
					<el-form-item label="联系方式" prop="phone">
						<el-col :span="12">
							<el-input v-model="ruleForm.store_phone" placeholder="请输入联系方式"></el-input>
						</el-col>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" class="submit" @click="submitForm('ruleForm')">保存</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				tagIndex: 0,
				colorList: [{
					id: 0,
					txt: '无色',
					color: '#ffffff'
				}, {
					id: 1,
					txt: '红色',
					color: '#ff3f26'
				}, {
					id: 2,
					txt: '黄色',
					color: '#ffc600'
				}, {
					id: 3,
					txt: '紫色',
					color: '#843bff'
				}, {
					id: 4,
					txt: '绿色',
					color: '#6ad653'
				}, {
					id: 5,
					txt: '玫红色',
					color: '#fe3f79'
				}],
				ruleForm: {
					avatar: "",
					store_name: "",
					store_desc: "",
					store_background: '',
					store_province_id: '',
					store_city_id: '',
					store_phone: '',
					store_area_id: "",
				},
				province: [],
				city: [],
				area: [],
			};
		},
		mounted() {
			let details = JSON.parse(localStorage.getItem("userinfo"));
			let info = {
				avatar: details.avatar,
				store_name: details.store_name,
				store_desc: details.store_desc,
				store_background: details.store_background,
				store_province_id: details.store_province.id,
				store_city_id: details.store_city.id,
				store_area_id: details.store_area.id,
				store_phone: details.store_phone,
			}
			this.ruleForm = info;
			this.getClassi();
		},
		methods: {
			submitForm(formName) {
				this.$api.tokenRequest({
					method: 'PUT',
					url: '/store/user',
					isLoading: '加载中...',
					data: this.ruleForm,
					success: res => {
						this.getUserInfo();
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 省选中
			provinceChange(e) {
				let item = this.province.find(v => v.id == e);
				this.ruleForm.store_province_id = item.id;
				this.city = item.children;
				this.ruleForm.store_city_id = "";
				this.area = [];
			},
			// 市选中
			cityChange(e) {
				let item = this.city.find(v => v.id == e);
				this.ruleForm.store_city_id = item.id;
				this.area = item.children;
			},
			// 区选中
			areaChange(e) {
				let item = this.area.find(v => v.id == e);
				this.params.store_area_id = item.id;
			},
			// 获取地区
			getClassi() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/api/maple/region',
					isLoading: '加载中...',
					data: {
						type: 1
					},
					success: res => {
						this.province = res.data;
						let item = this.province.find(v => v.id == this.ruleForm.store_province_id);
						this.city = item.children;
						let item1 = this.city.find(v => v.id == this.ruleForm.store_city_id);
						this.area = item1.children;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 封面图上传
			coverSuccess(res) {
				this.ruleForm.avatar = res.data;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			// 获取个人信息
			getUserInfo() {
				this.$api.tokenRequest({
					method: 'get',
					url: '/store/user',
					isLoading: '加载中...',
					success: res => {
						this.$message.success("修改成功");
						localStorage.setItem("userinfo", JSON.stringify(res.data));
						this.$store.commit("setUserInfo", res.data);
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			}
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;

		.box {
			width: 100%;
			height: 100%;
			box-sizing: border-box;
		}

		.edit-avatar {
			position: absolute;
			bottom: -22px;
			right: 0;
			left: 0;
			font-size: 12px;
			color: #ffffff;
			background: rgba(0, 0, 0, 0.5);
			line-height: normal;
			padding-bottom: 5px;
		}

		@keyframes bottom {
			0% {
				bottom: -22px;
			}

			100% {
				bottom: 0;
			}
		}

		.avatar-uploader:hover .edit-avatar {
			animation: bottom 0.5s;
			bottom: 0;
		}

		/deep/ .avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			border-radius: 50%;
		}

		/deep/ .avatar-uploader .el-upload:hover {
			border-color: #409eff;
		}

		/deep/ .avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 96px;
			height: 96px;
			line-height: 96px;
			text-align: center;
		}

		.avatar {
			width: 96px;
			height: 96px;
			display: block;
			border-radius: 50%;
		}

		.bgtag {
			background: #f8f8f8;
			border-radius: 50px;
			padding: 5px 12px;
			line-height: normal;

			.colortag {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background: #FF3F26;
			}
		}

		.bgtagactive {
			background: #fff5f4;
			border: 1px solid #FF3F26;
			color: #FF3F26;
			box-sizing: border-box;
		}

		.submit {
			width: 128px;
			height: 40px;
			background: #FF3F26;
			border-radius: 6px;
			color: #ffffff;
			border-color: transparent;
			margin-top: 100px;
		}

		/deep/ .el-form-item__label {
			text-align: left;
		}

		/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
			opacity: 0;
			margin-right: 0;
		}
	}
</style>
