<template>
  <el-drawer
    title="编辑"
    size="1000px"
    :visible.sync="drawer"
    :before-close="handleClose"
  >
    <div class="mart26 paddlr50">
      <!-- 这里放的是富文本框，暂时先用图片代替 -->
      <!-- <div v-html="html"></div> -->
      <img class="html" src="@/assets/img_22@2x.png" />
      <div>
        <el-form
          ref="form"
          :model="form"
          label-position="left"
          label-width="120px"
          :rules="rules"
          class="mart40 paddlr50"
        >
          <el-form-item label="您的姓名" prop="name">
            <el-col :span="12">
              <el-input
                v-model="form.name"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="联系方式" prop="phone">
            <el-col :span="12">
              <el-input
                v-model="form.phone"
                placeholder="请输入您的联系方式"
              ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="直播标题" prop="title">
            <el-input
              v-model="form.title"
              placeholder="请输入您的直播标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="预约时间" prop="date">
            <el-col :span="12">
              <!-- <el-date-picker
                type="date"
                placeholder="请选择预约时间"
                v-model="form.date"
                style="width: 100%"
              ></el-date-picker> -->
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="上传封面图" prop="imageUrl">
            <el-col :span="12">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-form-item>
          <el-form-item label="理由与内容" prop="desc">
            <el-input
              class="textarea"
              type="textarea"
              v-model="form.desc"
              placeholder="请说明为什么进行直播以及要直播什么"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bottom">
      <el-divider></el-divider>
      <div class="flex-row-center-center marb16">
        <el-button type="primary" class="submit" @click="onSubmit">提交</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      form: {
        name: "",
        phone: "",
        title:"",
        date: "",
        desc: "",
        imageUrl: "",
      },
      rules: {
          name: [
            { required: true, message: '请输入您的姓名', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入您的联系方式', trigger: 'change' }
          ],
          title: [
            { required: true, message: '请输入您的直播标题', trigger: 'change' }
          ],
          date: [
            { type: 'date', required: true, message: '请选择预约时间', trigger: 'change' }
          ],
        }
    };
  },
  methods: {
    show() {
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
          if (valid) {
            this.$message.success('保存成功')
            this.handleClose()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    handleAvatarSuccess(res, file) {
      this.form.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-drawer__header {
  color: #343434;
  font-weight: bold;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
  opacity: 0;
  margin-right: 0;
}
.html {
  width: 900px;
  height: 160px;
  border-radius: 10px;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 96px;
  height: 96px;
  line-height: 96px;
  text-align: center;
}
.avatar {
  width: 96px;
  height: 96px;
  display: block;
}
.textarea{
  height: 184px;
}
/deep/ .el-textarea__inner{
  height: 184px;
}
.bottom{
  position: absolute;
  bottom: 30px;
  left:0;
  width: 100%;
  .submit{
    width: 128px;
    height: 40px;
    background: #FF3F26;
    border-radius: 6px;
    border:1px solid #FF3F26;
  }
}
</style>
