<template>
	<div class="content flex-column">
		<div class="paddlr40 paddt40 paddb24 bg-white flex-1 boderra10 flex-column">
			<div class="fz-20 font-bold paddb30">数字基建</div>
			<div class="tableheader fz-15">
				<el-row>
					<el-col :span="4">
						<div class="tableheaderone flex-row-center-center">封面图</div>
					</el-col>
					<el-col :span="8">
						<div class="tableheaderone flex-row-center-center">产品名称</div>
					</el-col>
					<el-col :span="6">
						<div class="tableheaderone flex-row-center-center">价格</div>
					</el-col>
					<el-col :span="6">
						<div class="tableheaderone flex-row-center-center">操作</div>
					</el-col>
				</el-row>
			</div>
			<div class="flex-1 ">
				<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
					<template v-for="(item, index) in list">
						<div class="tableone boderb1-EEEEEE">
							<el-row>
								<el-col :span="4">
									<div class="flex-row-center-center paddtb18 table">
										<img class="cover" :src="item.good.cover" alt="" />
									</div>
								</el-col>
								<el-col :span="8">
									<div class="flex-row-center-center paddtb18 table fz-14 co-333736">
										{{item.good.title}}</div>
								</el-col>
								<el-col :span="6">
									<div class="flex-row-center-center paddtb18 table fz-14 co-333736">
										￥{{item.good.price}}
									</div>
								</el-col>
								<el-col :span="6">
									<div class="flex-row-center-center table paddtb18 fz-14 pointer co-333736"
										@click="toDetail(item)">
										查看四码
									</div>
								</el-col>

							</el-row>
						</div>
					</template>
				</ul>
			</div>
			<div class="flex-row-center-center mart28">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
					@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
					:total="total" :page-sizes="[10, 15, 20]">
				</el-pagination>
			</div>
		</div>

		<el-dialog class="reply-pop" width="400px" :visible.sync="codePop" :destroy-on-close="true" :show-close="false"
			top="25vh">
			<template slot="title"></template>
			<div class="flex-row-center-center relative">
				<iconImg src='assets/img_36.png' size='400 509' mode='fit'></iconImg>
				<div class="absolute ab-top flex-column-center-center">
					<div class="fz-21 co-343434 paddtb30 font-blod paddb30">产品四码</div>
					<iconImg status="2" :src='citem.code' size='288 288' mode='fit'></iconImg>
				</div>
				<div class="absolute ab-bottom flex-row-center-center paddb24">
					<div class="flex-row-center-center download boderra8 bg-FF3F26 fz-16 color-white pointer">我要下载</div>
				</div>
				<div class="absolute top0 right0 padd16 pointer" @click="handleClose">
					<iconImg src='assets/close_01.png' size='18 18' mode='fit'></iconImg>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,
				codePop: false,
				citem: "",
			};
		},
		mounted() {
			this.onSubmit();
		},
		methods: {
			// 加载更多
			load() {},
			// 查看四码
			toDetail(item) {
				this.citem = item;
				this.codePop = true;
			},
			// 关闭弹窗
			handleClose() {
				this.codePop = false;
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/Digital/list',
					isLoading: '加载中...',
					data: {
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.message.error(data.message);
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.tableheader {
			background: #f8f8f8;
			height: 48px;
			border-radius: 8px;
			box-sizing: border-box;

			.tableheaderone {
				color: #999999;
				line-height: 48px;
				box-sizing: border-box;
			}
		}

		.infinite-list {
			width: 100%;
			height: 100%;
			box-sizing: border-box;

			.tableone {
				width: 100%;
				box-sizing: border-box;

				.tableoneheader {
					background: #f8f8f8;
					border-radius: 8px 8px 0 0;
					padding: 12px 24px;

					.icon {
						width: 14px;
						height: 14px;
					}
				}

				.cover {
					width: 50px;
					height: 50px;
					border-radius: 8px;
				}
			}

			.table {
				box-sizing: border-box;
				height: 100px;
			}

			.changebtn {
				height: 27px;
				width: 68px;
				background: #FF3F26;
				border-radius: 6px;
				border-color: transparent;
				color: #ffffff;
				font-size: 13px;
				line-height: 27px;
				padding: 0;
			}
		}
	}

	.download {
		width: 320px;
		height: 44px;
	}
</style>
