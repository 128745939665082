<template>
	<div class="content">
		<div class="content box bg-white flex-column paddtb30 paddlr40 borra10">
			<div class="" style="height: 200px;">
				<el-scrollbar>
					<div v-html="content"></div>
				</el-scrollbar>
			</div>
			<div class="flex-1">
				<ul class="infinite-list goods-list flex-row-wrap" v-infinite-scroll="load" style="overflow:auto">
					<li v-for="(item, i) in shoplist" :key="i" class="infinite-list-item goods-list-item marr24 marb20">
						<div class="shop marb20 pos-r">
							<img class="cover" :src="item.cover" alt="" />
							<div class="paddlr12 paddtb6">
								<div class="fz-15">{{item.title}}</div>
								<div class="flex-row-center-start mart6 color-red font-bold">
									<span class="fz-12">￥</span>
									<span class="fz-18">{{item.price}}</span>
								</div>
							</div>
							<div class="chooseicon pointer flex-row-center-center" @click="chooseShop(item)">
								<img :src="citem.id == item.id ? require('@/assets/icon_14.png') : require('@/assets/icon_48@2x.png')" alt="" />
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="flex-row-center-end marb16 bottom">
				<div class="flex-row-center-start marr30">
					<div class="flex-row-center-start mart10">
						<span class="fz-12">报名费：</span>
						<span class="color-red fz-12">￥</span>
					</div>
					<span class="color-red fz-22">{{ total_price }}</span>
				</div>
				<el-button type="primary" class="submit" @click="onSubmit">提交</el-button>
			</div>
		</div>
		<payDialog ref="payRef" />
	</div>
</template>
<script>
	import payDialog from './components/payDialog.vue';
	var timer;
	
	export default {
		components: {
			payDialog
		},
		data() {
			return {
				content: "",
				page: 1,
				shoplist: [],
				total_price: "0.00",
				loading: false,
				citem: "",
				order_id: "", // 订单id
			};
		},
		mounted() {
			this.getList();
		},
		destroyed() {
			clearTimeout(timer);
		},
		methods: {
			onSubmit() {
				if (!this.citem) {
					this.$message.error('请先选择商品')
					return
				}
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/Digital',
					isLoading: '加载中...',
					data: {
						good_id: this.citem.id,
						order_type: 3,
					},
					success: res => {
						this.$api.tokenRequest({
							method: 'POST',
							url: '/store/AdOrder/pay',
							isLoading: '获取支付信息...',
							data: {
								order_id: res.data.id,
								pay_type: 2,
							},
							success: res1 => {
								this.order_id = res.data.id;
								this.$refs.payRef.show(res1.data.params.code_url, res.data.id);
								this.searchResult();
							},
							fail: data => {
								this.$message.error(data.message);
							},
						})
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			chooseShop(item) {
				this.citem = item;
				// this.total_price = item.price;
			},
			// 加载
			load() {
				this.getList();
			},
			getList() {
				if (this.loading) return;
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/digital/GoodList',
					isLoading: '加载中...',
					data: {
						page: this.page,
						size: 20
					},
					success: res => {
						this.total_price = res.data.price;
						this.content = res.data.text;
						res.data.data.forEach(item => {
							item.is_choose = false;
						})
						this.shoplist = this.page == 1 ? res.data.data : this.shoplist.concat(res.data.data);
						if (res.data.data.length > 0) {
							this.page = this.page + 1;
						};
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
			// 轮询支付结果
			searchResult() {
				if (timer) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					this.$api.tokenRequest({
						method: 'GET',
						url: '/store/AdOrder/PaySearch',
						data: {
							order_id: this.order_id
						},
						success: res => {
							clearTimeout(timer);
							this.$router.push({
								path: "/mine/digitalInfrastructure",
							});
						},
						fail: data => {
							if (data.code == 999) {
								this.searchResult();
							} else {
								this.message.error(data.message);
							}
						},
					})
				}, 3000);
			}
		},
	};
</script>
<style lang="less" scoped>
	/deep/ .el-scrollbar {
		height: 100%;
		width: 100%;
		box-sizing: border-box;
	}

	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;

		.box {
			width: 100%;
			height: 100%;

			.html {
				width: 100%;
				height: 160px;
				border-radius: 10px;
			}

			.bottom {
				padding-top: 10px;
				border-top: 1px solid #eeeeee;

				.submit {
					width: 128px;
					height: 40px;
					background: #ff3f26;
					border-radius: 6px;
					border: 1px solid #ff3f26;
				}
			}
		}
	}

	.shop {
		width: 240px;
		border: 1px solid #eeeeee;
		border-radius: 8px;
		flex-shrink: 0;
		margin-right: 12px;

		.cover {
			width: 100%;
			height: 240px;
			border-radius: 8px 8px 0 0;
		}

		.chooseicon {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 40px;

			img {
				width: 20px;
				height: 20px;
			}
		}
	}

	/deep/.el-col-4:nth-child(6n) .shop {
		margin-right: 0;
	}

	.goods-list-item {
		list-style-type: none;
	}

	.infinite-list {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
</style>
