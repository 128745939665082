<template>
	<div>
		<div class="paddtb30 paddlr40 bg-white boderra10">
			<div class="fz-20 font-bold">集采大厅</div>
			<div class="mart30 searchwrap flex-row-center-sb">
				<el-input class="flex-1" placeholder="请输入关键词进行搜索" v-model="keyword">
					<i slot="prefix" class="el-input__icon el-icon-search"></i>
				</el-input>
				<div class="searchbtn fz-14" @click="onSubmit">搜索</div>
			</div>
			<div class="mart30 flex-row fz-14">
				<div class="label color-grey fz-14">类目：</div>
				<div class="flex-1 flex-row flex-wrap">
					<span class="tag" :class="{'active':cateIndex == index}" @click="choose('cateIndex', index, item)"
						v-for="(item, index) in cate" :key="index">{{item.name}}</span>
				</div>
			</div>
			<div class="mart30 flex-row fz-14">
				<div class="label color-grey fz-14">全部：</div>
				<div class="flex-1 flex-row flex-wrap">
					<span class="tag" :class="{'active':allIndex == index}" @click="choose('allIndex', index, item)"
						v-for="(item, index) in all" :key="index">{{item.name}}</span>
				</div>
			</div>
			<div class="mart30 flex-row fz-14">
				<div class="label color-grey fz-14">收货地：</div>
				<div class="flex-1 flex-row flex-wrap area">
					<el-select v-model="province" class="tag" placeholder="请选择省" @change="provinceChange">
						<el-option v-for="item in provinceList" :key="item.name" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<el-select v-model="city" class="tag" placeholder="请选择市" @change="cityChange">
						<el-option v-for="item in cityList" :key="item.name" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<el-select v-model="area" placeholder="请选择区" @change="areaChange">
						<el-option v-for="item in areaList" :key="item.name" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="mart30 flex-row flex-wrap">
				<el-tag class="tagwrap" v-for="tag in tags" :key="tag.name" closable type="info"
					@close="handleClose(tag)">{{tag}}</el-tag>
			</div>
		</div>
		<el-row class="mart20">
			<el-col :span="6" v-for="(item,index) in list" :key="index">
				<div class="bg-white boderra10 paddtb20 paddlr24 fz-16 item" @click="toDetail(item)">
					<div class="flex-row-center-sb">
						<div class="flex-row-center-start flex-1">
							<span class="marr8">{{item.cate_text}}</span>
						</div>
						<span class="fz-12 color-grey">{{item.create_time}}</span>
					</div>
					<div class="mart6 font-bold color-red">{{item.num}}{{item.num_text}}</div>
					<div class="flex-row-center-sb mart16">
						<div class="fz-14 color-grey">
							<div>品类：{{item.cate_name}}</div>
							<div class="mart6">采购商收货地：{{item.address}}</div>
						</div>
						<div class="flex-row-center-center report color-white">前往报价</div>
					</div>
				</div>
			</el-col>
		</el-row>
		
		<div class="flex-row-center-center mart28">
			<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
				@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
				:total="total" :page-sizes="[10, 15, 20]" :page-size="size">
			</el-pagination>
		</div>
		
		<detailPop ref="detailPop" />
	</div>
</template>
<script>
	import detailPop from "./components/detailPop.vue";
	export default {
		components: {
			detailPop,
		},
		data() {
			return {
				keyword: "",
				cate: [],
				cate_id: "",
				all: [],
				province: "",
				provinceList: [],
				city: "",
				cityList: [],
				area: "",
				areaList: [],
				cateIndex: 0,
				allIndex: 0,
				tags: [], //标签数组
				list: [],
				page: 1,
				size: 20,
				total: 0,
			};
		},
		created() {},
		mounted() {
			this.getclassification();
		},
		methods: {
			// 详情
			toDetail(item) {
				this.$refs.detailPop.show(item, this.cate_id);
			},
			// 省选中
			provinceChange(e) {
				let item = this.provinceList.find(v => v.id == e);
				this.province = item.id;
				this.city = "";
				this.cityList = item.children;
				this.area = "";
				this.areaList = [];
				this.onSubmit();
			},
			// 市选中
			cityChange(e) {
				let item = this.cityList.find(v => v.id == e);
				this.city = item.id;
				this.area = "";
				this.areaList = item.children;
				this.onSubmit();
			},
			// 区选中
			areaChange(e) {
				let item = this.areaList.find(v => v.id == e);
				this.area = item.id;
				this.onSubmit();
			},
			// 分类选中
			choose(key, index, item) {
				this[key] = index;
				if (key === "cateIndex") {
					let arr = [{
						children: [],
						id: 0,
						name: "全部",
						pid: 0,
					}];
					this.all = arr.concat(item.children);
					this.allIndex = 0;
					this.tags[0] = item.name;
					this.cate_id = this.cate[this.cateIndex].id;
				} else {
					this.tags[1] = item.name;
					this.cate_id = this.all[this.allIndex].id;
				}
				this.onSubmit();
			},
			handleClose(tag) {
				// 删除标签
				this.tags.splice(this.tags.indexOf(tag), 1);
			},
			// 获取分类
			getclassification() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/api/common/cate',
					isLoading: '加载中...',
					success: res => {
						let arr = [{
							children: [],
							id: 0,
							name: "全部",
							pid: 0,
						}];
						this.cate = arr.concat(res.data);
						this.all = arr;
						this.getList();
					},
					fail: data => {
						this.message.error(data.message);
					},
				});
				
				this.$api.tokenRequest({
					method: 'GET',
					url: '/api/maple/region',
					isLoading: '加载中...',
					data: {
						type: 2
					},
					success: res => {
						this.provinceList = res.data;
					},
					fail: data => {
						this.message.error(data.message);
					},
				})
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			// 获取集采列表
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/Purchase/list',
					isLoading: '加载中...',
					data: {
						keyword: this.keyword,
						cate_id: this.cate_id || 0,
						province_id: this.province || 0,
						area_id: this.area || 0,
						city_id: this.city || 0,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.message.error(data.message);
					},
				})
			},
		}
	};
</script>
<style lang="less" scoped>
	.searchwrap {
		width: 100%;
		height: 40px;
		border-radius: 50px;
		background: #f8f8f8;

		/deep/ .el-input__inner {
			background-color: rgba(0, 0, 0, 0);
			border-color: transparent;
		}

		.searchbtn {
			height: 32px;
			background: #ff3f26;
			width: 76px;
			box-sizing: border-box;
			line-height: 32px;
			color: #ffffff;
			border-radius: 50px;
			text-align: center;
		}
	}

	.label {
		width: 88px;
	}

	/deep/ .el-row {
		min-width: 1200px;
	}

	.tag {
		margin-right: 32px;
		cursor: pointer;
	}

	.chooseicon {
		width: 6px;
		height: 6px;
		margin-left: 8px;
	}

	.active {
		color: #FF3F26;
		font-weight: bold;
	}

	.area {
		/deep/.el-input__inner {
			border-color: transparent;
			width: 80px;
			font-size: 14px;
			padding: 0;
			color: #343434;
			height: 20px;
			line-height: 20px;
		}

		/deep/.el-input__inner::-webkit-input-placeholder {
			color: #343434;
		}

		/deep/ .el-select .el-input .el-select__caret {
			color: #343434;
		}

		/deep/ .el-input__icon {
			line-height: 20px;
			width: 14px;
		}

		/deep/ .el-select .el-input.is-focus .el-input__inner {
			border-color: transparent;
		}
	}

	.tagwrap {
		border-radius: 50px;
		padding: 4px 12px;
		background: #F8F8F8;
		height: inherit;
		line-height: inherit;
		margin-right: 16px;
	}

	.report {
		width: 80px;
		height: 28px;
		background-color: #FF3F26;
		border-radius: 50px;
	}

	.item {
		margin-right: 12px;
		margin-bottom: 20px;
	}

	/deep/.el-col-6:nth-child(4n) .item {
		margin-right: 0;
	}
</style>
