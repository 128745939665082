<template>
	<div class="contentwrap">
		<div class="bg-white paddlr40 paddt30 contentwrap">
			<div class="fz-20 co-343434 font-blod">我的广告</div>
			<Ltitle class="paddt40" :list="titleList" :titleId="cid" @change="titleChange"></Ltitle>
			<Litem class="mart28" :list="itemList"></Litem>
			<div class="flex-1">
				<ul class="infinite-list" style="overflow: auto">
					<li v-for="(v, i) in list" :key="i">
						<goodsItem :render="itemList" :detail="v" :status="cid" ptype="2"
							@clickItem="clickItem($event, v, i)">
						</goodsItem>
					</li>
				</ul>
			</div>
			<div class="flex-row-center-center paddb44">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
				:total="total" :page-sizes="[10, 15, 20]">
				</el-pagination>
			</div>
		</div>

		<el-drawer ref="drawerPop" class="side-pop" :visible.sync="open" direction="rtl" :show-close="false"
			:close="handleClose" :destroy-on-close="true" size="1200px">
			<div class="fz-20 co-343434 font-blod paddl50 paddt30 paddb36 relative">
				<span>编辑</span>
				<div class="flex-row-center-center absolute top0 right0 paddr40 paddt37 pointer" @click="closePop">
					<iconImg src="assets/icon_16.png" class="marl30" size="12 12" mode="fit"></iconImg>
				</div>
			</div>
			<div class="paddl100">
				<div class="flex-row-center-start">
					<span class="fz-14 co-343434 w110">广告标题</span>
					<div class="input-area boderra6 boder1-EEEEEE flex-row-center-start">
						<input class="fullh100 boderra6 fz-14 co-343434 flex-1" v-model="params.title" placeholder="可输入产品的名称、特征、亮点"
							type="text" />
					</div>
				</div>
				<div class="mart24 flex-row-center-start">
					<span class="fz-14 co-343434 w110">广告副标题</span>
					<div class="input-area boderra6 boder1-EEEEEE">
						<input class="fullh100 boderra6 paddl20 fz-14 co-343434" v-model="params.mini_title"
							placeholder="可用一句话介绍产品和商家保障" type="text" />
					</div>
				</div>
				<div class="mart24 flex-row-center-start">
					<span class="fz-14 co-343434 w110">商品价格</span>
					<div class="flex-row-center-start">
						<div class="price-area boderra6 boder1-EEEEEE flex-row-center-start">
							<input class="flex-1 fullh100 boderra6 paddl20 fz-14 co-343434" v-model="params.good_price"
								placeholder="请输入金额" type="text" />
						</div>
						<span class="fz-14 co-343434 marl12">元</span>
					</div>
				</div>
				<div class="flex-row mart30">
					<span class="fz-14 co-343434 w110">产品封面图</span>
					<div class="">
						<div class="wh-96 relative pointer">
							<iconImg status="2" v-if="!params.image" src="assets/icon_10.png" size="96 96" mode="fit"></iconImg>
							<iconImg v-else status="2" :src="params.image" size="96 96" mode="fit"></iconImg>

							<el-upload class="avatar-uploader absolute all"
								action="https://admin.yixiangyipin.com/api/upload" :show-file-list="false"
								:on-success="coverSuccess" :before-upload="beforeAvatarUpload">
							</el-upload>

							<div class="absolute del-icon" v-if="params.image" @click="delPic(1)">
								<iconImg src="assets/icon_15.png" size="18 18" mode="fit"></iconImg>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-row mart30">
					<span class="fz-14 co-343434 w110">广告内容</span>
					<textarea class="content fz-14 co-343434 paddlr20 paddtb12" v-model="params.content"
						placeholder="可输入广告副标题"></textarea>
				</div>
				<div class="flex-row-center-start mart24 fz-14 co-343434">
					<span>广告时长</span>
					<div class="flex-row-center-start marl54 pointer" v-for="(v, i) in time_list" :key="i" @click="chooseType(v)">
						<iconImg :src="`assets/${params.type == v.id ? 'icon_14' : 'icon_144'}.png`" size="14 14" mode="fit">
						</iconImg>
						<span class="marl12">{{v.day}}({{v.price}}元)</span>
					</div>
				</div>
				<div class="flex-row-center-center mart100 h72">
					<el-button class="button" type="success" @click="save">保存</el-button>
				</div>
			</div>
		</el-drawer>
		<el-drawer class="side-pop" :visible.sync="detailopen" direction="rtl" :show-close="false" :close="handleClose"
			:destroy-on-close="true" size="1200px">
			<div class="fz-20 co-343434 font-blod paddl50 paddt30 paddb36 relative">
				<span>查看详情</span>
				<div class="flex-row-center-center absolute top0 right0 paddr40 paddt37 pointer"
					@click="detailopen = false">
					<iconImg src="assets/icon_16.png" class="marl30" size="12 12" mode="fit"></iconImg>
				</div>
				<div class="mart36">
					<div class="fz-20 font-bold">{{details.title}}</div>
					<div class="mart12 fz-14 color-grey">{{details.mini_title}}</div>
					<div class="paddr60">
						<el-divider />
					</div>
					<div class="mart12 fz-14 color-grey">{{details.content}}</div>
					<!-- <img class="detail" :src="details.image" alt="" /> -->
				</div>
			</div>
		</el-drawer>
		
		<el-dialog title="选择续费时长" :visible.sync="dialogVisible" width="640px">
			<div>
				<el-divider />
				<div class="paddl40 flex-row-wrap">
					<div class="flex-column-center-center time-item pointer marr24 marb24" :class="{'time-item-active': timeIndex == i}" v-for="(v, i) in time_list" :key="i" @click="timeChoose(i)">
						<div class="flex-row flex-align-end">
							<span class="price fz-18">{{v.price}}</span>
							<span class="unit">元</span>
						</div>
						<div class="mart2">{{v.day}}</div>
					</div>
				</div>
				<div class="flex-row-center-center marb16">
					<el-button type="primary" class="submit" @click="SubmitRenew">确认提交</el-button>
				</div>
			</div>
		</el-dialog>
		
		<payDialog ref="payRef" />
	</div>
</template>

<script>
	import payDialog from './components/payDialog.vue';
	var timer;
	
	export default {
		components: {
			payDialog
		},
		data() {
			return {
				titleList: [{
						id: 2,
						title: "审核中",
					},
					{
						id: 1,
						title: "展示中",
					},
					{
						id: 0,
						title: "已过期",
					},
					{
						id: 3,
						title: "被下架",
					}
				],
				cid: 2,
				itemList: [{
						title: "广告封面图",
						flex: 1,
						key: "image",
						type: 2,
					},
					{
						title: "广告标题",
						flex: 1.5,
						key: "title",
						type: 5,
					},
					{
						title: "广告副标题",
						flex: 1.5,
						key: "mini_title",
						type: 4,
					},
					{
						title: "商品单价",
						flex: 1,
						key: "good_price",
						bold: true,
						type: 1,
					},
					{
						title: "状态",
						flex: 1,
						key: "status",
						type: 6,
					},
					{
						title: "操作",
						flex: 1.5,
						key: "params6",
						type: 3,
					},
				],
				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false, // 接口加载状态
				open: false, // 编辑弹窗
				detailopen: false, // 详情弹窗
				time_list: [],
				params: {
					title: "",
					mini_title: "",
					content: "",
					image: [],
					phone: "",
					good_price: "",
					type: "",
					order_type: 1,
				},
				details: "",
				dialogVisible: false,
				timeIndex: -1,
				citem: "",
				order_id: "",
			};
		},
		watch: {
			$route: {
				handler: function(val) {
					if (val.query.status) {
						this.cid = val.query.status;
					} else {
						this.cid = 2;
					}
				},
				// 深度观察监听
				deep: true,
				immediate: true,
			},
		},
		mounted() {
			this.onSubmit();
			this.getData();
		},
		destroyed() {
			clearTimeout(timer);
		},
		methods: {
			// 续费时长显示
			timeChoose(i) {
				this.timeIndex = i;
			},
			// 确认续费
			SubmitRenew() {
				if (timer) {
					clearTimeout(timer);
				}
				if (this.timeIndex == -1) {
					this.$message.error("请选择续费时长");
					return false;
				}
				this.loading = true;
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/UserAd/renew',
					isLoading: '加载中...',
					data: {
						id: this.citem.id,
						type: this.time_list[this.timeIndex].id,
					},
					success: res => {
						this.$api.tokenRequest({
							method: 'POST',
							url: '/store/AdOrder/pay',
							isLoading: '加载中...',
							data: {
								order_id: res.data.id,
								pay_type: 2,
							},
							success: res1 => {
								this.order_id = res.data.id;
								this.loading = false;
								this.$refs.payRef.show(res1.data.params.code_url, res.data.id);
								this.searchResult();
							},
							fail: data => {
								this.loading = false;
								this.$message.error(data.message);
							},
						})
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			// 改变状态
			titleChange(v) {
				this.cid = v.id;
				this.onSubmit();
			},
			// 商品操作
			clickItem(e, v, i) {
				if (e == 3) {
					this.$api.tokenRequest({
						method: 'GET',
						url: '/store/UserAd/detail',
						isLoading: '加载中...',
						data: {
							id: v.id,
						},
						success: res => {
							let params = {
								id: v.id,
								title: res.data.title,
								mini_title: res.data.mini_title,
								content: res.data.content,
								image: res.data.image,
								phone: res.data.phone,
								good_price: res.data.good_price,
								type: res.data.type.id,
								order_type: 1,
							};
							this.params = params;
							this.open = true;
						},
						fail: data => {
							this.message.error(data.message);
						},
					})
				} else if (e == 4) {
					this.$api.tokenRequest({
						method: 'GET',
						url: '/store/UserAd/detail',
						isLoading: '加载中...',
						data: {
							id: v.id,
						},
						success: res => {
							this.details = res.data;
							this.detailopen = true;
						},
						fail: data => {
							this.message.error(data.message);
						},
					})
				} else if (e == 5) {
					// 续费
					this.$confirm("是否确认续费", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(() => {
						this.citem = v;
						this.dialogVisible = true;
					});
				}
			},
			// 关闭弹窗
			handleClose() {
				this.open = false;
			},
			// 关闭弹窗
			closePop() {
				this.open = false;
				this.$refs.drawerPop.closeDrawer();
			},
			// 删除图片
			delPic(type, i) {
				if (type == 1) {
					this.params.image = "";
				} else if (type == 2) {
					this.banner.splice(i, 1);
				} else if (type == 3) {
					this.detailPic.splice(i, 1);
				}
			},
			// 选择推荐类型
			chooseType(v) {
				this.params.type = v.id;
			},
			// 封面图上传
			coverSuccess(res) {
				this.params.image = res.data;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error("上传头像图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传头像图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			// 保存
			save() {
				if (!this.params.title) {
					this.$message.error('请输入标题!');
					return
				}
				if (!this.params.mini_title) {
					this.$message.error('请输入副标题!');
					return
				}
				if (!this.params.good_price) {
					this.$message.error('请输入商品价格!');
					return
				}
				if (this.params.image.length == 0) {
					this.$message.error('请上传封面图片!');
					return
				}
				if (!this.params.content) {
					this.$message.error('请输入正文!');
					return
				}
				if (!this.params.type) {
					this.$message.error('请选择广告时长!');
					return
				}
				this.$api.tokenRequest({
					method: 'PUT',
					url: '/store/UserAd/Myad',
					isLoading: '加载中...',
					data: this.params,
					success: res => {
						this.closePop();
						this.$api.tokenRequest({
							method: 'POST',
							url: '/store/AdOrder/pay',
							isLoading: '加载中...',
							data: {
								order_id: res.data.id,
								pay_type: 2,
							},
							success: res1 => {
								this.loading = false;
								this.$refs.payRef.show(res1.data.params.code_url);
							},
							fail: data => {
								this.loading = false;
								this.$message.error(data.message);
							},
						})
						// this.onSubmit();
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/UserAd/mylist',
					isLoading: '加载中...',
					data: {
						status: this.cid,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
			// 获取发布信息
			getData() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/UserAd/adType',
					isLoading: '加载中...',
					success: res => {
						res.data.forEach(v => {
							v.price = Number(v.price);
							v.day = `${v.day}天广告`;
						})
						this.time_list = res.data;
					},
					fail: data => {
						this.message.error(data.message);
					},
				})
			},
			// 轮询支付结果
			searchResult() {
				if (timer) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					this.$api.tokenRequest({
						method: 'GET',
						url: '/store/AdOrder/PaySearch',
						data: {
							order_id: this.order_id
						},
						success: res => {
							clearTimeout(timer);
							this.$refs.payRef.handleClose();
							this.onSubmit();
						},
						fail: data => {
							if (data.code == 999) {
								this.searchResult();
							} else {
								this.message.error(data.message);
							}
						},
					})
				}, 3000);
			},
		},
	};
</script>

<style lang="less" scoped>
	.contentwrap {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
	}

	.infinite-list {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}

	/deep/.paging .btn-prev {
		width: 30px;
		height: 30px;
		background: #ffffff;
		border: 1px solid #eeeeee;
		border-radius: 4px;
	}

	/deep/.paging .btn-next {
		width: 30px;
		height: 30px;
		background: #ffffff;
		border: 1px solid #eeeeee;
		border-radius: 4px;
	}

	/deep/.paging .number {
		width: 30px;
		height: 30px;
		background: #ffffff !important;
		border: 1px solid #eeeeee;
		border-radius: 4px;
		font-size: 13px;
		color: #343434;
		font-weight: normal;
	}

	/deep/.paging .active {
		width: 30px;
		height: 30px;
		background: #ffffff !important;
		border: 1px solid #ff3f26 !important;
		color: #ff3f26 !important;
		border-radius: 4px;
		font-size: 13px;
		font-weight: normal;
	}

	/deep/.paging .el-pager li {
		width: 30px;
		height: 30px;
		border-radius: 4px;
		border: 1px solid #eeeeee;
		background: #ffffff !important;
	}

	/deep/.paging .el-pager li:hover {
		color: #ff3f26 !important;
	}

	/deep/.paging .el-pagination__sizes {
		line-height: 30px;
	}

	/deep/.paging .el-pagination__sizes .el-input {
		width: 94px;
		height: 30px;
		margin: 0;
		margin-left: 8px;
	}

	/deep/.paging .el-input__inner {
		height: 30px;
	}

	/deep/.paging .el-pagination__jump {
		margin-left: 16px;
	}

	/deep/.paging .el-pagination__jump .el-input {
		height: 30px;
		margin-left: 8px;
		margin-right: 8px;
	}

	/deep/.side-pop .el-drawer__header {
		margin: 0;
		padding: 0;
	}

	.input-area {
		width: 70%;
		height: 44px;
	}

	.price-area {
		width: 320px;
		height: 44px;
	}

	.content {
		width: 60%;
		height: 184px;
		border: 1px solid #eeeeee;
		border-radius: 6px;
		resize: none;
		outline: none;
	}

	.button {
		width: 128px;
		height: 40px;
		background: #ff3f26;
		border: 0;
	}

	input {
		outline: none;
		padding: 0;
		margin: 0;
		border: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	::-webkit-input-placeholder {
		/* WebKit browsers，webkit内核浏览器 */
		color: #999999;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #999999;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #999999;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #999999;
	}

	.avatar-uploader {
		opacity: 0;
	}

	.del-icon {
		top: -9px;
		right: -9px;
	}

	.detail {
		width: 990px;
	}
	
	.submit {
		width: 128px;
		height: 40px;
		background: #ff3f26;
		border-radius: 6px;
		border: 1px solid #ff3f26;
	}
	
	.time-item {
		width: 144px;
		height: 70px;
		background: #F8F8F8;
		border-radius: 5px;
		font-size: 12px;
	}
	
	.time-item-active {
		width: 142px;
		height: 68px;
		background: rgba(255, 63, 38, 0.05);
		border-radius: 5px;
		border: 1px solid #FF3F26;
		color: #FF3F26;
	}
	
	.price {
		line-height: 17px;
	}
	
	.unit {
		line-height: 12px;
	}
</style>
