<template>
	<div>
		<el-drawer size="1000px" :visible.sync="drawer" :before-close="handleClose">
			<template slot="title">
				<span class="paddl50 paddr50 paddt30 paddb36 fz-20 co-343434 font-blod">集采报价</span>
			</template>
			<div class="paddlr80" v-if="details">
				<div class="paddt16 paddb15 paddl28 details">
					<div class="flex-row-center-sb">
						<span class="fz-16 co-343434 font-blod">{{details.cate_text}}</span>
						<div class="label flex-row-center-center fz-12 color-white font-blod">集采商品</div>
					</div>
					<div class="flex-row flex-align-end co-FF3F26 font-blod mart8">
						<span class="fz-22 l16">{{details.num}}</span>
						<span class="fz-16 l16">{{details.num_text}}</span>
					</div>
					<div class="flex-row-center-start fz-14 co-999999 mart14">
						<span>品类：{{details.cate_name}}</span>
						<span class="marl42">采购商收货地：江苏无锡</span>
					</div>
				</div>
				<div class="paddt30 fz-14 co-343434 paddb22 font-blod">请选择已上架商品进行展示报价</div>
				<div class="" style="height: 500px;">
					<ul class="infinite-list goods-list flex-row-wrap" v-infinite-scroll="load" style="overflow:auto">
						<li v-for="(v, i) in list" :key="i" class="infinite-list-item goods-list-item marr20 marb20">
							<div class="relative">
								<img class="item-cover" :src="v.cover" alt="">
								<div class="absolute top0 right0 padd10 pointer" @click="chooseItem(v)">
									<img class="item-cover-icon"
										:src="require(`@/${citem.id == v.id ? 'assets/icon_14.png' : 'assets/icon_48@2x.png'}`)"
										alt="">
								</div>
							</div>
							<div class="paddt8 paddl12 paddr12">
								<span class="fz-15 co-343434">{{v.title}}</span>
							</div>
							<div class="flex-row flex-align-end co-FF1717 paddb8 mart8 paddl12 font-blod">
								<span class="fz-12 l12">￥</span>
								<span class="fz-18 l16">{{v.price}}</span>
							</div>
						</li>
					</ul>
				</div>
				<div class="flex-row-center-start fz-14 co-343434">
					<span class="w96">报价金额</span>
					<div class="flex-row-center-start input-area paddlr20">
						<input class="flex-1 h44 fz-14 price-input" v-model="price" type="text" placeholder="请设置优惠后金额">
					</div>
					<span class="marl12">元</span>
				</div>
				<div class="flex-row mart24 fz-14 co-343434">
					<span class="w96 mart14">价格说明</span>
					<textarea class="desc-textarea fz-14" v-model="desc" placeholder="对输入的价格进行说明，让采购方安心、放心"></textarea>
				</div>
			</div>
			
			<div class="h100"></div>

			<div class="bottom">
				<el-divider></el-divider>
				<div class="flex-row-center-center marb16">
					<el-button type="primary" class="submit" @click="onSubmit">立即报价</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				drawer: false,
				details: "",
				page: 1,
				list: [],
				loading: false,
				count: 20,
				citem: "",
				price: "",
				desc: "",
			};
		},
		methods: {
			show(val) {
				this.page = 1;
				this.list = [];
				this.details = val;
				this.drawer = true;
				this.getList();
			},
			handleClose() {
				this.drawer = false;
			},
			onSubmit() {
				if (!this.citem) {
					this.$message.error("请选择报价商品");
					return false;
				}
				if (!this.price) {
					this.$message.error("请输入报价金额");
					return false;
				}
				if (!this.desc) {
					this.$message.error("请输入报价说明");
					return false;
				}
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/Purchase',
					isLoading: '加载中...',
					data: {
						good_id: this.citem.id,
						purchase_id: this.details.id,
						price: this.price,
						desc: this.desc,
					},
					success: res => {
						this.$message.success("报价成功");
						this.$emit("offerSuccess", {});
						this.handleClose();
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			chooseItem(item) {
				this.citem = item;
			},
			// 加载更多
			load() {
				if (this.loading) return;
				this.getList();
			},
			// 获取商品列表
			getList() {
				if (this.loading) return
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/good',
					isLoading: '加载中...',
					data: {
						status: 1,
						page: this.page,
						size: 10,
						cate_id: this.details.cate_id,
					},
					success: res => {
						this.loading = false;
						this.list = this.page == 1 ? res.data.data : this.list.concat(res.data.data);
						if (res.data.data.length > 0) {
							this.page = this.page + 1;
						}
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.details {
		background: linear-gradient(90deg, #FFF9F8 0%, #FFFFFF 100%);
		border-radius: 10px;
	}

	/deep/.el-drawer__header {
		padding: 0;
		padding-right: 50px;
		margin: 0;
	}

	.label {
		width: 64px;
		height: 23px;
		background: #FF3F26;
		border-radius: 4px;
	}

	.label-01 {
		background: #FF3F26;
	}

	.label-02 {
		background: #FFA826;
	}

	.label-03 {
		background: #7D6AF4;
	}

	.detail-item {
		width: 50%;
	}

	.bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		.submit {
			width: 128px;
			height: 40px;
			background: #ff3f26;
			border-radius: 6px;
			border: 1px solid #ff3f26;
		}
	}

	.goods-list-item {
		list-style-type: none;
		width: 160px;
		border-radius: 8px;
		border: 1px solid #EEEEEE;
	}

	.item-cover {
		width: 160px;
		height: 160px;
		background: #FFFFFF;
		border-radius: 8px 8px 0px 0px;
	}

	.item-cover-icon {
		width: 20px;
		height: 20px;
	}

	.infinite-list {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	
	.input-area {
		width: 280px;
		height: 44px;
		border-radius: 6px;
		border: 1px solid #EEEEEE;
	}
	
	.price-input {
		padding: 0;
		margin: 0;
		outline: none;
		border: 0;
	}
	
	.desc-textarea {
		width: 560px;
		height: 68px;
		border-radius: 6px 6px 6px 6px;
		border: 1px solid #EEEEEE;
		resize: none;
		outline: none;
		padding: 14px 20px;
	}
</style>
