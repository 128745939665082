<template>
	<div>
		<div class="fz-20 font-bold">订单详情</div>
		<div class="mart24 paddtb24 paddlr40 bg-white boderra10">
			<div class="fz-16 font-bold">订单状态</div>
			<div class="mart50 stwpwrap">
				<el-row v-if="(details.status != -10)">
					<el-col :span="6" v-for="(e, i) in stepList" :key="i">
						<div class="stepwrap">
							<img class="bg"
								:src="details.status >= e.id ? e.id == 40 ? require('@/assets/icon_47@2x.png') : e.id == 20 || e.id == 30 ? require('@/assets/icon_46_@2x.png') : require('@/assets/icon_44@2x.png') : e.id == 40 ? require('@/assets/icon_46@2x.png') : require('@/assets/icon_45@2x.png')"
								alt="" />
							<div class="stepbox flex-row-center-center">
								<div class="step flex-row-center-center fz-16"
									:class="{ stepactive: details.status >= e.id }">
									{{ i + 1 }}
								</div>
								<div class="marl8 fz-16 color-grey" :class="{ txtactive: details.status >= e.id }">
									{{e.title}}
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
				<el-row v-else>
					<el-col :span="12">
						<div class="stepwrap">
							<img class="bg" src="@/assets/icon_48_@2x.png" alt="" />
							<div class="stepbox color-white flex-row-center-center">
								<div class="step step2 flex-row-center-center fz-16">
									1
								</div>
								<div class="marl8 fz-16 color-white">
									待商家处理
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="stepwrap">
							<img class="bg" src="@/assets/icon_49_@2x.png" alt="" />
							<div class="stepbox color-white flex-row-center-center">
								<div class="step step2 flex-row-center-center fz-16">
									2
								</div>
								<div class="marl8 fz-16 color-white">
									拒绝退款
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="mart20 mart24 paddtb24 paddlr40 bg-white boderra10 fz-14" v-if="details">
			<el-row>
				<el-col :span="8">
					<div class="fz-16 font-bold marb24">退款信息</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">售后方式</span>
						<span>{{details.refund_type_text}}</span>
					</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">退款原因</span>
						<span>{{details.refund_reason.name}}</span>
					</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">商品金额</span>
						<span>￥{{details.good_price}}</span>
					</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">订单运费</span>
						<span>￥{{details.express_price}}</span>
					</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">退款单号</span>
						<span>{{details.order_sn}}</span>
					</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">申请时间</span>
						<span>￥{{details.create_time}}</span>
					</div>
					<div class="paddb16 flex-row-center-start font-bold">
						<span class="label" style="color: #343434">退款总额</span>
						<span class="fz-18 color-red">￥{{details.price}}</span>
					</div>
					<div class="mart20 flex-row-center-start" v-if="details.status == 10">
						<el-button class="reject flex-row-center-center" type="default" @click="showPopup(1)">拒绝售后
						</el-button>
						<el-button class="agree flex-row-center-center marl24" type="default" @click="showPopup(2)">同意售后
						</el-button>
					</div>
					<div class="mart20 flex-row-center-start" v-if="details.status == 30 && details.refund_type == 2">
						<el-button class="agree flex-row-center-center" type="default" @click="showPopup(3)">确认收货
						</el-button>
					</div>
					<div class="mart20 flex-row-center-start" v-if="details.status == 40 && details.refund_type == 2">
						<el-button class="reject flex-row-center-center" @click="$refs.expressRef.show(details)">查看物流
						</el-button>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="fz-14 font-bold marb24"></div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">售后说明</span>
						<span>{{details.refund_desc}}</span>
					</div>
					<div class="paddb16 flex-row">
						<span class="label">退款凭证</span>
						<div class="flex-1 flex-row-center-start flex-wrap">
							<img class="cover" v-for="(e,i) in details.refund_image" :key="i" :src="e" alt="" />
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="mart20 mart24 paddtb24 paddlr40 bg-white boderra10 fz-14" v-if="(active == 5)">
			<div class="fz-16 font-bold marb24">寄回信息</div>
			<div class="flex-row-center-start fz-14">
				<span class="font-bold marr16">江大小s</span>
				<span class="marr16">江苏省无锡市滨湖区蠡湖大道江南大学</span>
				<span class="marr30">1357656765</span>
				<div class="flex-row-center-start color-red pointer" @click="$refs.expressRef.show(details)">
					<span>查看物流</span>
					<img class="arrow" src="@/assets/icon_39@2x.png" alt="" />
				</div>
			</div>
		</div>
		<div class="mart20 mart24 paddtb24 paddlr40 bg-white boderra10 fz-14" v-if="details">
			<div class="fz-16 font-bold marb24">商品信息</div>
			<div class="tablewrap">
				<div class="tableheader fz-15">
					<el-row>
						<el-col :span="8">
							<div class="tableheaderone flex-row-center-center">商品</div>
						</el-col>
						<el-col :span="4">
							<div class="tableheaderone flex-row-center-center">规格说明</div>
						</el-col>
						<el-col :span="4">
							<div class="tableheaderone flex-row-center-center">数量</div>
						</el-col>
						<el-col :span="4">
							<div class="tableheaderone flex-row-center-center">单价</div>
						</el-col>
						<el-col :span="4">
							<div class="tableheaderone flex-row-center-center">运杂费</div>
						</el-col>
					</el-row>
				</div>
				<el-row>
					<el-col :span="8">
						<div class="flex-row paddtb20 paddlr24 table">
							<img class="cover" :src="details.goods.cover" alt="" />
							<div class="marl10 fz-14">{{details.goods.title}}</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
							<div>
								<div v-for="(v, i) in details.spec" :key="i">{{v.spec_name}}</div>
							</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
							<div>
								<div v-for="(v, i) in details.spec" :key="i">x{{v.number}}</div>
							</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
							<div>
								<div v-for="(v, i) in details.spec" :key="i">￥{{v.price}}</div>
							</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
							<div>
								<div>￥{{details.express_price}}</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<checkExpress ref="expressRef" />
		<editAddress ref="editAddress" @chang="fillInAddress" v-if="details.status == 10" />
	</div>
</template>
<script>
	import checkExpress from './components/checkExpress.vue';
	import editAddress from './components/editAddress.vue';
	export default {
		components: {
			checkExpress,
			editAddress
		},
		data() {
			return {
				id: "",
				details: "",
				active: 1,
				statusInfo: {
					txt: "",
					desc: "",
				},
				stepList: [{
					id: 10,
					title: "待商家处理",
				},
				{
					id: 20,
					title: "待买家寄回",
				},
				{
					id: 30,
					title: "待卖家收货",
				},
				{
					id: 40,
					title: "退款成功",
				}],
			};
		},
		watch: {
			$route: {
				handler: function(val, oldVal) {
					this.id = val.query.id;
					this.getData();
				},
				// 深度观察监听
				deep: true,
				immediate: true,
			},
		},
		methods: {
			showPopup(type) {
				let title = '',
					yesText = '',
					api = "",
					method = "",
					data = ""
				if (type == 1) {
					title = '是否确定拒绝售后';
					yesText = '拒绝成功';
					method = "POST";
					api = "/store/refund/examine";
					data = {
						id: this.id,
						status: 2
					};
				} else if (type == 2) {
					title = '是否确定同意售后';
					yesText = '已同意';
					method = "POST";
					api = "/store/refund/examine";
					data = {
						id: this.id,
						status: 1
					};
				} else if (type == 3) {
					title = '是否确认收货';
					yesText = '收货成功';
					method = "POST";
					api = "/store/refund/receipt";
					data = {
						order_id: this.id,
					};
				}
				this.$confirm(title, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (type == 2) {
						if (this.details.refund_type == 1) {
							this.$api.tokenRequest({
								method: 'POST',
								url: '/store/refund/examine',
								isLoading: '加载中...',
								data: {
									id: this.id,
									status: 1
								},
								success: res => {
									this.$message.success("已同意");
									this.getData();
								},
								fail: data => {
									this.$message.error(data.message);
								},
							})
						} else {
							this.$refs.editAddress.show();
						}
					} else {
						this.$api.tokenRequest({
							method: method,
							url: api,
							isLoading: '加载中...',
							data: data,
							success: res => {
								this.$message.success(yesText);
								this.getData();
							},
							fail: data => {
								this.$message.error(data.message);
							},
						})
					}
				})
			},
			// 填写寄回地址
			fillInAddress(data) {
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/refund/examine',
					isLoading: '加载中...',
					data: {
						id: this.id,
						status: 1
					},
					success: res => {
						this.$api.tokenRequest({
							method: 'POST',
							url: '/store/refund/sendAddress',
							isLoading: '加载中...',
							data: {
								order_id: this.id,
								...data,
							},
							success: res => {
								this.$refs.editAddress.handleClose();
								this.getData();
							},
							fail: data => {
								this.$message.error(data.message);
							},
						})
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 获取详情
			getData() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/refund/detail',
					isLoading: '加载中...',
					data: {
						id: this.id,
					},
					success: res => {
						this.details = res.data;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			}
		},
	};
</script>
<style scoped lang="less">
	.stepwrap {
		width: 100%;
		height: 40px;
		position: relative;

		.bg {
			width: 100%;
			height: 100%;
		}

		.stepbox {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			.step {
				width: 24px;
				height: 24px;
				background: #bbbbbb;
				border-radius: 50%;
				color: #ffffff;
			}

			.step2 {
				color: #bbbbbb;
				background: #ffffff;
			}

			.stepactive {
				background: #ffffff;
				color: #ff3f26;
			}

			.txtactive {
				color: #ffffff;
			}
		}
	}

	.cover {
		width: 80px;
		height: 80px;
		border-radius: 8px;
		margin-right: 8px;
	}

	.reject {
		width: 128px;
		height: 40px;
		border: 1px solid #ff3f26;
		border-radius: 6px;
		color: #ff3f26;
	}

	.agree {
		width: 128px;
		height: 40px;
		border-radius: 6px;
		color: #ffffff;
		background: #ff3f26;
		border-color: transparent;
	}

	.label {
		color: #999999;
		font-size: 14px;
		width: 100px;
	}

	.icon {
		width: 14px;
		height: 14px;
	}

	.arrow {
		width: 24px;
		height: 8px;
	}

	.tablewrap {
		border: 1px solid #eeeeee;
		border-radius: 8px;
	}

	.tableheader {
		background: #f8f8f8;
		height: 48px;
		border-radius: 8px;
		box-sizing: border-box;

		.tableheaderone {
			color: #999999;
			line-height: 48px;
			box-sizing: border-box;
		}
	}

	.cover {
		width: 60px;
		height: 60px;
		border-radius: 8px;
	}

	.table {
		border-right: 1px solid #eeeeee;
		box-sizing: border-box;
		height: 100px;
		box-sizing: border-box;
	}
</style>
