import config from "@/api/config.js";
import axios from "axios";

import {
	Loading,
	Message
} from 'element-ui';

let jumpTo = "";
let toLoginNum = 0;

// 调用接口封装
let request = (params) => {
	if (!params.method) {
		params.method = config.method;
	}

	if (!params.url) {
		console.log("未配置请求接口路径");
		return false
	}
	params.method = params.method.toLocaleUpperCase(); // 请求类型
	params.url = config.request_host + params.url;
	let loading = ""
	if (params.isLoading) {
		loading = Loading.service({
			lock: true,
			text: params.isLoading,
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		});
	}
	if (params.method == 'GET') {
		params.params = params.data || {}
	}
	axios(params).then(res => {
		if (params.isLoading) {
			loading.close();
		}
		if (res.data.code == 200) {
			params.success && typeof params.success === 'function' && params.success(res.data);
		} else {
			if (res.data.code == 4002) {
				// 授权失效,身份过期
				localStorage.removeItem("token");
				if (toLoginNum == 0) {
					toLoginNum = 1;
					jumpTo.replace({
						path: '/'
					})
				}
				// let loginData = localStorage.getItem("account");
				// if (loginData) {
				// 	// 保存密码刷新token
				// 	let account = JSON.parse(loginData);
				// 	request({
				// 		method: 'post',
				// 		url: '/store/login',
				// 		isLoading: '加载中...',
				// 		data: {
				// 			phone: account.account,
				// 			password: account.pass,
				// 		},
				// 		success: res => {
				// 			localStorage.setItem("token", res.data.token);
				// 			tokenRequest(params);
				// 		},
				// 		fail: data => {
				// 			console.log("刷新token报错信息", data);
				// 			Message.error(data.message);
				// 		},
				// 	})
				// } else {
				// 	// 没有保存密码跳转登录
				// 	if (toLoginNum == 0) {
				// 		toLoginNum = 1;
				// 		jumpTo.replace({
				// 			path: '/'
				// 		})
				// 	}
				// }
			} else if (res.data.code == 4001) {
				// 授权认证失败
				localStorage.removeItem("token");
				if (toLoginNum == 0) {
					toLoginNum = 1;
					jumpTo.replace({
						path: '/'
					})
				}
			} else {
				params.fail && typeof params.fail === 'function' && params.fail(res.data);
			}
		}
	}).catch(res => {
		if (params.isLoading) {
			loading.close();
		}
		params.fail && typeof params.fail === 'function' && params.fail(res);
	});
}

let tokenRequest = (params) => {
	let token = localStorage.getItem('token');
	if (!token) {
		console.log("未获取到token,请检查登录或刷新token部分的代码");
		return false
	}
	params.headers = {
		// 'Content-Type': 'multipart/form-data',
		// 'Content-Type': 'application/json',
		"Authorization": token,
	};
	request(params);
}

// 初始化路由跳转
let initJump = (params) => {
	jumpTo = params;
}

export default {
	request: request,
	tokenRequest: tokenRequest,
	initJump: initJump,
}
