<template>
	<div class="content flex-column">
		<div class="paddlr40 paddt40 paddb24 bg-white flex-1 boderra10 flex-column">
			<div class="fz-20 font-bold paddb30">资金管理</div>
			<div class="relative marb36">
				<img class="top_bg w100" src="@/assets/img_37.png" alt="">
				<div class="absolute all paddl100 flex-row-center-start">
					<div class="flex-column-center-center">
						<span class="fz-16 co-999999">账户总资金(元)</span>
						<div class="flex-row flex-align-end co-343434 mart6 font-blod">
							<span class="fz-22 l22">￥</span>
							<span class="fz-32 l32">{{details.all_money}}</span>
						</div>
					</div>
					<div class="flex-column-center-center paddl168">
						<span class="fz-16 co-999999">可提现(元)</span>
						<div class="flex-row flex-align-end co-343434 mart6 font-blod">
							<span class="fz-22 l22">￥</span>
							<span class="fz-32 l32">{{details.money}}</span>
						</div>
					</div>
					<div class="flex-column-center-center paddl168">
						<span class="fz-16 co-999999">待到账(元)</span>
						<div class="flex-row flex-align-end co-343434 mart6 font-blod">
							<span class="fz-22 l22">￥</span>
							<span class="fz-32 l32">{{details.recorded_money}}</span>
						</div>
					</div>
					<div class="flex-column-center-center paddl168">
						<span class="fz-16 co-999999">手续费</span>
						<div class="flex-row flex-align-end co-343434 mart6 font-blod">
							<span class="fz-32 l32">{{service_charge}}</span>
							<span class="fz-22 l22">%</span>
						</div>
					</div>
					<div class="flex-column-center-center paddl104">
						<span class="fz-16 co-999999 btn-top">待到账(元)</span>
						<div class="flex-row-center-center mart6">
							<div class="flex-row-center-center color-white fz-14 manage-btn bg-FF3F26 boderra6 pointer"
								@click="showPop(1)">
								银行卡管理
							</div>
							<div class="flex-row-center-center co-FF3F26 fz-14 withdrawal-btn boderra6 marl20 pointer"
								@click="showPop(2)">
								提现
							</div>
						</div>
					</div>
				</div>
			</div>
			<Ltitle class="marb24" :list="titleList" :titleId="cid" @change="titleChange"></Ltitle>
			<div class="tableheader fz-15">
				<el-row v-if="cid == 1">
					<el-col :span="8">
						<div class="tableheaderone flex-row-center-center">资金类型</div>
					</el-col>
					<el-col :span="8">
						<div class="tableheaderone flex-row-center-center">时间</div>
					</el-col>
					<el-col :span="8">
						<div class="tableheaderone flex-row-center-center">金额</div>
					</el-col>
				</el-row>
				<el-row v-else-if="cid == 2">
					<el-col :span="6">
						<div class="tableheaderone flex-row-center-center">提现时间</div>
					</el-col>
					<el-col :span="6">
						<div class="tableheaderone flex-row-center-center">金额</div>
					</el-col>
					<el-col :span="6">
						<div class="tableheaderone flex-row-center-center">提现凭证</div>
					</el-col>
					<el-col :span="6">
						<div class="tableheaderone flex-row-center-center">状态</div>
					</el-col>
				</el-row>
			</div>
			<div class="flex-1 ">
				<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
					<template v-for="(item, index) in list">
						<div class="tableone boderb1-EEEEEE">
							<el-row>
								<el-col :span="cid == 1 ? 8 : 6">
									<div class="flex-row-center-center table fz-14 co-343434">
										{{cid == 1 ? item.title : item.create_time}}
									</div>
								</el-col>
								<el-col :span="cid == 1 ? 8 : 6">
									<div class="flex-row-center-center table fz-14 co-343434">
										{{cid == 1 ? item.create_time : item.money}}
									</div>
								</el-col>
								<el-col :span="cid == 1 ? 8 : 6" v-if="cid == 2">
									<div class="flex-row-center-center table fz-14 co-1890FF" @click="showPic(item)">
										{{item.image ? '查看' : '--'}}
									</div>
								</el-col>
								<el-col :span="cid == 1 ? 8 : 6">
									<div class="flex-row-center-center table fz-14 co-343434 font-blod">
										{{cid == 1 ? `${item.account_type == 1 ? '-' : '+'}${item.money}` : item.status_text}}
									</div>
								</el-col>
							</el-row>
						</div>
					</template>
				</ul>
			</div>
			<div class="flex-row-center-center mart28">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
					@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
					:total="total" :page-sizes="[10, 15, 20]">
				</el-pagination>
			</div>
		</div>

		<el-dialog class="reply-pop" width="640px" :visible.sync="managePop" :destroy-on-close="true"
			:show-close="false" top="25vh">
			<template slot="title"></template>
			<div class="paddlr24">
				<div class="flex-row-center-sb boderb1-EEEEEE paddtb24">
					<span class="co-343434 fz-18 font-blod">银行卡</span>
					<div class="pointer" @click="handleClose(1)">
						<iconImg src='assets/close_01.png' size='18 18' mode='fit'></iconImg>
					</div>
				</div>
				<div v-if="showtype == 1">
					<div class="flex-row-center-start mart30">
						<span class="fz-14 co-343434 w80">姓名</span>
						<div class="flex-row-center-center pop-input h44 boder1-EEEEEE boderra6">
							<input type="text" class="flex-1 paddlr20 fz-14 pop-input-item h44 boderra6"
								placeholder="请填写姓名" v-model="params.bank_username">
						</div>
					</div>
					<div class="flex-row-center-start mart24">
						<span class="fz-14 co-343434 w80">银行卡号</span>
						<div class="flex-row-center-center pop-input h44 boder1-EEEEEE boderra6">
							<input type="text" class="flex-1 paddlr20 fz-14 pop-input-item h44 boderra6"
								placeholder="请填写银行卡号" v-model="params.bank_number">
						</div>
					</div>
					<div class="flex-row-center-start mart24">
						<span class="fz-14 co-343434 w80">开户行</span>
						<div class="flex-row-center-center pop-input h44 boder1-EEEEEE boderra6">
							<input type="text" class="flex-1 paddlr20 fz-14 pop-input-item h44 boderra6"
								placeholder="请填写开户行" v-model="params.bank_addr">
						</div>
					</div>
				</div>
				<div v-if="showtype == 2">
					<div class="flex-row-center-start mart30">
						<span class="fz-14 co-343434 w80">提现至</span>
						<el-select popper-class="option-item" class="select" :disabled="true" v-model="value"
							placeholder="请选择银行">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
								<span style="float: left" class="fz-14">{{ item.label }}</span>
							</el-option>
						</el-select>
					</div>
					<div class="flex-row-center-start mart24">
						<span class="fz-14 co-343434 w80">提现金额</span>
						<div class="flex-row-center-center pop-input h44 boder1-EEEEEE boderra6">
							<input type="text" class="flex-1 paddlr20 fz-14 pop-input-item h44 boderra6"
								placeholder="请输入提现金额" v-model="cash">
						</div>
						<span class="fz-14 co-343434 marl12">元</span>
					</div>

				</div>
				<div class="flex-row-center-center paddt40 paddb30">
					<div class="flex-row-center-center submit-btn-01 fz-16 color-white bg-FF3F26 boderra6 h44 pointer"
						@click="submit">
						确认提交
					</div>
				</div>
			</div>
		</el-dialog>

		<el-dialog class="reply-pop" width="640px" :visible.sync="voucherPop" :destroy-on-close="true"
			:show-close="false" top="25vh">
			<template slot="title"></template>
			<div class="paddlr24">
				<div class="flex-row-center-sb boderb1-EEEEEE paddtb24">
					<span class="co-343434 fz-18 font-blod">提现凭证</span>
					<div class="pointer" @click="handleClose(2)">
						<iconImg src='assets/close_01.png' size='18 18' mode='fit'></iconImg>
					</div>
				</div>
				<div class="flex-row-center-center paddt30 paddb30">
					<iconImg status="2" :src='citem.image' size='350 350' mode='fit'></iconImg>
				</div>
				<div class="paddb30"></div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				service_charge: "",
				details: "",
				titleList: [{
						id: 1,
						title: "资金明细",
					},
					{
						id: 2,
						title: "提现记录",
					}
				],
				cid: 1,
				managePop: false,
				name: "",
				card: "",
				bank: "",
				showtype: 1,
				cash: "",
				options: [{
					value: '',
					label: ''
				}],
				value: "",
				params: {
					bank_username: "",
					bank_number: "",
					bank_addr: "",
				},
				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,
				voucherPop: false,
				citem: "",
			};
		},
		mounted() {
			this.details = JSON.parse(localStorage.getItem("userinfo"));
			if (this.details.bank_username) {
				let params = {
					bank_username: this.details.bank_username,
					bank_number: this.details.bank_number,
					bank_addr: this.details.bank_addr,
				}
				this.options[0] = {
					value: params.bank_username,
					label: `${params.bank_username} ${params.bank_number.toString().length >= 4 ? params.bank_number.toString().substr(-4) : '卡号错误'}`
				};
				this.value = params.bank_username;
				this.params = params;
			};
			this.$api.tokenRequest({
				method: 'GET',
				url: '/api/common/options',
				isLoading: '加载中...',
				success: res => {
					this.service_charge = res.data.service_charge;
				},
				fail: data => {
					this.$message.error(data.message);
				},
			});
			this.onSubmit();
		},
		methods: {
			showPic(item) {
				if (!item.image) {
					this.$message.error("暂无提现凭证");
					return false;
				}
				this.citem = item;
				this.voucherPop = true;
			},
			titleChange(v) {
				this.cid = v.id;
				this.onSubmit();
			},
			// 显示弹窗
			showPop(type) {
				this.showtype = type;
				this.managePop = true;
			},
			// 关闭弹窗
			handleClose(type) {
				if (type == 1) {
					this.managePop = false;
				} else if (type == 2) {
					this.voucherPop = false;
				}
			},
			// 银行
			submit() {
				if (this.showtype == 1) {
					if (!this.params.bank_username) {
						this.$message.error('请输入姓名');
						return false
					}
					if (!this.params.bank_number) {
						this.$message.error('请填写银行卡号');
						return false
					}
					if (!this.params.bank_addr) {
						this.$message.error('请输入开户行');
						return false
					}

					this.$api.tokenRequest({
						method: 'PUT',
						url: '/store/user',
						isLoading: '加载中...',
						data: this.params,
						success: res => {
							this.$message.success("添加成功");
							let details = JSON.parse(JSON.stringify(this.details));
							details.bank_username = this.params.bank_username;
							details.bank_number = this.params.bank_number;
							details.bank_addr = this.params.bank_addr;
							localStorage.setItem("userinfo", JSON.stringify(details));
						},
						fail: data => {
							this.$message.error(data.message);
						},
					})
				} else if (this.showtype == 2) {
					if (!this.value) {
						this.$message.error('请选择提现银行');
						return false
					}
					if (!this.cash) {
						this.$message.error('请输入提现金额');
						return false
					}
					this.$api.tokenRequest({
						method: 'POST',
						url: '/store/withdraw',
						isLoading: '加载中...',
						data: {
							type: 1,
							money: this.cash
						},
						success: res => {
							this.$message.success("已提交申请");
							this.cash = "";
							this.managePop = false;
						},
						fail: data => {
							this.$message.error(data.message);
						},
					})
				}
				this.handleClose(1)
			},
			// 加载更多
			load() {

			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.getList();
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: this.cid == 1 ? "/store/account" : "/store/withdraw",
					isLoading: '加载中...',
					data: {
						status: 60,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.tableheader {
			background: #f8f8f8;
			height: 48px;
			border-radius: 8px;
			box-sizing: border-box;

			.tableheaderone {
				color: #999999;
				line-height: 48px;
				box-sizing: border-box;
			}
		}

		.infinite-list {
			width: 100%;
			height: 100%;
			box-sizing: border-box;

			.tableone {
				width: 100%;
				box-sizing: border-box;
			}

			.table {
				box-sizing: border-box;
				height: 55px;
			}
		}
	}

	.top_bg {
		height: 118px;
	}

	.btn-top {
		opacity: 0;
	}

	.manage-btn {
		width: 118px;
		height: 35px;
	}

	.withdrawal-btn {
		width: 116px;
		height: 33px;
		border: 1px solid #FF3F26;
	}

	.pop-input {
		width: 446px;
	}

	.pop-input-item {
		outline: none;
		border: 0;
	}

	.submit-btn-01 {
		width: 192px;
	}

	::-webkit-input-placeholder {
		/* WebKit browsers，webkit内核浏览器 */
		color: #999999;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #999999;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #999999;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #999999;
	}

	.select {
		width: 446px;
		height: 44px;
	}

	/deep/.select .el-input {
		height: 44px;
	}

	/deep/.select .el-input__inner {
		height: 44px;
	}

	/deep/.select .el-select__caret {
		transform: rotate(0deg);
	}

	/deep/.select .is-reverse {
		transform: rotate(180deg) !important;
	}

	/deep/.select .el-input__inner:focus {
		border-color: #FF3F26;
	}

	/deep/.select .el-icon-arrow-up::before {
		width: 8px;
		height: 8px !important;
		content: "";
		background: url(../../assets/icon_12.png) no-repeat;
		background-size: 8px 8px;
		background-position: 0 2px;
		padding: 0 0 0 9.5px;
		box-sizing: border-box;
		font-size: 12px;
	}

	.option-item .el-select-dropdown__item:hover {
		background: #FFF9F8 !important;
		color: #FF3F26 !important;
	}

	.option-item .hover {
		background: #FFF9F8 !important;
		color: #FF3F26 !important;
	}

	.option-item .selected {
		background: #FFF9F8 !important;
		color: #FF3F26 !important;
	}
</style>