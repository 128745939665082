<template>
	<div class="login-wrapper flex-row-center-center">
		<div class="flex-row">
			<img class="img_login" src="../../assets/img_login@2x.png" alt="" />
			<div class="loginbox bg-white">
				<div class="logintitle text-center ">一乡一品商城商家端登录</div>
				<el-form ref="ruleFormRef" :model="ruleForm" status-icon label-width="120px" class="login-form"
					label-position="top">
					<el-form-item label="账号" prop="account"
						:rules="[{ required: true, message: '请输入账号', trigger: 'blur' },]">
						<el-input v-model.number="ruleForm.account" placeholder="请输入账号" />
					</el-form-item>
					<el-form-item label="密码" prop="pass"
						:rules="[{ required: true, message: '登录密码不能少于4位有效字符', trigger: 'blur' },{ min: 4, message: '登录密码不能少于4位有效字符', trigger: 'blur' }]">
						<el-input v-model="ruleForm.pass" type="password" placeholder="请输入密码" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="remmeber">
						<el-checkbox v-model="remmeber" label="记住密码" size="large" />
					</el-form-item>
					<el-form-item>
						<div class="login" @click="submitForm">登录</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	// import decrypt from '@/utils/index.js'
	export default {
		components: {

		},
		data() {
			return {
				ruleForm: {
					account: '',
					pass: '',
				},
				remmeber: false,
			};
		},
		created() {
			let loginData = localStorage.getItem("account");
			if (loginData) {
				let account = JSON.parse(loginData);
				this.ruleForm = account;
			}
			let token = localStorage.getItem("token");
			if (token) {
				this.$router.push({
					path: '/index/index'
				})
			} else {
				// let account = JSON.parse(localStorage.getItem(accountKey))
				// this.ruleForm.pass = decrypt.decryptDes(account.password)
				// this.ruleForm.account = account.account
			}
		},
		methods: {
			// 登录
			submitForm() {
				this.$refs.ruleFormRef.validate((valid) => {
					if (valid) {
						this.$api.request({
							method: 'post',
							url: '/store/login',
							isLoading: '加载中...',
							data: {
								phone: this.ruleForm.account,
								password: this.ruleForm.pass,
							},
							success: res => {
								if (this.remmeber) {
									localStorage.setItem("account", JSON.stringify(this.ruleForm));
								} else {
									localStorage.setItem("account", "");
								}
								localStorage.setItem("token", res.data.token);
								this.getUserInfo();
							},
							fail: data => {
								this.$message.error(data.message);
							},
						})
					}
				});
			},
			// 获取个人信息
			getUserInfo() {
				this.$api.tokenRequest({
					method: 'get',
					url: '/store/user',
					isLoading: '加载中...',
					success: res => {
						this.$message.success("登录成功");
						localStorage.setItem("userinfo", JSON.stringify(res.data));
						this.$store.commit("setUserInfo", res.data);
						this.$webSocket.config({
							username: res.data.im_user.username,
							password: res.data.im_user.password
						})
						this.$webSocket.open();
						this.$router.push({
							path: '/index/index'
						})
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			}
		},
	};
</script>

<style scoped lang="less">
	.login-wrapper {
		width: 100%;
		height: 100vh;
		background: url(../../assets/bg_login@2x.png) no-repeat;

		.img_login {
			width: 550px;
			height: 720px;
			border-radius: 20px 0 0 65px;
		}

		.loginbox {
			width: 550px;
			height: 720px;
			border-radius: 0 65px 20px 0;
			box-sizing: border-box;

			.logintitle {
				margin-top: 106px;
				font-size: 30px;
				font-weight: bold;
				margin-bottom: 64px;
			}

			.login-form {
				padding: 0 80px;
			}

			.login {
				width: 388px;
				height: 58px;
				background: #FF3F26;
				border-radius: 6px;
				text-align: center;
				line-height: 58px;
				color: #ffffff;
				cursor: pointer;
			}

			/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
				opacity: 0;
				margin-right: 0;
			}

			/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
				color: #FF3F26;
			}

			/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
				background-color: #FF3F26;
				border-color: #FF3F26;
			}

			/deep/ .el-checkbox__inner:hover {
				border-color: #FF3F26;
			}

			/deep/ .el-checkbox__inner {
				border-radius: 50%;
			}

			/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
				border-color: #FF3F26;
			}
		}
	}
</style>
