<template>
	<div class="fullh100 flex-column">
		<div class="flex-1 paddb26">
			<div class="h60 top-01 bg-white flex-row-center-start fz-18 co-s343434 paddlr24 font-blod">规则说明</div>
			<div class="infinite-list-wrapper paddt20 paddl20 paddr20 fullh100" style="overflow:auto">
				<div class="" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
					<div class="h66 bg-white boderra10 flex-row-center-sb marb20 paddlr24 pointer" v-for="(v, i) in list"
						:key="i" @click="toDetail(v)">
						<span class="fz-16s co-343434">{{v.title}}</span>

						<div class="flex-row-center-start">
							<span class="fz-12 co-BBBBBB marr4">查看详情</span>
							<iconImg src="assets/icon_52.png" size='20 8' mode='fit'></iconImg>
						</div>
					</div>
				</div>
			</div>
		</div>

		<el-drawer ref="detailPop" class="side-pop" :visible.sync="detailopen" direction="rtl" :show-close="false"
			:close="handleClose" :destroy-on-close="true" size="1200px">
			<div class="fz-20 co-343434 paddl50 paddt30 paddb36 relative">
				<span class="font-blod">{{details.title}}</span>
				<div class="flex-row-center-center absolute top0 right0 paddr40 paddt37 pointer" @click="handleClose">
					<iconImg src='assets/icon_16.png' class="marl30" size='12 12' mode='fit'></iconImg>
				</div>
				<div class="mart36 fz-14 co-343434 paddr50" v-html="details.content">
					
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				detailopen: false, // 详情弹窗

				state: 1, // 列表加载状态
				page: 0, // 分页
				listLoading: false, // 接口加载状态
				list: [], // 列表
				disabled: true,
				details: "", // 详情
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			// 查看详情
			toDetail(v) {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/index/ruleDescDetail',
					isLoading: '加载中...',
					data: {
						id: v.id,
					},
					success: res => {
						this.details = res.data;
						this.detailopen = true;
					},
					fail: data => {
						this.message.error(data.message);
					},
				})
			},
			// 关闭弹窗
			handleClose() {
				this.detailopen = false;
			},
			// 上拉加载
			load() {
				this.getList();
			},
			// 获取列表
			getList() {
				let page = this.page;
				page++;
				if (this.listLoading) {
					return false
				}
				if (this.list.length < this.page * 10 && this.page > 0) {
					return false
				}
				this.listLoading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/index/ruleDesc',
					isLoading: '加载中...',
					data: {
						page: page,
						size: 10,
					},
					success: res => {
						this.listLoading = false;
						this.list = res.data.data;
						if (res.data.data.length > 0) {
							this.page = page;
						}
					},
					fail: data => {
						this.listLoading = false;
						this.message.error(data.message);
					},
				})
			},
			// 刷新数据
			getRefresh() {
				this.state = 1;
				this.list = [];
				this.page = 0;
				this.getList();
			}
		}
	}
</script>

<style scoped>
	.top-01 {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
</style>
