import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userinfo: "", // 用户信息
	},
	// 获取仓库数据
	getters: {},
	// 修改仓库数据
	mutations: {
		// 存储用户信息
		setUserInfo(state, val) {
			state.userinfo = val;
		}
	},
	actions: {},
	modules: {}
})
