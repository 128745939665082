<template>
	<div class="noticewrap">
		<div class="paddlr24 paddtb18 bg-white top fz-18 font-bold">
			{{ record_title }}
		</div>
		<div class="messagewrap flex-1">
			<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
				<li v-for="(item, index) in list" :key="index">
					<div class="bg-white boderra10 padd20 marb20">
						<div class="fz-16 font-bold">{{item.title}}</div>
						<div class="mart8 fz-14 color-grey">{{item.desc}}</div>
						<div class="flex-row-center-sb mart18 fz-12">
							<div class="co-bbbbbb">{{item.create_time}}</div>
							<div class="flex-row-center-start color-red pointer" @click="toDetail(item)">
								<span>查看详情</span>
								<img class="detail" src="@/assets/icon_39@2x.png" alt="" />
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<detail ref="detailRef" />
	</div>
</template>
<script>
	import detail from './detail.vue'
	export default {
		props: {
			record_title: {
				type: String,
				default () {
					return "";
				},
			},
		},
		components: {
			detail
		},
		data() {
			return {
				type: 1,
				page: 1,
				state: 1,
				list: []
			}
		},
		created() {
			console.log(1111)
			this.refreshList(1);
		},
		methods: {
			toDetail(item) {
				this.$refs.detailRef.show(item.id, this.type)
			},
			load() {
				this.getList();
			},
			getList() {
				if (this.state == 3) return
				if (this.loading) return
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: this.type == 1 ? '/store/notice/index' : '/store/notice/list',
					isLoading: '加载中...',
					data: {
						keyword: "",
						page: this.page,
						size: 20
					},
					success: res => {
						console.log(res)
						this.loading = false;
						this.list = this.list.concat(res.data);
						if (res.data.length > 0) {
							this.page = this.page + 1;
						}
						if (res.data.length < 10) {
							this.state = 3;
						}
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
			refreshList(type) {
				this.type = type;
				this.page = 1;
				this.state = 1;
				this.list = [];
				this.getList();
			}
		}
	};
</script>
<style scoped lang="less">
	.noticewrap {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.messagewrap {
			padding: 0 30px;
			background: #f5f5f5;

			.detail {
				width: 20px;
				height: 8px;
			}
		}

		.infinite-list {
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			padding-top: 20px;
		}
	}
</style>
