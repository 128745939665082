<template>
	<div>
		<el-drawer title="改价" size="1000px" :visible.sync="drawer" :before-close="handleClose">
			<div class="mart26 paddlr50">
				<div class="tablewrap">
					<div class="tableheader fz-15">
						<el-row>
							<el-col :span="9">
								<div class="tableheaderone flex-row-center-center">商品</div>
							</el-col>
							<el-col :span="5">
								<div class="tableheaderone flex-row-center-center">
									规格说明
								</div>
							</el-col>
							<el-col :span="5">
								<div class="tableheaderone flex-row-center-center">数量</div>
							</el-col>
							<el-col :span="5">
								<div class="tableheaderone flex-row-center-center">单价</div>
							</el-col>
						</el-row>
					</div>
					<el-row v-if="info">
						<el-col :span="9">
							<div class="flex-row paddtb20 paddlr24 table">
								<img class="cover" :src="info.goods.cover" alt="" />
								<div class="marl10 fz-14">{{info.goods.title}}</div>
							</div>
						</el-col>
						<el-col :span="5">
							<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
								<div>
									<div v-for="(v, i) in info.spec" :key="i">{{v.spec_name}}</div>
								</div>
							</div>
						</el-col>
						<el-col :span="5">
							<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
								<div>
									<div v-for="(v, i) in info.spec" :key="i">x{{v.number}}</div>
								</div>
							</div>
						</el-col>
						<el-col :span="5">
							<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
								<div>
									<div v-for="(v, i) in info.spec" :key="i">￥{{v.price}}</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div>
					<Ltitle class="paddt40" :list="titleList" :titleId="cid" @change="titleChange"></Ltitle>
					<el-form label-position="left" label-width="120px" class="mart36">
						<el-form-item :label="cid == 1 ? '增加费用':'减少费用'" prop="price">
							<el-col :span="12">
								<el-input style="width:320px" v-model="add_price" v-if="cid == 1"
									placeholder="请输入增加费用"></el-input>
								<el-input style="width:320px" v-model="reduce_price" v-else placeholder="请输入减少费用">
								</el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="运杂费" prop="z_price">
							<el-col :span="12">
								<el-input style="width:320px" v-if="cid == 1" v-model="add_freight" placeholder="请输入运杂费"></el-input>
								<el-input style="width:320px" v-else v-model="reduce_freight" placeholder="请输入运杂费"></el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="原订单总价" prop="desc">
							<el-col :span="12">
								<div class="flex-row-center-start">
									<el-input style="width:320px" v-model="info.price" :disabled="true"
										placeholder="请输入原订单总价"></el-input>
									<span class="marl12 fz-14">元</span>
								</div>
							</el-col>
						</el-form-item>
						<el-form-item label="现订单总价" prop="desc">
							<el-col :span="12">
								<div class="flex-row-end-start font-bold fz-14 color-red">
									<span class="fz-12 mart10 marb2">￥</span>
									<span v-if="cid == 1">{{add_total}}</span>
									<span v-else>{{reduce_total}}</span>
								</div>
							</el-col>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="bottom">
				<el-divider></el-divider>
				<div class="flex-row-center-center marb16">
					<el-button type="primary" class="submit" @click="onSubmit">确认修改</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
	export default {
		props: {
			details: {
				type: [Object, String],
				default: "",
			}
		},
		data() {
			return {
				drawer: false,
				titleList: [{
					id: 1,
					title: "加价",
				},
				{
					id: 2,
					title: "减价",
				}],
				cid: 1,
				info: "",
				add_price: 0, // 增加费用
				reduce_price: 0, // 减少费用
				add_freight: 0, // 增加运杂费
				reduce_freight: 0, // 减少运杂费
				add_total: "0.00",
				add_old_total: "0.00",
				reduce_total: "0.00",
				reduce_old_total: "0.00",
			};
		},
		watch: {
			// 加价变化
			add_price(val) {
				let price = Number(this.info.price) + Number(val) + Number(this.add_freight);
				this.add_total = price.toFixed(2);
			},
			// 加价运费变化
			add_freight(val) {
				let price = Number(this.info.price) + Number(this.add_price) + Number(val);
				this.add_total = price.toFixed(2);
			},
			// 减价变化
			reduce_price(val) {
				let price = Number(this.info.price) + Number(this.reduce_freight) - Number(val);
				this.reduce_total = price < 0 ? "0.00" : price.toFixed(2);
			},
			// 减价运费变化
			reduce_freight(val) {
				let price = Number(this.info.price) + Number(val) - Number(this.reduce_price);
				this.reduce_total = price < 0 ? "0.00" : price.toFixed(2);
			}
		},
		methods: {
			show(val) {
				this.setInfo(val);
				this.drawer = true;
			},
			handleClose() {
				this.drawer = false;
			},
			onSubmit() {
				this.$api.tokenRequest({
					method: 'post',
					url: '/store/order/changePrice',
					isLoading: '加载中...',
					data: {
						order_id: this.info.id,
						type: this.cid,
						price: this.cid == 1 ? this.add_price : this.reduce_price,
						express_price: this.cid == 1 ? this.add_freight : this.reduce_freight,
					},
					success: res => {
						this.add_price = 0;
						this.reduce_price = 0;
						this.add_freight = 0;
						this.reduce_freight = 0;
						this.add_total = "0.00";
						this.add_old_total = "0.00";
						this.reduce_total = "0.00";
						this.reduce_old_total = "0.00";
						this.handleClose();
						this.$emit("refreshList", {});
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			chooseShop(item) {
				item.is_choose = !item.is_choose;
			},
			titleChange(v) {
				this.cid = v.id;
			},
			// 设置渲染内容
			setInfo(val) {
				this.info = val;
				this.add_total = val.price;
				this.reduce_total = val.price;
			}
		},
	};
</script>
<style lang="less" scoped>
	/deep/ .el-scrollbar__wrap {
		width: 100%;
	}

	.tablewrap {
		border: 1px solid #eeeeee;
		border-radius: 8px;
	}

	.tableheader {
		background: #f8f8f8;
		height: 48px;
		border-radius: 8px;
		box-sizing: border-box;

		.tableheaderone {
			color: #999999;
			line-height: 48px;
			box-sizing: border-box;
		}
	}

	.cover {
		width: 60px;
		height: 60px;
		border-radius: 8px;
	}

	.table {
		border-right: 1px solid #eeeeee;
		box-sizing: border-box;
		height: 100px;
		box-sizing: border-box;
	}

	.changebtn {
		height: 27px;
		width: 68px;
		background: #ff3f26;
		border-radius: 6px;
		border-color: transparent;
		color: #ffffff;
		font-size: 13px;
		line-height: 27px;
		padding: 0;
	}

	/deep/ .el-form-item__content {
		line-height: inherit;
	}

	/deep/ .el-drawer__header {
		color: #343434;
		font-weight: bold;
	}

	/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		opacity: 0;
		margin-right: 0;
	}

	.html {
		width: 900px;
		height: 160px;
		border-radius: 10px;
	}

	.textarea {
		height: 184px;
	}

	/deep/ .el-textarea__inner {
		height: 184px;
	}

	.bottom {
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 100%;

		.submit {
			width: 128px;
			height: 40px;
			background: #ff3f26;
			border-radius: 6px;
			border: 1px solid #ff3f26;
		}
	}
</style>
