<template>
	<div class="flex-row-center-start goods-item">
		<div class=" flex-row-center-center" :style="{'flex': v.flex,}" v-for="(v, i) in renderlist" :key="i">
			<div class="fz-14 co-999999"
				:class="[v.bold ? 'font-blod' : '', v.type == 4 || v.type == 5 ? 'paddlr24' : '']"
				v-if="v.type == 1 || v.type == 5 || v.type == 4">{{info[v.key]}}</div>
			<div class="fz-14 flex-column-center-center" v-if="v.type == 6">
				<div :class="status == 1 || status == 3 ? 'co-343434' : status == 2 ? 'co-999999' : 'co-FF3F26'">
					{{status == 1 ? '展示中' : status == 2 ? '审核中' : status == 3 ? '被下架' : '已过期'}}</div>
				<div class="mart6 co-FF3F26" v-if="status == 1">{{detail.surplus_day}} </div>
			</div>
			<iconImg status="2" :src='info[v.key]' v-else-if="v.type == 2" size='50 50' mode='fit'></iconImg>
			<div class="flex-row-center-start fz-14 co-1890FF" v-else-if="v.type == 3 && ptype == 1">
				<span class="pointer marr22" v-if="status == 1" @click="clickItem(1)">下架</span>
				<span class="pointer marr22" v-if="status == 2" @click="clickItem(6)">上架</span>
				<span class="pointer marr22 co-FF3F26" v-if="status == 2 || status == 3" @click="clickItem(2)">删除</span>
				<span class="pointer marr22" v-if="status == 1 || status == 2 || status == 3"
					@click="clickItem(3)">编辑</span>
				<span class="pointer" @click="clickItem(4)">查看</span>
			</div>
			<div class="flex-row-center-start fz-14 co-1890FF" v-else-if="v.type == 3 && ptype == 2">
				<span class="pointer marr22" v-if="status == 1" @click="clickItem(5)">续费</span>
				<span class="pointer marr22" v-if="status == 0" @click="clickItem(5)">缴费</span>
				<span class="pointer marr22" v-if="status == 3" @click="clickItem(3)">编辑</span>
				<span class="pointer" @click="clickItem(4)">查看</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			render: {
				type: Array,
				default: function() {
					return []
				}
			},
			detail: {
				type: Object,
				default: function() {
					return {}
				}
			},
			ptype: {
				type: [String, Number],
				default: 1
			},
			status: {
				type: [String, Number],
				default: 1
			},
		},
		data() {
			return {
				renderlist: [],
				info: "",
			}
		},
		created() {
			this.setList(this.render);
			this.setInfo(this.detail);
		},
		watch: {
			render(n) {
				this.setList(n);
			},
			detail(n) {
				this.setInfo(n);
			}
		},
		methods: {
			// 操作按钮
			clickItem(type) {
				this.$emit("clickItem", type);
			},
			// 设置渲染数组
			setList(val) {
				this.renderlist = val;
			},
			// 设置渲染信息
			setInfo(val) {
				this.info = val;
			}
		}
	}
</script>

<style scoped lang="less">
	@media (max-width: 1400px) {
		.goods-item {
			padding-top: 14px;
			padding-bottom: 13px;
			min-height: 86px;
		}

		.cover {
			width: 31px;
			height: 31px;
			object-fit: cover;
		}

		.item-text {
			font-size: 16px;
		}
	}

	@media (min-width: 1401px) {
		.goods-item {
			padding-top: 22px;
			padding-bottom: 21px;
			min-height: 86px;
		}

		.cover {
			width: 50px;
			height: 50px;
			object-fit: cover;
		}

		.item-text {
			font-size: 16px;
		}
	}

	.goods-item:hover {
		background-color: #f5f7fa;
		cursor: pointer;
	}
</style>
