<template>
	<el-drawer ref="drawerPop" class="side-pop" :visible.sync="open" direction="rtl" :show-close="false"
		:destroy-on-close="true" size="1100px">
		<div class="fz-20 co-343434 paddl50 paddb36 relative">
			<span class="marb40 font-blod">通知详情</span>
			<div class="flex-row-center-center absolute top0 right0 paddr40 paddt37 pointer" @click="closePop">
				<iconImg src="assets/icon_16.png" class="marl30" size="12 12" mode="fit"></iconImg>
			</div>
			<span class="fz-16 font-bold mart40">{{details.title}}</span>
			<div class="color-grey fz-14 mart10">{{details.create_time}}</div>
			<div class="paddr60">
				<el-divider />
			</div>
			<!-- 富文本信息，先用图片替代 -->
			<div v-html="details.content"></div>
		</div>
	</el-drawer>
</template>
<script>
	export default {
		data() {
			return {
				open: false, // 详情弹窗
				details: "",
			};
		},
		methods: {
			show(id, type) {
				this.$api.tokenRequest({
					method: 'GET',
					url: type == 1 ? '/store/notice/detail' : '/store/notice/listDetail',
					isLoading: '加载中...',
					data: {
						id: id,
					},
					success: res => {
						this.details = res.data;
						this.open = true;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			closePop() {
				this.open = false;
			}
		},
	};
</script>
<style scoped lang="less">
	.big-cover {
		width: 360px;
		height: 360px;
		border-radius: 12px;
	}

	.small-cover {
		width: 64px;
		height: 64px;
		border-radius: 6px;
		margin-right: 10px;
	}

	.price {
		display: flex;
		align-items: center;
		justify-content: start;
		padding: 8px 12px;
		background: #fff7f6;
		border-radius: 8px;
		color: #FF3F26;
		font-size: 26px;
		margin-top: 16px;
	}

	.unit {
		font-size: 16px;
		margin-top: 6px;
	}

	.label {
		width: 68px;
		color: #999999;
	}

	.spec {
		padding: 12px 24px;
		border: 1px solid #EEEEEE;
		border-radius: 6px;
		font-size: 14px;
	}

	/deep/ .el-divider--horizontal {
		margin-top: 13px;
	}

	.detail {
		width: 990px;
	}
</style>
