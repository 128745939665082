<template>
	<div class="content flex-column">
		<div class="paddlr40 paddt40 paddb24 bg-white flex-1 boderra10 flex-column">
			<div class="fz-20 font-bold paddb30">我的直播</div>
			<Ltitle class="marb24" :list="titleList" :titleId="cid" @change="titleChange"></Ltitle>
			<div class="tableheader fz-15">
				<el-row>
					<el-col :span="4">
						<div class="tableheaderone flex-row-center-center">封面图</div>
					</el-col>
					<el-col :span="4">
						<div class="tableheaderone flex-row-center-center">直播标题</div>
					</el-col>
					<el-col :span="4">
						<div class="tableheaderone flex-row-center-center">个人信息</div>
					</el-col>
					<el-col :span="4">
						<div class="tableheaderone flex-row-center-center">直播时间</div>
					</el-col>
					<el-col :span="4">
						<div class="tableheaderone flex-row-center-center">状态</div>
					</el-col>
					<el-col :span="4">
						<div class="tableheaderone flex-row-center-center">操作</div>
					</el-col>
				</el-row>
			</div>
			<div class="flex-1 ">
				<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
					<template v-for="(item, index) in list">
						<div class="tableone boderb1-EEEEEE">
							<el-row>
								<el-col :span="4">
									<div class="flex-row-center-center paddtb18 table">
										<img class="cover" src="@/assets/img_4@2x.png" alt="" />
									</div>
								</el-col>
								<el-col :span="4">
									<div class="flex-row-center-center paddtb18 table fz-14 co-343434">
										五常大米专场介绍
									</div>
								</el-col>
								<el-col :span="4">
									<div class="flex-row-center-center paddtb18 table fz-14 co-343434">
										<iconImg src='assets/logo.png' size='32 32' mode='fit'></iconImg>
										<span class="marl8">江大-小S</span>
									</div>
								</el-col>
								<el-col :span="4">
									<div class="flex-row-center-center paddtb18 table fz-14 co-343434">
										2022-07-09 18:00-22:00
									</div>
								</el-col>
								<el-col :span="4">
									<div class="flex-row-center-center table paddtb18 fz-14 co-343434">
										{{item.title}}
									</div>
								</el-col>
								<el-col :span="4">
									<div class="flex-row-center-center table paddtb18 fz-14 pointer co-343434">
										<div class="start-btn flex-row-center-center bg-FF3F26 color-white fz-13 boderra6 pointer"
											v-if="item.id == 3">
											开始直播
										</div>
										<span v-else>-</span>
									</div>
								</el-col>
							</el-row>
						</div>
					</template>
				</ul>
			</div>
			<div class="flex-row-center-center mart28">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper" :total="1000">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				titleList: [{
						id: 1,
						title: "全部",
					},
					{
						id: 2,
						title: "未开始",
					},
					{
						id: 3,
						title: "进行中",
					},
					{
						id: 4,
						title: "已结束",
					},
					{
						id: 5,
						title: "已结束",
					},
					{
						id: 6,
						title: "已结束",
					},
					{
						id: 7,
						title: "已结束",
					},
				],
				cid: 1,
				list: [{
						id: 2,
						title: "未开始",
					},
					{
						id: 3,
						title: "进行中",
					},
					{
						id: 4,
						title: "已结束",
					},
					{
						id: 5,
						title: "审核中",
					},
					{
						id: 6,
						title: "已拒绝",
					},
					{
						id: -1,
						title: "进行中",
					},
					{
						id: -1,
						title: "未开始",
					},
					{
						id: -1,
						title: "未开始",
					},
					{
						id: -1,
						title: "未开始",
					},
					{
						id: -1,
						title: "未开始",
					},
					{
						id: -1,
						title: "未开始",
					},
				],
				codePop: false,
			};
		},
		methods: {
			titleChange(v) {
				this.cid = v.id;
			},
			// 加载更多
			load() {

			},
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.tableheader {
			background: #f8f8f8;
			height: 48px;
			border-radius: 8px;
			box-sizing: border-box;

			.tableheaderone {
				color: #999999;
				line-height: 48px;
				box-sizing: border-box;
			}
		}

		.infinite-list {
			width: 100%;
			height: 100%;
			box-sizing: border-box;

			.tableone {
				width: 100%;
				box-sizing: border-box;

				.tableoneheader {
					background: #f8f8f8;
					border-radius: 8px 8px 0 0;
					padding: 12px 24px;

					.icon {
						width: 14px;
						height: 14px;
					}
				}

				.cover {
					width: 50px;
					height: 50px;
					border-radius: 8px;
				}
			}

			.table {
				box-sizing: border-box;
				height: 100px;
			}

			.changebtn {
				height: 27px;
				width: 68px;
				background: #FF3F26;
				border-radius: 6px;
				border-color: transparent;
				color: #ffffff;
				font-size: 13px;
				line-height: 27px;
				padding: 0;
			}
		}
	}

	.start-btn {
		width: 80px;
		height: 27px;
	}
</style>
