<template>
	<div class="fullh100">
		<div class="fullh100 bg-white">
			<div class="font-blod fz-20 co-343434 paddt30 paddl40 paddb40">
				<span>发布广告</span>
				<span class="op0">{{changeStatus}}</span>
			</div>
			<div class="paddl60">
				<div class="flex-row-center-start">
					<span class="fz-14 co-343434 w110">广告标题</span>
					<div class="input-area boderra6 boder1-EEEEEE flex-row-center-start">
						<input class="fullh100 boderra6 fz-14 co-343434 flex-1" v-model="params.title"
							placeholder="可输入广告标题" type="text">
					</div>
				</div>
				<div class="mart24 flex-row-center-start">
					<span class="fz-14 co-343434 w110">广告副标题</span>
					<div class="input-area boderra6 boder1-EEEEEE flex-row-center-start">
						<input class="flex-1 fullh100 boderra6 paddl20 fz-14 co-343434" v-model="params.mini_title"
							placeholder="可输入广告副标题" type="text">
					</div>
				</div>
				<div class="mart24 flex-row-center-start">
					<span class="fz-14 co-343434 w110">联系电话</span>
					<div class="input-area boderra6 boder1-EEEEEE flex-row-center-start">
						<input class="flex-1 fullh100 boderra6 paddl20 fz-14 co-343434" v-model="params.phone"
							placeholder="可输入联系电话" type="text">
					</div>
				</div>
				<div class="mart24 flex-row-center-start">
					<span class="fz-14 co-343434 w110">商品价格</span>
					<div class="flex-row-center-start">
						<div class="price-area boderra6 boder1-EEEEEE flex-row-center-start">
							<input class="flex-1 fullh100 boderra6 paddl20 fz-14 co-343434" v-model="params.good_price"
								placeholder="请输入金额" type="text">
						</div>
						<span class="fz-14 co-343434 marl12">元</span>
					</div>
				</div>
				<div class="flex-row mart30">
					<span class="fz-14 co-343434 w110">产品封面图</span>
					<div class="wh-96 relative pointer">
						<el-upload class="absolute all" action="https://admin.yixiangyipin.com/api/upload"
							:show-file-list="false" :on-success="coverSuccess" :before-upload="beforeAvatarUpload">
							<iconImg v-if="params.image.length == 0" src='assets/icon_10.png' size='96 96' mode='fit'>
							</iconImg>
							<img v-else :src="params.image[0]" alt="" class="wh-96">
						</el-upload>
					</div>
				</div>
				<div class="flex-row mart30">
					<span class="fz-14 co-343434 w110">广告内容</span>
					<textarea class="content fz-14 co-343434 paddlr20 paddtb12" v-model="params.content"
						placeholder="可输入广告内容"></textarea>
				</div>
				<div class="flex-row-center-start mart24 fz-14 co-343434">
					<span class="w110">广告时长</span>
					<div class="flex-row-center-start pointer marr32" v-for="(v, i) in time_list" :key="i"
						@click="chooseType(v)">
						<iconImg :src="`assets/${params.type == v.id ? 'icon_14' : 'icon_144'}.png`" size='14 14'
							mode='fit'>
						</iconImg>
						<span class="marl12">{{v.day}}({{v.price}}元)</span>
					</div>
				</div>
				<div class="flex-row-center-center mart100 h72">
					<el-button class="button" type="success" @click="save">支付发布</el-button>
				</div>
			</div>
		</div>
		<payDialog ref="payRef" />
	</div>
</template>

<script>
	import payDialog from './components/payDialog.vue';

	var timer;

	export default {
		components: {
			payDialog
		},
		data() {
			return {
				timeIndex: 0,
				time_list: [],
				loading: false,
				params: {
					title: "",
					mini_title: "",
					content: "",
					image: [],
					phone: "",
					good_price: "",
					type: "",
					order_type: 1,
				},
				order_id: "", // 订单id
				changeStatus: false,
			}
		},
		mounted() {
			this.getData();
		},
		destroyed() {
			clearTimeout(timer);
		},
		methods: {
			// 保存
			save() {
				let params = JSON.parse(JSON.stringify(this.params));
				if (!this.params.title) {
					this.$message.error('请输入标题!');
					return
				}
				if (!this.params.mini_title) {
					this.$message.error('请输入副标题!');
					return
				}
				if (!this.params.good_price) {
					this.$message.error('请输入商品价格!');
					return
				}
				if (this.params.image.length == 0) {
					this.$message.error('请上传封面图片!');
					return
				}
				if (!this.params.content) {
					this.$message.error('请输入正文!');
					return
				}
				if (!this.params.type) {
					this.$message.error('请选择广告时长!');
					return
				}
				if (this.loading) return
				this.loading = true;
				params.image = params.image.join(",");
				this.$api.tokenRequest({
					method: 'post',
					url: '/store/UserAd',
					isLoading: '加载中...',
					data: params,
					success: res => {
						this.$api.tokenRequest({
							method: 'POST',
							url: '/store/AdOrder/pay',
							isLoading: '加载中...',
							data: {
								order_id: res.data.id,
								pay_type: 2,
							},
							success: res1 => {
								this.order_id = res.data.id;
								this.loading = false;
								this.$refs.payRef.show(res1.data.params.code_url, res.data.id);
								this.searchResult();
							},
							fail: data => {
								this.loading = false;
								this.$message.error(data.message);
							},
						})
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
			// 封面图上传
			coverSuccess(res) {
				this.changeStatus = !this.changeStatus;
				this.params.image[0] = res.data;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			// 选择推荐类型
			chooseType(v) {
				this.params.type = v.id;
			},
			// 获取发布信息
			getData() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/UserAd/adType',
					isLoading: '加载中...',
					success: res => {
						res.data.forEach(v => {
							v.price = Number(v.price);
							v.day = `${v.day}天广告`;
						})
						this.time_list = res.data;
					},
					fail: data => {
						this.message.error(data.message);
					},
				})
			},
			// 轮询支付结果
			searchResult() {
				if (timer) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					this.$api.tokenRequest({
						method: 'GET',
						url: '/store/AdOrder/PaySearch',
						data: {
							order_id: this.order_id
						},
						success: res => {
							clearTimeout(timer);
							this.$router.push({
								path: "/ad/ad",
							});
						},
						fail: data => {
							if (data.code == 999) {
								this.searchResult();
							} else {
								this.message.error(data.message);
							}
						},
					})
				}, 3000);
			}
		}
	}
</script>

<style scoped>
	.input-area {
		width: 600px;
		height: 44px;
	}

	.price-area {
		width: 320px;
		height: 44px;
	}

	.content {
		width: 60%;
		height: 184px;
		border: 1px solid #EEEEEE;
		border-radius: 6px;
		resize: none;
		outline: none;
	}

	.button {
		width: 128px;
		height: 40px;
		background: #FF3F26;
		border: 0;
	}

	input {
		outline: none;
		padding: 0;
		margin: 0;
		border: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	::-webkit-input-placeholder {
		/* WebKit browsers，webkit内核浏览器 */
		color: #999999;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #999999;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #999999;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #999999;
	}

	.avatar-uploader {
		opacity: 0;
	}
</style>