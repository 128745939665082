<template>
	<div>
		<el-drawer title="订单发货" size="1000px" :visible.sync="drawer" :before-close="handleClose">
			<div class="mart26 paddlr50">
				<div>
					<el-form label-position="left" label-width="120px" class="mart36">
						<el-form-item label="订单编号" prop="ordernum">
							<div class="fz-14 lh-40">{{info.order_sn}}</div>
						</el-form-item>
						<el-form-item label="收货地址" prop="address">
							<div class="addresswrap flex-row-center-sb" v-if="info">
								<div class="flex-row-center-start">
									<span class="fz-14 font-bold marr16">{{info.address_info.name}}</span>
									<span class="fz-14 marr16">{{info.address_info.full_address}}</span>
									<span class="fz-14">{{info.address_info.phone}}</span>
								</div>
								<span class="fz-14 font-bold color-red pointer"
									@click="$refs.addressRef.show()">编辑</span>
							</div>
						</el-form-item>
						<el-form-item label="发货方式" prop="way">
							<div class="flex-row-center-start pointer">
								<div class="flex-row-center-center waywrap marr22" @click="choose(1)"
									:class="{ wayactive: way == 1 }">
									物流快递
								</div>
								<div class="flex-row-center-center waywrap" @click="choose(2)"
									:class="{ wayactive: way == 2 }">
									货运
								</div>
							</div>
						</el-form-item>
						<el-form-item label="物流订单" prop="expressnum" v-if="way == 1">
							<el-col :span="12">
								<el-input style="width: 320px" v-model="express_number" placeholder="请输入物流订单">
								</el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="物流公司" prop="company" v-if="way == 1">
							<el-col :span="12">
								<el-select style="width: 320px" v-model="company" placeholder="请选择物流公司"
									@change="companyChange">
									<el-option v-for="(item, index) in companyList" :key="index" :label="item.title"
										:value="item.id">
										<span style="float: left" class="fz-14">{{ item.title }}</span>
									</el-option>
								</el-select>
							</el-col>
						</el-form-item>
						<el-form-item label="联系人" prop="expressnum" v-if="way == 2">
							<el-col :span="12">
								<el-input style="width: 320px" v-model="link_username" placeholder="请输入联系人">
								</el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="联系电话" prop="expressnum" v-if="way == 2">
							<el-col :span="12">
								<el-input style="width: 320px" v-model="link_phone" placeholder="请输入联系电话">
								</el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="车牌号码" prop="expressnum" v-if="way == 2">
							<el-col :span="12">
								<el-input style="width: 320px" v-model="car_number" placeholder="请输入车牌号码">
								</el-input>
							</el-col>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="bottom">
				<el-divider></el-divider>
				<div class="flex-row-center-center marb16">
					<el-button type="primary" class="submit" @click="onSubmit">确认发货</el-button>
				</div>
			</div>
		</el-drawer>
		<editAddress ref="addressRef" @chang="alterAddress" />
	</div>
</template>
<script>
	import editAddress from './editAddress.vue'
	export default {
		components: {
			editAddress
		},
		data() {
			return {
				drawer: false,
				way: 1,
				company: "",
				express_number: "",
				express_id: "",
				link_username: "",
				link_phone: "",
				car_number: "",
				info: "",
				companyList: []
			};
		},
		created() {
			this.$api.tokenRequest({
				method: 'GET',
				url: '/api/maple/express',
				isLoading: '加载中...',
				success: res => {
					this.companyList = res.data;
				},
				fail: data => {
					this.message.error(data.message);
				},
			})
		},
		methods: {
			alterAddress(data) {
				this.$api.tokenRequest({
					method: 'PUT',
					url: '/store/order/editAddress',
					isLoading: '加载中...',
					data: {
						order_id: this.info.id,
						province_id: data.province_id,
						city_id: data.city_id,
						district_id: data.area_id,
						address: data.address,
						name: data.name,
						phone: data.phone,
					},
					success: res => {
						this.$refs.addressRef.handleClose();
						this.getData();
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			companyChange(data) {
				this.express_id = data;
			},
			show(val) {
				this.setInfo(val);
				this.drawer = true;
			},
			handleClose() {
				this.drawer = false;
			},
			onSubmit() {
				if (this.way == 1) {
					if (!this.express_id) {
						this.$message.error("请选择快递公司");
						return false;
					}
					if (!this.express_number) {
						this.$message.error("请输入快递单号");
						return false;
					}
				}
				if (this.way == 2) {
					if (!this.link_username) {
						this.$message.error("请输入联系人");
						return false;
					}
					if (!this.link_phone) {
						this.$message.error("请输入联系电话");
						return false;
					}
					if (!this.car_number) {
						this.$message.error("请输入车牌号");
						return false;
					}
				}
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/order/delivery',
					isLoading: '加载中...',
					data: {
						order_id: this.info.id,
						express_type: this.way,
						express_id: this.express_id,
						express_number: this.express_number,
						link_username: this.link_username,
						link_phone: this.link_phone,
						car_number: this.car_number,
					},
					success: res => {
						this.handleClose();
						this.$emit("refreshList", {});
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			choose(i) {
				this.way = i;
			},
			// 设置渲染内容
			setInfo(val) {
				this.info = val;
			},
			// 获取详情
			getData() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/order/detail',
					isLoading: '加载中...',
					data: {
						order_id: this.info.id,
					},
					success: res => {
						this.info = res.data;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			}
		},
	};
</script>
<style lang="less" scoped>
	.addresswrap {
		padding: 16px 24px;
		background: #f9f9f9;
		border-radius: 10px;
		border: 2px solid #eeeeee;
		border-radius: 10px;
	}

	/deep/ .el-form-item__content {
		line-height: inherit;
	}

	/deep/ .el-drawer__header {
		color: #343434;
		font-weight: bold;
	}

	/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		opacity: 0;
		margin-right: 0;
	}

	.waywrap {
		padding: 8px 50px;
		border: 1px solid #eeeeee;
		border-radius: 6px;
		font-size: 14px;
		min-width: 160px;
		min-height: 36px;
		box-sizing: border-box;
	}

	.wayactive {
		border: 1px solid #ff3f26;
		background: #fff9f8;
		color: #ff3f26;
	}

	.bottom {
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 100%;

		.submit {
			width: 128px;
			height: 40px;
			background: #ff3f26;
			border-radius: 6px;
			border: 1px solid #ff3f26;
		}
	}
</style>
