<template>
  <div>
    <div class="flex-row-center-center" :class="[boder, `${pointer ? 'cursor-pointer' : ''}`]" :style="{width: `${width}px`, height: `${height}px`, background: `${bg}`, borderRadius: `${bradius}px`}">
            <span :style="{'fontSize': fz + 'px', 'color': color}">{{text}}</span>
        </div>

    <!-- <a-button type="primary" :class="[boder, `${pointer ? 'cursor-pointer' : ''}`]" :style="{width: `${width}px`, height: `${height}px`, background: `${bg}`, borderRadius: `${bradius}px`, fontSize: `${fz}px`, color: `${color}`, fontWeight: `${blod ? '600' : ''}`}" >{{text}}</a-button> -->
  </div>
</template>

<script>
  export default {
    name: "iconImg",
    props: {
      // 按钮宽高
      wh: {
        type: String,
        default: '',
      },
      // 按钮文字
      text: {
        type: String,
        default: '',
      },
      // 文字大小
      fz: {
        type: String,
        default: '14',
      },
      // 文字颜色
      color: {
        type: String,
        default: '',
      },
      // 背景颜色
      bg: {
        type: String,
        default: '',
      },
      // 边框
      boder: {
        type: String,
        default: '',
      },
      // 是否加粗
      blod: {
        type: Boolean,
        default: false,
      },
      // 圆角
      radius: {
        type: [String, Number],
        default: 0,
      },
      // 是否显示手势图标
      pointer: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        width: '', // 宽
        height: '', // 高
        path: '', // 图片路径
        bradius: '', // 圆角
      };
    },
    watch: {
      wh: {
        handler(nval) {
          this.setSize(nval);
        },
        deep: true
      },
    },
    created() {
      if (this.wh) {
        this.setSize(this.wh);
      }
      this.setRadius();
    },
    methods: {
      // 设置尺寸
      setSize(size) {
        this.width = size.split(' ')[0];
        this.height = size.split(' ')[1];
      },
      // 设置圆角
      setRadius() {
        if (this.radius == 0) {
          let height = this.wh.split(' ')[1];
          this.bradius = height / 2;
        } else {
          this.bradius = this.radius;
        }
      }
    }
  };
</script>

<style scoped>

</style>
