<template>
	<div class="content flex-column">
		<div class="paddlr40 paddt40 paddb24 bg-white flex-1 boderra10 flex-column">
			<div class="fz-20 font-bold paddb40">评价管理</div>
			<div class="tableheader fz-15">
				<el-row>
					<el-col :span="9">
						<div class="tableheaderone flex-row-center-center">商品标题</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">规格说明</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">数量</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">运杂费</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">金额</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">操作</div>
					</el-col>
				</el-row>
			</div>
			<div class="flex-1 mart16">
				<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
					<li v-for="(item, index) in list" :key="index" class="marb16">
						<div class="tableone">
							<div class="tableoneheader flex-row-center-start color-grey">
								<div class="fz-12">订单编号：{{item.order_sn}}</div>
								<div class="flex-row-center-start marl48">
									<img class="icon" src="@/assets/icon_53@2x.png" alt="" />
									<div class="fz-12 marl8">买家：{{item.address_info.name}}</div>
								</div>
							</div>
							<el-row>
								<el-col :span="9">
									<div class="flex-row paddtb20 paddlr24 table">
										<img class="cover" :src="item.goods.cover" alt="" />
										<div class="marl10 fz-14">{{item.goods.title}}</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										<div>
											<div v-for="(vv, ii) in item.spec" :key="ii">{{vv.spec_name}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										<div>
											<div v-for="(vv, ii) in item.spec" :key="ii">x{{vv.number}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										￥{{item.express_price}}
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center font-bold paddtb20 paddlr24 table fz-14">
										￥{{item.price}}
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center table paddtb20 paddlr24 fz-14 co-1890FF pointer"
										@click="toDetail(item)">
										查看评价
									</div>
								</el-col>
							</el-row>
						</div>
					</li>
				</ul>
			</div>
			<div class="flex-row-center-center mart28">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
					@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
					:total="total" :page-sizes="[10, 15, 20]">
				</el-pagination>
			</div>
		</div>

		<el-drawer ref="detailPop" class="side-pop" :visible.sync="detailopen" direction="rtl" :show-close="false"
			:destroy-on-close="true" size="1200px">
			<div class="fz-20 co-343434 paddl50 paddt30 paddb36 relative" v-if="details">
				<span class="font-blod">查看评价</span>
				<div class="flex-row-center-center absolute top0 right0 paddr40 paddt37 pointer"
					@click="handleClose(1)">
					<iconImg src='assets/icon_16.png' size='12 12' mode='fit'></iconImg>
				</div>
				<div class="paddl60 paddr80 paddt40">
					<div class="">
						<div class="flex-row-center-sb">
							<div class="flex-row-center-start">
								<iconImg status="2" :src='details.user.avatar' size='32 32' mode='fit'></iconImg>
								<span class="fz-16 co-343434 marl8">{{details.user.nickname}}</span>
							</div>

							<div class="flex-row-center-start">
								<div class="marl4" v-for="(v, i) in 5" :key="i">
									<iconImg :src="`assets/${i < details.star ? 'is_start' : 'no_start'}.png`" size='14 14'
										mode='fit'></iconImg>
								</div>
							</div>
						</div>
						<div class="paddtb14 fz-14 co-333736">{{details.content}}</div>
						<div class="flex-row-wrap">
							<div class="marr12 marb12" v-for="(v, i) in details.image" :key="i">
								<iconImg status="2" :src='v' size='100 100' radius="8" mode='fit'></iconImg>
							</div>
						</div>
						<div class="flex-row-center-start fz-12 paddb16">
							<span class="co-999999">{{details.create_time}}</span>
							<div class="pointer flex-row-center-start marl24" @click="reply">
								<iconImg src='assets/icon_51.png' size='12 12' mode='fit'></iconImg>
								<span class="co-FF3F26 marl6">回复</span>
							</div>
						</div>
						<div class="paddt16 bodert1-EEEEEE">
							<div v-for="(v, i) in details.reply" :key="i">
								<div class="marb16">
									<div class="flex-row-center-start fz-14">
										<span class="co-FF3F26">商家回复：</span>
										<span class="co-343434">{{v.content}}</span>
									</div>
									<div class="paddt10 fz-14 co-999999">{{v.create_time}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-drawer>

		<el-dialog class="reply-pop" :visible.sync="replyPop" width="640px" :destroy-on-close="true" :show-close="false"
			top="35vh">
			<template slot="title"></template>
			<div class="paddlr24" v-if="details">
				<div class="flex-row-center-sb boderb1-EEEEEE paddtb24">
					<span class="co-343434 fz-18 font-blod">回复@{{details.user.nickname}}</span>
					<div class="pointer" @click="handleClose(2)">
						<iconImg src='assets/icon_15.png' size='18 18' mode='fit'></iconImg>
					</div>
				</div>
			</div>
			<div class="paddt23 paddb22 flex-row-center-center">
				<textarea class="reply-textarea w100 boderra6 fz-14" v-model="content" placeholder="发布您的回复"></textarea>
			</div>
			<div class="flex-row-center-end paddb30 paddr24">
				<div class="flex-row-center-center reply-btn bg-FF3F26 boderra6 color-white fz-13 pointer"
					@click="submit">
					回复
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,

				detailopen: false, // 详情弹窗
				replyPop: false, // 回复弹窗
				content: "", // 回复内容
				details: "",
			};
		},
		mounted() {
			this.onSubmit();
		},
		methods: {
			// 加载更多
			load() {},
			// 查看评价
			toDetail(item) {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/order/evaluate',
					isLoading: '加载中...',
					data: {
						order_id: item.id,
					},
					success: res => {
						this.details = res.data;
						this.detailopen = true;
					},
					fail: data => {
						this.message.error(data.message);
					},
				})
			},
			// 关闭弹窗
			handleClose(type) {
				if (type == 1) {
					this.detailopen = false;
				} else if (type == 2) {
					this.replyPop = false;
				}
			},
			// 评价
			reply() {
				this.replyPop = true;
			},
			// 提交评价
			submit() {
				if (!this.content) {
					this.$message.error('请输入回复内容');
					return false
				}
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/order/reply',
					isLoading: '加载中...',
					data: {
						evaluate_id: this.details.id,
						content: this.content,
					},
					success: res => {
						this.details.reply.push({
							content: this.content,
							create_time: this.$utils.getCurrentTime("sec"),
						});
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
				
				this.replyPop = false;
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/order',
					isLoading: '加载中...',
					data: {
						status: 60,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.message.error(data.message);
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.tableheader {
			background: #f8f8f8;
			height: 48px;
			border-radius: 8px;
			box-sizing: border-box;

			.tableheaderone {
				color: #999999;
				line-height: 48px;
				box-sizing: border-box;
			}
		}

		.infinite-list {
			width: 100%;
			height: 100%;
			box-sizing: border-box;

			.tableone {
				width: 100%;
				border: 1px solid #EEEEEE;
				border-radius: 8px;
				box-sizing: border-box;

				.tableoneheader {
					background: #f8f8f8;
					border-radius: 8px 8px 0 0;
					padding: 12px 24px;

					.icon {
						width: 14px;
						height: 14px;
					}
				}

				.cover {
					width: 60px;
					height: 60px;
					border-radius: 8px;
				}
			}

			.table {
				border-right: 1px solid #EEEEEE;
				box-sizing: border-box;
				height: 100px;
				box-sizing: border-box;
			}

			.changebtn {
				height: 27px;
				width: 68px;
				background: #FF3F26;
				border-radius: 6px;
				border-color: transparent;
				color: #ffffff;
				font-size: 13px;
				line-height: 27px;
				padding: 0;
			}
		}
	}

	.reply-textarea {
		width: 552px;
		height: 83px;
		border: 1px solid #FF3F26;
		outline: none;
		resize: none;
		padding: 12px 20px;
	}

	.reply-btn {
		width: 96px;
		height: 33px;
	}

	::-webkit-input-placeholder {
		/* WebKit browsers，webkit内核浏览器 */
		color: #999999;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #999999;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #999999;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #999999;
	}
</style>
