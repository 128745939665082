import { render, staticRenderFns } from "./release.vue?vue&type=template&id=0c84cd51&scoped=true&"
import script from "./release.vue?vue&type=script&lang=js&"
export * from "./release.vue?vue&type=script&lang=js&"
import style0 from "./release.vue?vue&type=style&index=0&id=0c84cd51&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c84cd51",
  null
  
)

export default component.exports