import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueRouter from 'vue-router';
import store from './store';
import './plugins/element.js';
import './plugins/charts.js';

import './assets/css/item.css';
import './assets/css/togyUI.css';
import api from './api/api.js';
import utils from './api/utils.js';
import iconImg from '@/components/iconImg';
import Ltitle from '@/components/Ltitle';
import Litem from '@/components/Litem';
import goodsItem from '@/components/goodsItem';
import webSocket from './common/webSocket.js';

// 注册为全局组件
Vue.component('iconImg', iconImg);
Vue.component('Ltitle', Ltitle);
Vue.component('Litem', Litem);
Vue.component('goodsItem', goodsItem);

api.initJump(router);

Vue.prototype.$api = api;
Vue.prototype.$utils = utils;
Vue.prototype.$webSocket = webSocket.webSocket;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
