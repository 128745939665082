<template>
	<div class="fullh100">
		<div class="bg-white boderra10 paddt30 paddl40 fullh100 relative">
			<div class="fz-20 co-343434 font-blod">意见反馈</div>
			<div class="paddl20 flex-row paddt40">
				<span class="fz-14 co-343434 mart13">反馈内容</span>
				<textarea class="content boder1-EEEEEE boderra6 fz-14 paddl20 paddr20 paddt12 paddb12 marl40"
					placeholder="可输入反馈内容" v-model="params.content"></textarea>
			</div>
			<div class="paddl20 mart13 flex-row paddt30">
				<span class="fz-14 co-343434">上传图片</span>
				<div class="relative flex-row-center-center marl40">
					<div class="wh-96 marr10" v-for="(v, i) in params.image" :key="i">
						<img :src="v" alt="" class="wh-96">
					</div>
					<div class="wh-96 relative" v-if="params.image.length < 9">
						<iconImg src='assets/icon_10.png' size='96 96' mode='fit'></iconImg>
						<el-upload class="avatar-uploader absolute all" action="https://admin.yixiangyipin.com/api/upload"
							:show-file-list="false" :on-success="imageSuccess" :before-upload="beforeAvatarUpload">
						</el-upload>
					</div>
				</div>
			</div>

			<div class="ab-bottom absolute flex-row-center-center paddb107">
				<div class="flex-row-center-center submit fz-14 color-white bg-FF3F26 boderra6 pointer" @click="submit">
					提交</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				content: '', // 反馈内容
				image: '', // 上传图片
				loading: false, // 接口加载状态
				params: {
					content: "",
					image: [],
				}
			}
		},
		methods: {
			// 上传图片
			imageSuccess(res) {
				this.params.image = this.params.image.concat([res.data]);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			// 提交
			submit() {
				if (!this.params.content) {
					this.$message.error('请输入反馈内容');
					return false
				}
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/index/feedback',
					isLoading: '加载中...',
					data: this.params,
					success: res => {
						this.$message.success("提交成功");
					},
					fail: data => {
						this.message.error(data.message);
					},
				})
			}
		}
	}
</script>

<style scoped>
	.content {
		outline: none;
		resize: none;
		width: 960px;
		height: 164px;
	}

	.avatar-uploader {
		opacity: 0;
		width: 96px;
		height: 96px;
	}

	.cover {
		width: 96px;
		height: 96px;
	}

	/deep/.avatar-uploader .el-upload {
		width: 96px;
		height: 96px;
	}

	.submit {
		width: 128px;
		height: 40px;
	}

	::-webkit-input-placeholder {
		/* WebKit browsers，webkit内核浏览器 */
		color: #999999;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #999999;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #999999;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #999999;
	}
</style>
