<template>
	<div>
		<div class="flex-row-center-start boderb1-EEEEEE">
			<div class="paddr64 pointer" v-for="(v, i) in titlelist" :key="i" @click="chooseTitle(v)">
				<div class="fz-16 paddb8" :class="cid == v.id ? 'co-FF3F26 font-blod' : 'co-333736'">
					{{ v.title }}
				</div>
				<div class="line w100" :class="cid == v.id ? 'bg-FF3F26' : 'bg-white'"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			list: {
				type: Array,
				default: function() {
					return [];
				},
			},
			titleId: {
				type: [String, Number],
				default: "1",
			},
		},
		data() {
			return {
				titlelist: [],
				cid: 1,
			};
		},
		watch: {
			list: {
				handler(n) {
					this.setList(n);
				},
				immediate: true,
				deep: true
			},
			titleId: {
				handler(n) {
					this.setId(n);
				},
				immediate: true,
				deep: true
			},
		},
		methods: {
			// 选择状态
			chooseTitle(v) {
				this.$emit("change", v);
			},
			// 设置渲染数组
			setList(val) {
				this.titlelist = val;
			},
			// 设置选中id
			setId(val) {
				this.cid = val;
			},
		},
	};
</script>

<style scoped>
	.line {
		height: 3px;
		border-radius: 2px;
	}
</style>
