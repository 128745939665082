<template>
	<div class="content">
		<div class="paddtb30 paddlr40 bg-white content boderra10">
			<div class="fz-20 font-bold">订单管理</div>
			<div>
				<Ltitle class="paddt40" :list="titleList" :titleId="cid" @change="titleChange"></Ltitle>
			</div>
			<div class="tableheader fz-15 mart24">
				<el-row>
					<el-col :span="6">
						<div class="tableheaderone flex-row-center-center">商品</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">规格说明</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">数量</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">运杂费</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">金额</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">状态</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">操作</div>
					</el-col>
				</el-row>
			</div>
			<div class="flex-1 mart16">
				<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
					<li v-for="(item, index) in list" :key="index" class="marb16 pointer" @click="toDetail(item)">
						<div class="tableone">
							<div class="tableoneheader flex-row-center-start color-grey">
								<div class="fz-12">订单编号：{{item.order_sn}}</div>
								<div class="flex-row-center-start marl48">
									<img class="icon" src="@/assets/icon_40@2x.png" alt="" />
									<div class="fz-12 marl10">买家：{{item.address_info.name}}</div>
								</div>
								<div class="flex-row-center-start marl48" v-if="cid != -1" @click.stop="toChat(item)">
									<img class="icon" src="@/assets/icon_41@2x.png" alt="" />
									<div class="fz-12 marl10">联系买家</div>
								</div>
							</div>
							<el-row>
								<el-col :span="6">
									<div class="flex-row paddtb20 paddlr24 table">
										<img class="cover" :src="cid == -1 ? item.good_cover : item.goods.cover" alt="" />
										<div class="marl10 fz-14">{{cid == -1 ? item.good_title : item.goods.title}}</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										<div v-if="cid == -1">
											<div v-for="(v, i) in item.goods" :key="i">{{v.spec_name}}</div>
										</div>
										<div v-else>
											<div v-for="(v, i) in item.spec" :key="i">{{v.spec_name}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										<div v-if="cid == -1">
											<div v-for="(v, i) in item.goods" :key="i">{{v.number}}</div>
										</div>
										<div v-else>
											<div v-for="(v, i) in item.spec" :key="i">x{{v.number}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center font-bold paddtb20 paddlr24 table fz-14">
										<div>
											<div v-if="(item.status == 10)">待确认</div>
											<div v-else>￥{{item.express_price}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										<div>
											<div>￥{{item.price}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										<div v-if="cid != -1">
											<div class="color-red flex-row-center-center font-blod">{{ item.status_text }}</div>
											<div>订单详情</div>
										</div>
										<div v-else>
											<div class="color-red">{{ item.status_text }}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										<div v-if="cid != -1">
											<el-button class="changebtn flex-row-center-center"
												v-if="item.status == 10 || item.status == 20" @click.stop="changePrice(item)">改价
											</el-button>
											<el-button class="changebtn flex-row-center-center" v-else-if="item.status == 30"
												@click.stop="toExpress(item)">去发货</el-button>
											<div v-else-if="item.status == 40 && item.express.express_type == 1"
												@click.stop="$refs.expreddRef.show(item)">
												查看物流
											</div>
											<div v-else-if="item.status == 40 && item.express.express_type == 12" class="color-red">
												*货运方式请拨打联系电话咨询运输情况
											</div>
											<div v-else-if="item.status == 60" @click.stop="clear">
												<el-popconfirm title="是否确认隐藏订单？" @confirm="hiddenOrder(item)">
													<div class="pointer" slot="reference">隐藏订单</div>
												</el-popconfirm>
												<el-popconfirm title="是否确认删除订单？" @confirm="deleteOrder(item)">
													<div class="color-grey pointer" slot="reference">删除订单</div>
												</el-popconfirm>
											</div>
										</div>
										<div v-else>
											<div>订单详情</div>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</li>
				</ul>
			</div>
			<div class="flex-row-center-center mart28">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
					@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
					:total="total" :page-sizes="[10, 15, 20]">
				</el-pagination>
			</div>
		</div>
		<priceChange ref="changeRef" @refreshList="onSubmit" />
		<deliverGoods ref="goodsRef" @refreshList="onSubmit" />
		<checkExpress ref="expreddRef" />
	</div>
</template>
<script>
	import priceChange from "./components/priceChange.vue";
	import deliverGoods from "./components/deliverGoods.vue";
	import checkExpress from "./components/checkExpress.vue";
	export default {
		components: {
			priceChange,
			deliverGoods,
			checkExpress,
		},
		data() {
			return {
				titleList: [{
					id: 0,
					title: "全部",
				},
				{
					id: 10,
					title: "待修改",
				},
				{
					id: 20,
					title: "待付款",
				},
				{
					id: 30,
					title: "待发货",
				},
				{
					id: 40,
					title: "待收货",
				},
				{
					id: 60,
					title: "交易成功",
				},
				{
					id: -1,
					title: "退款售后",
				}],
				cid: 0,

				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,
				citem: "",
			};
		},
		mounted() {
			this.onSubmit();
		},
		methods: {
			titleChange(v) {
				if (this.loading) {
					return false;
				}
				this.cid = v.id;
				this.onSubmit();
				this.$forceUpdate()
			},
			toDetail(item) {
				// 跳转订单详情
				if (this.cid == -1) {
					this.$router.push({
						path: "/order/refundDetail",
						query: {
							id: item.id
						},
					});
					this.cid = -1
					return
				}
				this.$router.push({
					path: "/order/detail",
					query: {
						id: item.id
					},
				});
			},
			changePrice(item) {
				this.$refs.changeRef.show(item);
			},
			toExpress(item) {
				this.$refs.goodsRef.show(item);
			},
			// 隐藏订单
			hiddenOrder(item) {
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/order/hide',
					isLoading: '加载中...',
					data: {
						order_id: item.id,
					},
					success: res => {
						this.$message.success("隐藏成功");
						this.onSubmit();
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 删除订单
			deleteOrder(item) {
				this.$api.tokenRequest({
					method: 'DELETE',
					url: '/store/order',
					isLoading: '加载中...',
					data: {
						order_id: item.id,
					},
					success: res => {
						this.$message.success("删除成功");
						this.onSubmit();
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			load() {

			},
			clear() {
				// 清除冒泡
				return
			},
			toChat(item) {
				let data = {
					type: 1,
					im_user: {
						id: item.im_user.id,
						password: "",
						username: item.im_user.username,
					},
					user: {
						avatar: item.im_user.avatar,
						id: item.im_user.id,
						nickname: item.im_user.nickname,
					}
				}
				// 跳转聊天
				this.$router.push({
					path: '/message/index',
					query: data
				})
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				if (this.loading) {
					return false;
				}
				this.list = [];
				if (this.cid == -1) {
					this.getRefundList()
				} else {
					this.getList();
				}
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				if (this.cid == -1) {
					this.getRefundList()
				} else {
					this.getList();
				}
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/order',
					isLoading: '加载中...',
					data: {
						status: this.cid,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
			// 获取售后订单
			getRefundList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/refund',
					isLoading: '加载中...',
					data: {
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			}
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.tableheader {
			background: #f8f8f8;
			height: 48px;
			border-radius: 8px;
			box-sizing: border-box;

			.tableheaderone {
				color: #999999;
				line-height: 48px;
				box-sizing: border-box;
			}
		}

		.infinite-list {
			width: 100%;
			height: 100%;
			box-sizing: border-box;

			.tableone {
				width: 100%;
				border: 1px solid #eeeeee;
				border-radius: 8px;
				box-sizing: border-box;

				.tableoneheader {
					background: #f8f8f8;
					border-radius: 8px 8px 0 0;
					padding: 12px 24px;

					.icon {
						width: 20px;
						height: 20px;
					}
				}

				.cover {
					width: 60px;
					height: 60px;
					border-radius: 8px;
				}
			}

			.table {
				border-right: 1px solid #eeeeee;
				box-sizing: border-box;
				height: 100px;
				box-sizing: border-box;
			}

			.changebtn {
				height: 27px;
				width: 68px;
				background: #ff3f26;
				border-radius: 6px;
				border-color: transparent;
				color: #ffffff;
				font-size: 13px;
				line-height: 27px;
				padding: 0;
			}
		}
	}
</style>
