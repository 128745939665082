<template>
	<div class="content">
		<gain :details="details" v-if="type == 1" />
		<index @change="successChange" v-else />
	</div>
</template>
<script>
	import index from "./components/index.vue";
	import gain from "./components/gain.vue";
	export default {
		components: {
			index,
			gain
		},
		watch: {
			$route: {
				handler: function(val, oldVal) {
					this.type = val.query.type
				},
				// 深度观察监听
				deep: true,
				immediate: true,
			},
		},
		data() {
			return {
				type: 0, //1展示记录组件 其他展示index组件
			};
		},
		mounted() {
			this.getData();
		},
		methods: {
			successChange() {
				this.getData();
			},
			// 获取盲盒
			getData() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/box',
					isLoading: '加载中...',
					success: res => {
						this.type = 1;
						this.details = res.data;
					},
					fail: data => {
						if (data.code == 999) {
							this.type = 0;
						} else {
							this.$message.error(data.message);
						}
					},
				})
			}
		}
	};
</script>
<style scoped lang="less">
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
</style>
