<template>
	<div>
		<div class="paddtb30 paddlr40 borra10 bg-white">
			<div class="fz-20 font-bold">添加盲盒</div>
			<div class="mart30 flex-row-end-sb">
				<div class="flex-1 flex-row" style="align-items:stretch">
					<img class="cover" :src="info.good_cover" alt="">
					<div class="flex-1 flex-column marl8">
						<div class="fz-16 flex-1">{{info.good_title}}</div>
						<div class="flex-row-center-start">
							<div class="flex-row-end-start color-red font-bold">
								<span class="fz-12 marb2">￥</span>
								<span class="fz-18">{{info.price}}</span>
							</div>
							<span class="color-grey line-through fz-12 marl12">￥{{info.original_price}}</span>
						</div>
					</div>
				</div>
				<div class="flex-row-center-start">
					<span class="fz-14 marr40">盲盒总数：{{info.number}}个</span>
					<div class="flex-row-center-start fz-14">
						<span>剩余数量：</span>
						<span class="color-red">{{info.surplus_number}}个</span>
					</div>
				</div>
			</div>
		</div>
		<div class="paddtb30 paddlr40 borra10 bg-white mart20">
			<div class="fz-20 font-bold">领取情况</div>
			<div class="mart20">
				<div class="paddtb18 line-bottom flex-row-center-sb" v-for="(e,i) in info.receive_list" :key="i">
					<div class="flex-row-center-start flex-1">
						<img class="avatar marr16" :src="e.user.avatar" alt="" />
						<div class="flex-1">
							<div class="fz-15 font-bold">{{e.user.nickname}}</div>
							<div class="mart4 fz-14 color-grey">领取了您的盲盒惊喜</div>
						</div>
					</div>
					<div class="fz-11 color-grey">{{e.create_time}}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			details: {
				type: Object,
				default: function () {
					return {}
				}
			},
		},
		data() {
			return {
				info: "",
			};
		},
		watch: {
			details(newValue) {
				this.setInfo(newValue)
			}
		},
		created() {
			this.setInfo(this.details);
		},
		methods: {
			// 设置渲染信息
			setInfo(val) {
				this.info = val;
			}
		}
	}
</script>
<style scoped lang="less">
	.cover {
		width: 100px;
		height: 100px;
		border-radius: 8px;
	}

	.avatar {
		width: 45px;
		height: 45px;
		border-radius: 50%;
	}
</style>
