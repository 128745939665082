<template>
	<div class="content">
		<div class="paddtb30 paddlr40 content bg-white boderra10">
			<div class="fz-20 font-bold">添加盲盒</div>
			<div class="mart36 purchase flex-column-center-center pointer" @click="toAdd">
				<img src="@/assets/icon_49@2x.png" class="icon" alt="" />
				<span class="fz-16">请选择采购商品</span>
			</div>
		</div>
		<addBlind ref="blindRef" @change="successChange" />
	</div>
</template>
<script>
	import addBlind from './addBlind.vue'
	export default {
		components: {
			addBlind,
		},
		methods: {
			toAdd() {
				this.$refs.blindRef.show();
			},
			successChange() {
				this.$emit("change", {});
				this.$refs.blindRef.handleClose();
			}
		}
	}
</script>
<style scoped lang="less">
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;

		.purchase {
			width: 296px;
			height: 144px;
			border: 1px solid #EEEEEE;
			border-radius: 8px;

			.icon {
				width: 14px;
				height: 14px;
				margin-bottom: 6px;
			}
		}
	}
</style>
