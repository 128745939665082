<template>
	<div>
		<el-dialog title="编辑收货地址" :visible.sync="dialogVisible" width="640px" :before-close="handleClose">
			<div>
				<el-divider />
				<el-form ref="form" label-position="left" label-width="120px" class="mart36">
					<el-form-item label="地址信息" prop="address">
						<el-select class="select-01 select" v-model="delivery_province" placeholder="请选择省"
							@change="provinceChange">
							<el-option v-for="item in province" :key="item.name" :label="item.name" :value="item.id">
								<span style="float: left" class="fz-14">{{ item.name }}</span>
							</el-option>
						</el-select>
						<el-select class="select-01 marl16 select" v-model="delivery_city" placeholder="请选择市"
							@change="cityChange">
							<el-option v-for="item in city" :key="item.name" :label="item.name" :value="item.id">
								<span style="float: left" class="fz-14">{{ item.name }}</span>
							</el-option>
						</el-select>
						<el-select class="select-01 marl16 select" v-model="delivery_area" placeholder="请选择区"
							@change="areaChange">
							<el-option v-for="item in area" :key="item.name" :label="item.name" :value="item.id">
								<span style="float: left" class="fz-14">{{ item.name }}</span>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="详细地址" prop="address">
						<el-input style="width: 446px" v-model="params.address" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<el-form-item label="姓名" prop="name">
						<el-input style="width: 446px" v-model="params.name" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="phone">
						<el-input style="width: 446px" v-model="params.phone" placeholder="请输入手机号码"></el-input>
					</el-form-item>
				</el-form>
				<div class="mart40">
					<div class="flex-row-center-center marb16">
						<el-button type="primary" class="submit" @click="onSubmit">确认提交</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				province: [],
				city: [],
				area: [],
				delivery_province: "",
				delivery_city: "",
				delivery_area: "",
				params: {
					name: "",
					phone: "",
					province_id: "",
					city_id: "",
					area_id: "",
					address: "",
				},
			};
		},
		created() {
			this.getRegion();
		},
		methods: {
			// 省选中
			provinceChange(e) {
				let item = this.province.find(v => v.id == e);
				this.params.province_id = item.id;
				this.city = item.children;
				this.params.city_id = "";
				this.params.area_id = "";
				this.area = [];
				
			},
			// 市选中
			cityChange(e) {
				let item = this.city.find(v => v.id == e);
				this.params.city_id = item.id;
				this.params.area_id = "";
				this.area = item.children;
			},
			// 区选中
			areaChange(e) {
				let item = this.area.find(v => v.id == e);
				this.params.area_id = item.id;
			},
			show() {
				this.dialogVisible = true;
			},
			handleClose() {
				this.dialogVisible = false;
			},
			onSubmit() {
				if (!this.params.province_id) {
					this.$message.error("请选择省");
					return false;
				}
				if (!this.params.city_id) {
					this.$message.error("请选择市");
					return false;
				}
				if (!this.params.area_id) {
					this.$message.error("请选择区");
					return false;
				}
				if (!this.params.address) {
					this.$message.error("请输入详细地址");
					return false;
				}
				if (!this.params.name) {
					this.$message.error("请输入姓名");
					return false;
				}
				if (!this.params.phone) {
					this.$message.error("请输入手机号");
					return false;
				}
				this.$emit("chang", this.params);
			},
			// 获取省市区
			getRegion() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/api/maple/region',
					isLoading: '加载中...',
					data: {
						type: 1
					},
					success: res => {
						this.province = res.data;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			}
		},
	};
</script>
<style lang="less" scoped>
	.el-dialog__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/ .el-dialog {
		border-radius: 10px;
	}

	/deep/ .el-dialog__body {
		padding: 0 24px 40px 24px;
	}

	.submit {
		width: 128px;
		height: 40px;
		background: #ff3f26;
		border-radius: 6px;
		border: 1px solid #ff3f26;
	}

	.select {
		width: 320px;
		height: 44px;
	}

	/deep/.select .el-input {
		height: 44px;
	}

	/deep/.select .el-input__inner {
		height: 44px;
	}

	/deep/.select .el-select__caret {
		transform: rotate(0deg);
	}

	/deep/ .el-upload--picture-card,
	/deep/ .el-upload-list__item-actions,
	/deep/ .el-upload-list--picture-card .el-upload-list__item {
		width: 96px;
		height: 96px;
		border-color: transparent;
	}

	/deep/.select .is-reverse {
		transform: rotate(180deg) !important;
	}

	/deep/.select .el-input__inner:focus {
		border-color: #FF3F26;
	}

	/deep/.select .el-icon-arrow-up::before {
		width: 8px;
		height: 8px !important;
		content: "";
		background: url(../../../assets/icon_12.png) no-repeat;
		background-size: 8px 8px;
		background-position: 0 2px;
		padding: 0 0 0 9.5px;
		box-sizing: border-box;
		font-size: 12px;
	}

	.select-01 {
		width: 130px;
		height: 44px;
		border-radius: 6px;
	}
</style>
