<template>
	<div class="content flex-column">
		<div class="paddlr40 paddt40 paddb24 bg-white flex-1 boderra10 flex-column">
			<div class="fz-20 font-bold paddb30">发票管理</div>
			<Ltitle class="marb24" :list="titleList" :titleId="cid" @change="titleChange"></Ltitle>
			<div class="tableheader fz-15">
				<el-row>
					<el-col :span="9">
						<div class="tableheaderone flex-row-center-center">商品标题</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">规格说明</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">数量</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">运杂费</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">金额</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">操作</div>
					</el-col>
				</el-row>
			</div>
			<div class="flex-1 mart16">
				<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
					<li v-for="(item, index) in list" :key="index" class="marb16">
						<div class="tableone">
							<div class="tableoneheader flex-row-center-start color-grey">
								<div class="fz-12">订单编号：{{item.order_sn}}</div>
								<div class="flex-row-center-start marl48">
									<img class="icon" src="@/assets/icon_53@2x.png" alt="" />
									<div class="fz-12 marl8">买家：{{item.address_info.name}}</div>
								</div>
								<div class="flex-row-center-start marl56 color-grey pointer" @click="toChat(item)">
									<img class="icon" src="@/assets/icon_41@2x.png" alt="" />
									<div class="fz-12 marl10">联系买家</div>
								</div>
							</div>
							<el-row>
								<el-col :span="9">
									<div class="flex-row paddtb20 paddlr24 table">
										<img class="cover" :src="item.goods.cover" alt="" />
										<div class="marl10 fz-14 co-343434">{{item.goods.title}}</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14 co-343434">
										<div>
											<div v-for="(v, i) in item.spec" :accesskey="i">{{v.spec_name}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14 co-343434">
										<div>
											<div v-for="(v, i) in item.spec" :accesskey="i">{{v.number}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div
										class="flex-row-center-center paddtb20 font-bold paddlr24 table co-343434 fz-14">
										￥{{item.express_price}}
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14 co-343434">
										￥{{item.price}}
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center table paddtb20 paddlr24 fz-14  pointer"
										@click="toDetail(item)" :class="cid == 0 ? 'co-1890FF' : 'co-343434'">
										{{cid == 0 ? '开票申请' : '删除'}}
									</div>
								</el-col>
							</el-row>
						</div>
					</li>
				</ul>
			</div>
			<div class="flex-row-center-center mart28">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper" :total="1000">
				</el-pagination>
			</div>
		</div>

		<el-drawer ref="detailPop" class="side-pop" :visible.sync="detailopen" direction="rtl" :show-close="false"
			:destroy-on-close="true" size="1200px">
			<div class="fz-20 co-343434 paddl50 fullh100 relative" v-if="details">
				<span class="font-blod paddt30">开票申请</span>
				<div class="flex-row-center-center absolute top0 right0 paddr40 paddt37 pointer" @click="handleClose">
					<iconImg src='assets/icon_16.png' size='12 12' mode='fit'></iconImg>
				</div>
				<div class="paddr80 paddt36">
					<div class="flex-row-center-start padd20 boder1-EEEEEE boderra8">
						<iconImg status="2" :src='details.order.cover' size='60 60' radius="5" mode='fit'></iconImg>
						<div class="flex-column-sb h60 marl12">
							<span class="fz-13 co-343434">订单编号：{{details.order.order_sn}}</span>
							<div class="flex-row flex-align-end">
								<span class="fz-12 co-343434 l12">开票金额</span>
								<span class="fz-12 font-blod co-FF3F26 l12">￥</span>
								<span class="fz-16 font-blod co-FF3F26 l14">{{details.order.price}}</span>
							</div>
						</div>
					</div>
					<div class="paddt30 flex-row-center-start fz-14 co-343434">
						<span class="w-96">发票类型</span>
						<div class="flex-row-center-start w320 h44 boder1-EEEEEE bg-F8F8F8 boderra6">
							<span class="paddl24">{{details.type_text}}</span>
						</div>
					</div>
					<div class="paddt24 flex-row-center-start fz-14 co-343434">
						<span class="w-96">抬头类型</span>
						<div class="flex-row-center-start w320 h44 boder1-EEEEEE bg-F8F8F8 boderra6">
							<span class="paddl24">{{details.header_type_text}}</span>
						</div>
					</div>
					<div class="paddt36 flex-row-center-start fz-14 co-343434">
						<span class="w-96">接收方式</span>
						<div class="flex-row-center-start">
							<span class="marl12">{{details.receipt_type_text}}</span>
						</div>
					</div>
					<div class="paddt36 flex-row-center-start fz-14 co-343434">
						<span class="w-96">发票抬头</span>
						<div class="flex-row-center-start w600 h44 boder1-EEEEEE bg-F8F8F8 boderra6">
							<span class="paddl24">{{details.invoice_title}}</span>
						</div>
					</div>
					<div class="paddt24 flex-row-center-start fz-14 co-343434" v-if="details.receipt_type == 1">
						<span class="w-96">接收邮箱</span>
						<div class="flex-row-center-start w600 h44 boder1-EEEEEE bg-F8F8F8 boderra6">
							<span class="paddl24">{{details.email}}</span>
						</div>
					</div>
					<div class="paddt24 flex-row-center-start fz-14 co-343434" v-if="details.receipt_type == 2">
						<span class="w-96">税号</span>
						<div class="flex-row-center-start w600 h44 boder1-EEEEEE bg-F8F8F8 boderra6">
							<span class="paddl24">{{details.invoice_number}}</span>
						</div>
					</div>
					<div class="paddt24 flex-row-center-start fz-14 co-343434" v-if="details.receipt_type == 2">
						<span class="w-96">发票地址</span>
						<div class="flex-row-center-start w600 h44 boder1-EEEEEE bg-F8F8F8 boderra6">
							<span class="paddl24">{{details.address_info.full_address}}{{details.address_info.address}}{{details.address}}</span>
						</div>
					</div>
				</div>

				<div class="ab-bottom absolute flex-row-center-center paddb40">
					<div class="flex-row-center-center fz-14 color-white reply-btn boderra6 bg-FF3F26 pointer"
						@click="submit">
						完成开票
					</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				titleList: [{
						id: 0,
						title: "申请中",
					},
					{
						id: 1,
						title: "已完成",
					}
				],
				cid: 0,
				
				detailopen: false, // 详情弹窗
				details: "",
				type: 1,
				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,
			};
		},
		mounted() {
			this.onSubmit();
		},
		methods: {
			titleChange(v) {
				if (this.loading) return;
				this.cid = v.id;
				this.onSubmit();
			},
			// 选择开票类型
			chooseType(type) {
				this.type = type;
			},
			// 加载更多
			load() {},
			// 开票
			toDetail(item) {
				if (this.cid == 0) {
					this.$api.tokenRequest({
						method: 'GET',
						url: '/store/ticket/detail',
						isLoading: '加载中...',
						data: {
							ticket_id: item.id,
						},
						success: res => {
							this.details = res.data;
							this.detailopen = true;
						},
						fail: data => {
							this.message.error(data.message);
						},
					})
				} else {
					this.$confirm('此操作将确认删除开票, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$api.tokenRequest({
							method: 'DELETE',
							url: '/store/ticket',
							isLoading: '加载中...',
							data: {
								ticket_id: item.id,
							},
							success: res => {
								this.$message.success('已删除!');
								this.onSubmit();
							},
							fail: data => {
								this.$message.error(data.message);
							},
						})
					})
				}
			},
			submit() {
				this.$confirm('此操作将确认完成开票, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.tokenRequest({
						method: 'POST',
						url: '/store/ticket',
						isLoading: '加载中...',
						data: {
							ticket_id: this.details.id,
						},
						success: res => {
							this.handleClose();
							this.$message.success('已完成!');
							this.onSubmit();
						},
						fail: data => {
							this.$message.error(data.message);
						},
					})
				})
			},
			// 关闭弹窗
			handleClose() {
				this.detailopen = false;
			},
			// 联系买家
			toChat(item) {
				let data = {
					type: 1,
					im_user: {
						id: item.im_user.id,
						password: "",
						username: item.im_user.username,
					},
					user: {
						avatar: item.im_user.avatar,
						id: item.im_user.id,
						nickname: item.im_user.nickname,
					}
				}
				// 跳转聊天
				this.$router.push({
					path: '/message/index',
					query: data
				})
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/ticket',
					isLoading: '加载中...',
					data: {
						status: this.cid,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.message.error(data.message);
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.tableheader {
			background: #f8f8f8;
			height: 48px;
			border-radius: 8px;
			box-sizing: border-box;

			.tableheaderone {
				color: #999999;
				line-height: 48px;
				box-sizing: border-box;
			}
		}

		.infinite-list {
			width: 100%;
			height: 100%;
			box-sizing: border-box;

			.tableone {
				width: 100%;
				border: 1px solid #EEEEEE;
				border-radius: 8px;
				box-sizing: border-box;

				.tableoneheader {
					background: #f8f8f8;
					border-radius: 8px 8px 0 0;
					padding: 12px 24px;

					.icon {
						width: 14px;
						height: 14px;
					}
				}

				.cover {
					width: 60px;
					height: 60px;
					border-radius: 8px;
				}
			}

			.table {
				border-right: 1px solid #EEEEEE;
				box-sizing: border-box;
				height: 100px;
				box-sizing: border-box;
			}

			.changebtn {
				height: 27px;
				width: 68px;
				background: #FF3F26;
				border-radius: 6px;
				border-color: transparent;
				color: #ffffff;
				font-size: 13px;
				line-height: 27px;
				padding: 0;
			}
		}
	}

	.reply-btn {
		width: 128px;
		height: 40px;
	}
</style>
