/*
 * webSocket.js
 * verson: 1.1.2
 * update time: 2021年6月21日15:00:19
 * company: 拓冠科技
 * author: 夏天的橙子
 * explain： 对websocket的封装
 * */
// 接口配置
let config = {
    heartbeat: 20, // 心跳时间，秒
    maxReconnectionNum: 10, // 失败或错误后，最大重连次数
    action: {
        error: 'error',
        login: "login", // 登录
        ping: "ping", // 心跳包
        recentList: "recentList", // 最近会话列表
        recentListEnd: "recentListEnd", // 最近会话列表，最后一页返回的数据类型
        friendList: "friendList", // 好友列表
        friendListEnd: "friendListEnd", // 分页接收好友列表，最后一页返回的数据类型
        msgRecordHistory: "msgRecordHistory", // 聊天记录列表
        readMsg: "readMsg", // 清空未读消息
        sendMsg: "msg", // 发消息
        msgResult: "msgResult", // 消息发送回执
        recallMsg: "recall", // 撤回消息
		getUnReadNum: "getUnReadNum", // 获取未读消息总数
		getUnReadNumResult: "getUnReadNumResult", // 获取未读消息总数结果
        getCustomerGroupList: 'getCustomerGroupList', // 客服列表
        startCustomerMsg: 'startCustomerMsg', // 发起客服聊天
        sendCustomerMsg: 'customerMsg', // 和客服聊天
        readGroupMsg: 'readGroupMsg', // 清空和客服聊天的未读消息
    }, // action数据类型
};
let webSocket = {
    debug: true,
    url: 'wss://admin.yixiangyipin.com/wss', // websocket的url和端口
    heartbeat: parseInt(config.heartbeat || 10) * 1000, // 心跳时间，秒
    maxReconnectionNum: parseInt(config.maxReconnectionNum || 15), // 失败或错误后，最大重连次数
    action: config.action,
    username: null,
    password: null,
	type: 3,
    socket: null,
    num: 0, // 重新连接的次数
    loading: false, // websocket连接登录中
    timer: null, // 心跳包定时器
    no_token_reconnection_num: 0, // 没有token/url时重新连接的次数
    no_token_reconnection_maxnum: 10, // 没有token/url时重新连接的最大次数
    log: function(txt) {
        this.debug && console.log(txt);
    }
};
let temp_recentList = []; // 临时储存recentList数组，因为recentList是分页返回的，等到全部返回回来了再触发回调传过去
let temp_friendList = []; // 临时储存的friendList数组，因为friendList是分页返回的，等到全部返回回来了再触发回调传过去
webSocket.config = function(params = {}) {
    if (params.url) {
        this.url = params.url;
    }
    // websocket登录账号密码
    if (params.username && params.password) {
        this.username = params.username;
        this.password = params.password;
    }
    // 登录成功回调
    if (params.onLoginSuccess) {
        this.onLoginSuccess = params.onLoginSuccess;
    }
    // webSocket关闭回调
    if (params.onClose) {
        this.onClose = params.onClose;
    }
    // webSocket错误回调
    if (params.onError) {
        this.onError = params.onError;
    }
    // 接收到最近会话列表数据
    if (params.onRecentList) {
        this.onRecentList = params.onRecentList;
    }
    // 接收到好友列表数据
    if (params.onFriendList) {
        this.onFriendList = params.onFriendList;
    }
    // 接收到聊天记录数据
    if (params.onMsgRecordHistory) {
        this.onMsgRecordHistory = params.onMsgRecordHistory;
    }
    // 消息发送结果回调
    if (params.onMsgResult) {
        this.onMsgResult = params.onMsgResult;
    }
    // 接收到消息
    if (params.onMsg) {
        this.onMsg = params.onMsg;
    }
    // 接收到客服列表
    if (params.onCustomerGroupList) {
        this.onCustomerGroupList = params.onCustomerGroupList;
    }
	// 未读消息总数
	if (params.onGetUnReadNumResult) {
	    this.onGetUnReadNumResult = params.onGetUnReadNumResult;
	}
    // 接收到其他消息:除了设置好的消息之外的消息
    if (params.onOtherMessage) {
        this.onOtherMessage = params.onOtherMessage;
    }
}
webSocket.open = function(callback) {
    // websocket登录中
    if (this.loading) return false;
    this.loading = true; // 正在连接
    let token = localStorage.getItem('token');
    if (!token || !this.username || !this.password || !this.url) {
        if (!token) {
            this.log('没token,webSocket连接失败');
        } else if (!this.url) {
            this.log('没url,webSocket连接失败');
        } else if (!this.username) {
            this.log('没username,webSocket连接失败');
        } else if (!this.password) {
            this.log('没password,webSocket连接失败');
        }
        if (this.no_token_reconnection_num < this.no_token_reconnection_maxnum) {
            this.no_token_reconnection_num++;
            let a = setTimeout(() => {
                clearTimeout(a);
                this.loading = false;
                this.open(callback);
            }, 1000)
        } else {
            this.loading = false;
            this.no_token_reconnection_num = 0;
        }
        return false;
    }
    // 清除心跳包定时器
    if (this.timer) {
        clearTimeout(this.timer);
    }
    if (this.socke) {
        let readyState = this.socket.readyState;
        // 0，表示正在连接；1，表示连接成功，可以通信了；2，表示连接正在关闭；3，表示连接已经关闭，或者打开连接失败。
        if (readyState === 1) {
            // 先关闭之前的，等待1s后重新尝试打开websocket
            this.close();
            let a = setTimeout(() => {
                clearTimeout(a);
                this.loading = false;
                this.open(callback);
            }, 500)
            return false;
        } else if (readyState === 0 || readyState === 2) {
            // 正在连接或者关闭，等待1s后重新尝试打开websocket
            let a = setTimeout(() => {
                clearTimeout(a);
                this.loading = false;
                this.open(callback);
            }, 500)
            return false;
        }
    }
    let socket = new WebSocket(this.url + "?username=" + this.username + "&password=" + this.password + "&type=" + this.type);
    // 监听 WebSocket 连接打开事件
    socket.onopen = (res) => {
        this.log('webSocket连接打开', res);
    };
    // 监听 WebSocket 接受到服务器的消息事件
    socket.onmessage = (res) => {
        this.loading = false;
        if (res) {
            let result = JSON.parse(res.data);
            switch (result.action) {
                case this.action.ping:
                    this.log("ping成功");
                    break;
                case "error":
                    this.log("错误信息" + JSON.stringify(result));
                    break;
                case this.action.login:
                    // 登录
                    if (result.data.code == "0") {
                        // 登录成功
                        this.log("登录成功");
                        this.num = 0;
                        this.info = result.data.info; // 保存我的websocket信息
                        callback && typeof callback === "function" && callback();
                        this.onLoginSuccess && typeof this.onLoginSuccess === "function" &&
                            this
                            .onLoginSuccess(result.data);
                        let a = setTimeout(() => {
                            clearTimeout(a);
                            this.checkConnect(); // 开启心跳包
                        }, this.heartbeat)
                    } else {
                        this.log("登录返回信息" + JSON.stringify(result));
                    }
                    break;
                case this.action.recentList:
                    // 最近会话列表
                    temp_recentList = [...temp_recentList, ...result.data];
                    break;
                case this.action.recentListEnd:
                    // 最近会话列表，结束
                    temp_recentList = [...temp_recentList, ...result.data];
                    this.log("接收到最近会话列表");
                    this.onRecentList && typeof this.onRecentList === "function" &&
                        this.onRecentList({
                            action: this.action.recentList,
                            data: temp_recentList
                        });
                    delay(() => {
                        temp_recentList = [];
                    })
                    break;
                case this.action.friendList:
                    // 好友列表
                    temp_friendList = [...temp_friendList, ...result.data];
                    break;
                case this.action.friendListEnd:
                    // 好友列表，结束
                    temp_friendList = [...temp_friendList, ...result.data];
                    this.log("接收到好友列表");
                    this.onFriendList({
                        action: this.action.friendList,
                        data: temp_friendList
                    });
                    delay(() => {
                        temp_friendList = [];
                    });
                    break;
                case this.action.msgRecordHistory:
                    // 聊天记录
                    this.log("接收到聊天记录");
                    this.onMsgRecordHistory(result.data);
                    break;
                case this.action.msgResult:
                    // 消息发送结果
                    this.log("消息发送结果");
                    this.onMsgResult(result.data);
                    break;
                case this.action.sendMsg:
                    // 接收到消息
                    this.log("接收到消息");
                    this.onMsg(result.data);
                    break;
                case this.action.getCustomerGroupList:
                    // 获取客服列表
                    this.log("接收到客服列表");
                    this.onCustomerGroupList(result.data);
                    break;
				case this.action.getUnReadNumResult:
				    // 未读消息总数
				    this.log("未读消息总数");
				    this.onGetUnReadNumResult(result.data);
				    break;
                default:
                    // 其他消息
                    this.log("接收到其他消息" + JSON.stringify(result));
                    this.onOtherMessage(result);
                    break;
            }
        }
    };
    // 监听 WebSocket 连接关闭事件
    socket.onclose = (res) => {
        this.log('webSocket连接关闭', res);
        this.loading = false;
        if (this.timer) {
            clearTimeout(this.timer); // 清除心跳包定时器
        }
        // 重新连接
        if (this.num < this.maxReconnectionNum) {
            let b = setTimeout(() => {
                this.open();
                clearTimeout(b);
            }, 1500)
        } else if (this.onClose) {
            // 达到最大重连次数还未成功，放弃
            this.onClose(res);
        }
    };
    // 监听 WebSocket 错误事件
    socket.onerror = (res) => {
        this.log('webSocket错误', res);
        this.loading = false;
        if (this.timer) {
            clearTimeout(this.timer); // 清除心跳包定时器
        }
        // 重新连接
        if (this.num < this.maxReconnectionNum) {
            let b = setTimeout(() => {
                this.open();
                clearTimeout(b);
            }, 1500)
        } else if (this.onError) {
            // 达到最大重连次数还未成功，放弃
            this.onError(res);
        }
    };
    this.socket = socket;
}
/* *
 * 发消息
 * data:{
 *     "action": "msg",
 *     "data": {
 *         "msg_id" : 2342344,
 *         "type" : 1,
 *         "to_uid" : 1,
 *         "content" : "内容" ,
 *         "ext" : ""
 *     }
 * }
 * 
 * */
webSocket.sendMessage = function(params) {
    // 登录中
    if (this.loading) {
        let a = setTimeout(() => {
            // 重新发消息
            this.sendMessage(params);
            clearTimeout(a);
        }, 1000)
        return false
    }
    if (this.socket) {
        let readyState = this.socket.readyState;
        // 0，表示正在连接；1，表示连接成功，可以通信了；2，表示连接正在关闭；3，表示连接已经关闭，或者打开连接失败。
        if (readyState === 1) {
            if (!params.data.action) {
                this.log('请求类型错误');
                return false
            }
            this.log('webSocket发消息' + JSON.stringify(params.data));
            // 发送
            this.socket.send(JSON.stringify(params.data));
            params.success && typeof params.success === "function" && params.success();
        } else if (readyState === 0 || readyState === 2) {
            let a = setTimeout(() => {
                clearTimeout(a);
                // 重新发消息
                this.sendMessage(params);
            }, 500)
        } else {
            // 先去打开websocket
            this.open(() => {
                // 重新发消息
                this.sendMessage(params);
            });
        }
    } else {
        // 先去打开websocket
        this.open(() => {
            // 重新发消息
            this.sendMessage(params);
        });
    }
}
let check_timer = '';
// 检查连接
webSocket.checkConnect = function() {
    clearTimeout(check_timer)
    check_timer = setTimeout(() => {
        clearTimeout(check_timer);
        clearTimeout(this.timer);
        // 登录中
        if (this.loading) {
            let a = setTimeout(() => {
                // 重新发消息
                this.checkConnect();
                clearTimeout(a);
            }, 1000)
            return false
        }
        if (this.socket) {
            let readyState = this.socket.readyState;
            // 0，表示正在连接；1，表示连接成功，可以通信了；2，表示连接正在关闭；3，表示连接已经关闭，或者打开连接失败。
            if (readyState === 1) {
                if (this.action.ping) {
                    this.sendMessage({
                        data: {
                            action: this.action.ping
                        },
                        success: () => {
                            this.timer = setTimeout(() => {
                                clearTimeout(this.timer);
                                this.checkConnect();
                            }, this.heartbeat); // 心跳包
                        },
                        fail: () => {
                            this.close();
                        }
                    });
                } else {
                    this.log("websocket未配置心跳包action");
                }
            } else if (readyState === 0 || readyState === 2) {
                let b = setTimeout(() => {
                    clearTimeout(b);
                    // 重新发消息
                    this.sendMessage(params);
                }, 500)
            } else {
                this.open();
            }
        } else {
            this.open();
        }
    }, 350)
}
/* *
 * 关闭webSocket
 * */
webSocket.close = function() {
    if (this.loading) {
        let a = setTimeout(() => {
            clearTimeout(a);
            this.close();
        }, 500)
        return false
    }
    if (this.socket) {
        let readyState = this.socket.readyState;
        // 0，表示正在连接；1，表示连接成功，可以通信了；2，表示连接正在关闭；3，表示连接已经关闭，或者打开连接失败。
        if (readyState === 1) {
            this.num = 100;
            this.socket.close();
        } else if (readyState === 0) {
            let b = setTimeout(() => {
                clearTimeout(b);
                this.close();
            }, 500)
        }
    }
}
// 获取最近会话列表
webSocket.getRecentList = function() {
    let action = this.action.recentList || "";
    if (!action) {
        this.log("websocket未配置最近会话列表action");
        return false;
    }
    this.sendMessage({
        data: {
            action: action
        }
    });
}
// 未读消息总数
webSocket.getUnReadNum = function() {
    let action = this.action.getUnReadNum || "";
    if (!action) {
        this.log("websocket未配置未读消息总数action");
        return false;
    }
    this.sendMessage({
        data: {
            action: action
        }
    });
}
// 获取好友列表
webSocket.getFriendList = function() {
    let action = this.action.friendList || '';
    if (!action) {
        this.log("websocket未配置好友列表action");
        return false
    }
    this.sendMessage({
        data: {
            action: action
        }
    });
}
// 获取聊天记录
webSocket.getMsgRecordHistory = function(params = {}) {
    let action = this.action.msgRecordHistory || '';
    if (!action) {
        this.log("websocket未配置聊天记录action");
        return false;
    }
    if (!params.data) {
        this.log('参数错误');
        return false;
    }
    let data = params.data;
    if (!data.to_uid) {
        this.log('对方用户id不能为空');
        return false
    }
    if (!data.size) {
        data.size = 50;
    }
    data.date = data.date || "";
    data.keyword = data.keyword || "";
    params.data = {
        action: action,
        data: data
    }
    this.sendMessage(params);
}
// 清空未读消息
webSocket.clearUnReadMsg = function(params) {
    let action = this.action.readMsg || '';
    if (!action) {
        this.log("websocket未配置清空未读消息action");
        return false;
    }
    if (!params.data) {
        this.log('参数错误');
        return false;
    }
    let data = params.data;
    if (!data.uid) {
        this.log('对方用户id不能为空');
        return false
    }
    params.data = {
        action: action,
        data: data
    }
    this.sendMessage(params);
}
// 清空和客服聊天的未读消息
webSocket.readGroupMsg = function(params) {
    let action = this.action.readGroupMsg || '';
    if (!action) {
        this.log("websocket未配置清空客服聊天未读消息action");
        return false;
    }
    if (!params.data) {
        this.log('参数错误');
        return false;
    }
    let data = params.data;
    if (!data.uid) {
        this.log('对方用户id不能为空');
        return false
    }
    params.data = {
        action: action,
        data: data
    }
    this.sendMessage(params);
}
// 发起客服聊天
webSocket.startCustomerMsg = function(params) {
    let action = this.action.startCustomerMsg || '';
    if (!action) {
        this.log("websocket未配置发起客服聊天action");
        return false;
    }
    if (!params.data) {
        this.log('参数错误');
        return false;
    }
    let data = params.data;
    if (!data.type) {
        this.log('对方用户id不能为空');
        return false
    }
    if (!data.to_uid) {
        this.log('对方用户id不能为空');
        return false
    }
    if (!data.content) {
        this.log('发送内容不能为空');
        return false
    }
    // 唯一消息id ： 时间戳 + 100-999的随机整数,这个id是发送消息时生成的，可用于校验消息是否发送成功等的操作；
    data.msg_id = parseInt(new Date().getTime()) * 100 + parseInt(Math.random() * (999 - 100 + 1) + 100, 10);
    params.data = {
        action: action,
        data: data
    }
    this.sendMessage(params);
}
// 发送消息(与客服对话)
webSocket.sendCustomerMsg = function(params) {
    let action = this.action.sendCustomerMsg || '';
    if (!action) {
        this.log("websocket未配置发送消息(与客服对话)action");
        return false;
    }
    if (!params.data) {
        this.log('参数错误');
        return false;
    }
    let data = params.data;
    if (!data.type) {
        this.log('发送消息类型不能为空');
        return false
    }
    if (!data.to_uid) {
        this.log('对方用户id不能为空');
        return false
    }
    if (!data.content) {
        this.log('发送内容不能为空');
        return false
    }
    // 唯一消息id ： 时间戳 + 100-999的随机整数,这个id是发送消息时生成的，可用于校验消息是否发送成功等的操作；
    data.msg_id = parseInt(new Date().getTime()) * 100 + parseInt(Math.random() * (999 - 100 + 1) + 100, 10);
    params.data = {
        action: action,
        data: data
    }
    this.sendMessage(params);
}
// 发送消息
webSocket.sendMsg = function(params) {
    let action = this.action.sendMsg || '';
    if (!action) {
        this.log("websocket未配置发送消息action");
        return false;
    }
    if (!params.data) {
        this.log('参数错误');
        return false;
    }
    // 消息类型: 1文字，2图片，3语音
    let data = params.data;
    if (!data.type) {
        this.log('发送消息类型不能为空');
        return false
    }
    if (!data.to_uid) {
        this.log('对方用户id不能为空');
        return false
    }
    if (!data.content) {
        this.log('发送内容不能为空');
        return false
    }
    // 唯一消息id ： 时间戳 + 100-999的随机整数,这个id是发送消息时生成的，可用于校验消息是否发送成功等的操作；
    data.msg_id = parseInt(new Date().getTime()) * 100 + parseInt(Math.random() * (999 - 100 + 1) + 100, 10);
    params.data = {
        action: action,
        data: data
    }
    this.sendMessage(params);
}
// 撤回消息
webSocket.recallMsg = function(params = {}) {
    let action = this.action.recallMsg || '';
    if (!action) {
        this.log("websocket未配置撤回消息action");
        return false;
    }
    if (!params.data) {
        this.log('参数错误');
        return false;
    }
    let data = params.data;
    if (!data.to_uid) {
        this.log('对方用户id不能为空');
        return false
    }
    if (!data.recall_id) {
        this.log('消息id不能为空');
        return false
    }
    params.data = {
        action: action,
        data: data
    }
    this.sendMessage(params);
}
// 获取客服列表
webSocket.getCustomerGroupList = function() {
    let action = this.action.getCustomerGroupList || "";
    if (!action) {
        this.log("websocket未配置客服列表action");
        return false;
    }
    this.sendMessage({
        data: {
            action: action
        }
    });
}
let delay = function(callback, time = 16) {
    if (time <= 16) {
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(() => {
                callback();
            });
        } else {
            let a = setTimeout(() => {
                clearTimeout(a);
                callback();
            }, time)
        }
    } else {
        let a = setTimeout(() => {
            clearTimeout(a);
            callback();
        }, time)
    }
}
export default {
	webSocket
}
