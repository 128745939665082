<template>
	<el-dialog :visible.sync="dialogVisible" width="400px" :before-close="handleClose" :show-close="false">
		<div class="paddlr40">
			<div class="flex-row-center-center">
				<img class="icon" src="@/assets/icon_31@2x.png" alt="">
			</div>
			<div class="mart44 text-center fz-20 font-bold">报名成功</div>
			<div class="mart10 color-grey fz-14 text-center">请等待审核，报名情况可在我的-活动报名查看</div>
			<div class="mart40 btn color-white fz-16 flex-row-center-center pointer">
				前往我的活动报名
			</div>
			<div class="mart16 btnback fz-16 flex-row-center-center pointer" @click="handleClose">
				返回商家招募列表
			</div>
			<div class="closewrap pointer flex-row-center-center" @click="handleClose">
				<img class="close" src="@/assets/icon_15.png" alt="" />
			</div>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				way: 1,
			}
		},
		methods: {
			show() {
				this.dialogVisible = true
			},
			handleClose() {
				this.dialogVisible = false
			},
			choose(value) {
				this.way = value
			}
		}
	};
</script>
<style lang="less" scoped>
	.el-dialog__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			width: 134px;
			height: 110px;
		}

		.btn {
			width: 320px;
			height: 44px;
			background: #FF3F26;
			border-radius: 8px;
		}

		.btnback {
			width: 320px;
			height: 44px;
			border: 1px solid #FF3F26;
			border-radius: 8px;
			color: #FF3F26;
		}

		.closewrap {
			position: absolute;
			top: 0;
			right: 0;
			width: 52px;
			height: 52px;

			.close {
				width: 20px;
				height: 20px;
			}
		}
	}

	/deep/ .el-dialog {
		border-radius: 10px;
	}

	/deep/ .el-dialog__body {
		padding: 0 0 40px 0;
	}
</style>
