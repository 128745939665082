<template>
	<div>
		<el-dialog :visible.sync="dialogVisible" width="640px" :before-close="handleClose" class="dialog-pop">
			<template slot="title">
				<span class="fz-18 font-blod co-343434 paddb20 boderb1-EEEEEE">开票信息</span>
			</template>
			<div class="paddt28">
				<div class="flex-row-center-start fz-16">
					<span class="co-999999 w160">发票类型</span>
					<span class="co-343434">{{details.type == 1 ? '电子普通发票' : '增值税专用发票'}}</span>
				</div>
				<div class="flex-row-center-start fz-16 paddt30">
					<span class="co-999999 w160">抬头类型</span>
					<span class="co-343434">{{details.type == 1 ? '个人或事业单位' : '企业'}}</span>
				</div>
				<div class="flex-row-center-start fz-16 paddt30">
					<span class="co-999999 w160">接收方式</span>
					<span class="co-343434">{{details.receipt_type == 1 ? '电子邮箱' : '邮寄'}}</span>
				</div>
				<div class="flex-row-center-start fz-16 paddt30">
					<span class="co-999999 w160">发票抬头</span>
					<span class="co-343434">{{details.invoice_title}}</span>
				</div>
				<div class="flex-row-center-start fz-16 paddt30" v-if="details.receipt_type == 1">
					<span class="co-999999 w160">接收邮箱</span>
					<span class="co-343434">{{details.email}}</span>
				</div>
				<div class="flex-row-center-start fz-16 paddt30" v-if="details.receipt_type == 2">
					<span class="co-999999 w160">税号</span>
					<span class="co-343434">{{details.invoice_number}}</span>
				</div>
				<div class="flex-row-center-start fz-16 paddt30" v-if="details.receipt_type == 2">
					<span class="co-999999 w160">邮寄地址</span>
					<span class="co-343434">{{details.address}}</span>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				details: "",
				dialogVisible: false,
			};
		},
		methods: {
			show(val) {
				this.details = val;
				this.dialogVisible = true;
			},
			handleClose() {
				this.dialogVisible = false;
			},
		},
	};
</script>
<style lang="less" scoped>
	.el-dialog__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/ .el-dialog {
		border-radius: 10px;
	}
	
	/deep/ .el-dialog__header {
		padding: 24px 24px 0 24px;
	}

	/deep/ .el-dialog__body {
		padding: 0 24px 40px 24px;
	}

	.submit {
		width: 128px;
		height: 40px;
		background: #ff3f26;
		border-radius: 6px;
		border: 1px solid #ff3f26;
	}

	.select {
		width: 320px;
		height: 44px;
	}

	/deep/.select .el-input {
		height: 44px;
	}

	/deep/.select .el-input__inner {
		height: 44px;
	}

	/deep/.select .el-select__caret {
		transform: rotate(0deg);
	}

	/deep/ .el-upload--picture-card,
	/deep/ .el-upload-list__item-actions,
	/deep/ .el-upload-list--picture-card .el-upload-list__item {
		width: 96px;
		height: 96px;
		border-color: transparent;
	}

	/deep/.select .is-reverse {
		transform: rotate(180deg) !important;
	}

	/deep/.select .el-input__inner:focus {
		border-color: #FF3F26;
	}

	/deep/.select .el-icon-arrow-up::before {
		width: 8px;
		height: 8px !important;
		content: "";
		background: url(../../../assets/icon_12.png) no-repeat;
		background-size: 8px 8px;
		background-position: 0 2px;
		padding: 0 0 0 9.5px;
		box-sizing: border-box;
		font-size: 12px;
	}

	.select-01 {
		width: 130px;
		height: 44px;
		border-radius: 6px;
	}
</style>
