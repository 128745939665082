<template>
	<div>
		<el-dialog title="查看物流" :visible.sync="dialogVisible" width="720px" :before-close="handleClose">
			<div style="height: 500px; overflow: scroll;">
				<el-divider />
				<div class="mart24 express marb30">
					<div class="flex-row-center-start fz-14 marb20">
						<span class="label color-grey fz-14">快递公司</span>
						<span>{{details.title}}</span>
					</div>
					<div class="flex-row-center-start fz-14">
						<span class="label color-grey fz-14">物流单号</span>
						<span>{{details.number}}</span>
					</div>
				</div>
				<el-steps direction="vertical">
					<el-step v-for="(item, index) in details.track" :key="index"
						:class="{ stepactive: active > item.status }">
						<template v-slot:icon>
							<div>
								<div class="icon" v-if="index == 0"></div>
								<div class="icon1" v-else src="@/assets/icon_43@2x.png"></div>
							</div>
						</template>
						<template v-slot:title>
							<div>
								<div class="fz-14 color-black">
									{{ item.context }}
								</div>
								<div>
									<div class="fz-12">{{ item.time }}</div>
								</div>
							</div>
						</template>
					</el-step>
				</el-steps>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				details: "",
				dialogVisible: false,
				active: 1,
			};
		},
		methods: {
			show(val) {
				console.log(val)
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/order/express',
					isLoading: '加载中...',
					data: {
						express_id: val.express.id,
					},
					success: res => {
						this.details = res.data;
						this.dialogVisible = true;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			handleClose() {
				this.dialogVisible = false;
			},
		},
	};
</script>
<style lang="less" scoped>
	.express {
		background: #f9f9f9;
		width: 660px;
		height: 98px;
		border-radius: 10px;
		box-sizing: border-box;
		padding: 20px 24px;

		.label {
			width: 100px;
		}
	}

	.el-dialog__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/ .el-step__icon.is-text {
		padding: 0;
		border: 0px solid transparent;
		width: 10px;
		height: 10px;
	}

	/deep/ .el-step.is-vertical .el-step__line {
		left: 4px;
		top: 5px;
		bottom: -5px;
		background: #EEEEEE;
	}

	/deep/ .el-step__main {
		padding-bottom: 40px;
	}

	/deep/ .el-step {
		align-items: stretch;
	}

	/deep/ .el-dialog {
		border-radius: 10px;
	}

	/deep/ .el-dialog__body {
		padding: 0 24px 40px 24px;
	}

	.icon {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #FF3F26;
	}

	.icon1 {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #EEEEEE;
	}

	/deep/ .el-step__title {
		line-height: inherit;
		font-weight: normal !important;
	}

	.color-black {
		color: #343434;
	}
</style>
