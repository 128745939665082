<template>
  <div class="content">
    <div class="bg-white paddtb40 paddlr30 borra10 flex-column content">
      <div class="flex-row-center-sb">
        <div class="fz-20 font-bold">嘚瑟直播</div>
        <div class="report flex-row-center-center pointer" @click="toAppoint">预约直播</div>
      </div>
      <div>
        <Ltitle
          class="paddt40"
          :list="titleList"
          :titleId="cid"
          @change="titleChange"
        ></Ltitle>
      </div>
      <div class="mart28 flex-1">
        <el-scrollbar>
          <div class="flex-row-center-start flex-wrap">
            <div class="item" v-for="(item,index) in 40" :key="index" @click="goDetail">
              <img class="cover" src="@/assets/img_4@2x.png" alt="" />
              <div class="flex-row-center-start status">
                <img src="@/assets/icon_25@2x.png" v-if="item == 3" alt="">
                <img src="@/assets/icon_24@2x.png" v-else alt="">
                <span class="fz-11 color-white statustxt">{{item == 3?'回放直播':'直播中'}}</span>
              </div>
              <div class="mart12 fz-16 font-bold">五常大米专场介绍</div>
              <div class="mart8 flex-row-center-start">
                <img class="avatar" src="@/assets/img_4@2x.png" alt="">
                <span class="fz-14 marl8">江大小S</span>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="mart34">
        <div class="flex-row-center-center">
          <el-pagination
            class="paging"
            background
            layout="prev, pager, next, sizes, jumper"
            :total="1000"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <appointDialog ref="dialogRef" />
  </div>
</template>
<script>
import appointDialog from './components/appointDialog.vue'
export default {
  components:{
    appointDialog
  },
  data() {
    return {
      titleList: [
        {
          id: 1,
          title: "热门直播",
        },
        {
          id: 2,
          title: "我的关注",
        },
      ],
      cid: 1,
    };
  },
  methods: {
    // 改变状态
    titleChange(v) {
      this.cid = v.id;
    },
    goDetail(){
      this.$router.push({ path: '/broadcast/detail' });
    },
    toAppoint(){
      this.$refs.dialogRef.show()
    }
  },
};
</script>
<style lang="less" scoped>
.content{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .report {
  width: 88px;
  height: 32px;
  border: 1px solid #ff3f26;
  border-radius: 5px;
  color: #ff3f26;
}
/deep/ .el-scrollbar{
  height: 100%;
}
.item{
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  width: 296px;
  .cover{
    width: 296px;
    height: 296px;
    border-radius: 10px;
  }
  .status{
    position: absolute;
    top:16px;
    left:16px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    img{
      width: 22px;
      height: 22px;
    }
    .statustxt{
      padding: 2px 8px 2px 2px;
    }
  }
  .avatar{
      width: 22px;
      height: 22px;
      border-radius: 50%;
    }
}
}
</style>
