<template>
	<div>
		<el-drawer title="招募详情" size="1000px" :visible.sync="drawer" :before-close="handleClose">
			<div class="mart26 paddlr50">
				<!-- 这里放的是富文本框，暂时先用图片代替 -->
				<div v-html="details.content"></div>
				<div>
					<el-form ref="form" label-position="left" label-width="120px" class="mart40 paddlr50">
						<el-form-item label="您的姓名" prop="name">
							<el-col :span="12">
								<el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="联系方式" prop="phone">
							<el-col :span="12">
								<el-input v-model="form.phone" placeholder="请输入您的联系方式"></el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="活动商品" prop="good_id">
							<div class="infinite-list good_area flex-row flex-wrap" style="overflow: auto">
								<div class="shop marr20 pos-r" v-for="(item, index) in shoplist" :key="index">
									<img class="cover" :src="item.cover" alt="" />
									<div class="paddlr12 paddtb6">
										<div class="fz-15">{{item.title}}</div>
										<div class="flex-row-center-start mart6 color-red font-bold">
											<span class="fz-12">￥</span>
											<span class="fz-18">{{item.price}}</span>
										</div>
									</div>
									<div class="chooseicon pointer flex-row-center-center" @click="chooseShop(item)">
										<img :src="item.id == form.good_id ? require('@/assets/icon_14.png') : require('@/assets/icon_48@2x.png')"
											alt="" />
									</div>
								</div>
							</div>
							<div class="bg-F9F9F9 boderra10 paddt26" v-if="speclist.length > 0">
								<div class="marlr40 boderb1-EEEEEE flex-row paddb18">
									<div class="w-100 co-343434 fz-14 mart8">规格</div>
									<div class="flex-row-wrap">
										<div class="marr20 spec-item flex-row-center-center pointer" :class="{'spec-item-active': specIndex == i}"
											v-for="(v, i) in speclist" :key="i" @click="specChoose(i)">{{v.title}}</div>
									</div>
								</div>
								<div class="marlr40 paddt15 flex-row-center-start paddb32">
									<div class="w-100"></div>
									<div class="flex-row-center-start marl20">
										<div class="marr24">起批量：{{speclist[specIndex].batch}}个起</div>
										<div class="flex-row-center-start fz-14 co-343434">
											<span>原价：</span>
											<span class="font-blod">{{speclist[specIndex].price}}</span>
											<span>/{{speclist[specIndex].unit}}</span>
										</div>
									</div>
								</div>
								<div class="marlr40 flex-row-center-start paddb24">
									<div class="w-100 co-343434 fz-14 mart8">提供数量</div>
									<el-col :span="8">
										<el-input v-model="form.num" placeholder="请输入活动提供数量"></el-input>
									</el-col>
									<div class="fz-14 co-343434 marl12">个</div>
								</div>
								<div class="marlr40 flex-row-center-start paddb30">
									<div class="w-100 co-343434 fz-14 mart8">活动价格</div>
									<el-col :span="8">
										<el-input v-model="form.price" placeholder="请输入活动价格，不可超过原价"></el-input>
									</el-col>
									<div class="fz-14 co-343434 marl12">元</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="参与活动优势" prop="desc">
							<el-input class="textarea" type="textarea" v-model="form.desc" placeholder="请输入参与活动的优势">
							</el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="bottom paddr40">
				<el-divider></el-divider>
				<div class="flex-row-center-end marb16">
					<div class="flex-row-center-start marr30">
						<div class="flex-row-center-start mart10">
							<span class="fz-12">报名费：</span>
							<span class="color-red fz-12">￥</span>
						</div>
						<span class="color-red fz-22">{{details.price}}</span>
					</div>
					<el-button type="primary" class="submit" @click="onSubmit">提交</el-button>
				</div>
			</div>
		</el-drawer>
		<payDialog ref="payRef" />
	</div>
</template>
<script>
	import payDialog from "./payDialog.vue";
	var timer;
	
	export default {
		components: {
			payDialog,
		},
		data() {
			return {
				details: "", // 详情
				drawer: false,
				form: {
					name: "",
					phone: "",
					good_id: "",
					spec_id: "",
					desc: "",
					order_type: 2,
					cloud_id: "",
					num: "",
					price: "",
				},

				loading: false,
				page: 1,
				status: 1,
				shoplist: [],
				speclist: [],
				specIndex: 0,
				specInfo: "",
				order_id: "", // 订单id
			};
		},
		methods: {
			// 选择规格
			specChoose(i) {
				this.specIndex = i;
				this.form.num = "";
				this.form.price = "";
			},
			show(id) {
				this.form.cloud_id = id;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/Cloud/detail',
					isLoading: '加载中...',
					data: {
						id: id,
					},
					success: res => {
						this.details = res.data;
						this.drawer = true;
						this.refreshList();
					},
					fail: data => {
						this.message.error(data.message);
					},
				})
			},
			removeTime() {
				clearTimeout(timer);
			},
			handleClose() {
				this.drawer = false;
			},
			onSubmit() {
				if (!this.form.name) {
					this.$message.error("请输入姓名");
					return false;
				}
				if (!this.form.phone) {
					this.$message.error("请输入联系方式");
					return false;
				}
				if (!this.form.good_id) {
					this.$message.error("请选择商品");
					return false;
				}
				if (!this.form.desc) {
					this.$message.error("请输入优势");
					return false;
				}
				if (!this.form.num) {
					this.$message.error("请输入提供数量");
					return false;
				}
				if (!this.form.price) {
					this.$message.error("请输入价格");
					return false;
				}
				this.form.spec_id = this.speclist[this.specIndex].id;
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/Cloud',
					isLoading: '加载中...',
					data: this.form,
					success: res => {
						this.$api.tokenRequest({
							method: 'POST',
							url: '/store/AdOrder/pay',
							isLoading: '获取支付信息...',
							data: {
								order_id: res.data.id,
								pay_type: 2,
							},
							success: res1 => {
								this.order_id = res.data.id;
								this.loading = false;
								this.$refs.payRef.show(res1.data.params.code_url, res.data.id);
								this.searchResult();
							},
							fail: data => {
								this.loading = false;
								this.$message.error(data.message);
							},
						})
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
			chooseShop(item) {
				this.form.good_id = item.id;
				this.form.num = "";
				this.form.price = "";
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/Cloud/goodspec',
					isLoading: '加载中...',
					data: {
						good_id: item.id,
					},
					success: res => {
						this.speclist = res.data;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 获取商品列表
			getList() {
				if (this.loading) return
				if (this.status == 3) return
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/Cloud/goodlist',
					isLoading: '加载中...',
					data: {
						cloud_id: this.form.cloud_id,
						good_cate: this.details.good_cate,
						page: this.page,
						size: 20,
					},
					success: res => {
						this.loading = false;
						this.shoplist = this.shoplist.concat(res.data.data);
						if (res.data.data.length > 0) {
							this.page = this.page + 1;
						}
						if (res.data.data.length == 0) {
							this.status = 3;
						}
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
			// 刷新列表
			refreshList() {
				this.page = 0;
				this.status = 1;
				this.shoplist = [];
				this.getList();
			},
			// 轮询支付结果
			searchResult() {
				if (timer) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					this.$api.tokenRequest({
						method: 'GET',
						url: '/store/AdOrder/PaySearch',
						data: {
							order_id: this.order_id
						},
						success: res => {
							clearTimeout(timer);
							this.$router.push({
								path: "/mine/activity",
								query: {
									cid: 3
								}
							});
						},
						fail: data => {
							if (data.code == 999) {
								this.searchResult();
							} else {
								this.message.error(data.message);
							}
						},
					})
				}, 3000);
			}
		},
	};
</script>
<style lang="less" scoped>
	/deep/ .el-scrollbar__wrap {
		width: 100%;
	}

	.shop {
		width: 160px;
		border: 1px solid #eeeeee;
		border-radius: 8px;
		flex-shrink: 0;

		.cover {
			width: 160px;
			height: 160px;
			border-radius: 8px 8px 0 0;
		}

		.chooseicon {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 40px;

			img {
				width: 20px;
				height: 20px;
			}
		}
	}

	/deep/ .el-form-item__content {
		line-height: inherit;
	}

	/deep/ .el-drawer__header {
		color: #343434;
		font-weight: bold;
	}

	/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		opacity: 0;
		margin-right: 0;
	}

	.html {
		width: 900px;
		height: 160px;
		border-radius: 10px;
	}

	.textarea {
		height: 184px;
	}

	/deep/ .el-textarea__inner {
		height: 184px;
	}

	.bottom {
		margin-top: 138px;

		.submit {
			width: 128px;
			height: 40px;
			background: #ff3f26;
			border-radius: 6px;
			border: 1px solid #ff3f26;
		}
	}

	.infinite-list {
		width: 100%;
	}

	.good_area {
		height: 500px;
	}

	.spec-item {
		padding: 0 32px;
		height: 36px;
		background: #FFFFFF;
		border-radius: 6px;
		border: 1px solid #EEEEEE;
		font-size: 14px;
		color: #343434;
	}

	.spec-item-active {
		padding: 0 32px;
		height: 36px;
		background: #FFF9F8;
		border-radius: 6px;
		border: 1px solid #FF3F26;
		font-size: 14px;
		color: #FF3F26;
	}
</style>
