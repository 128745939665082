// 接口域名和状态码的全局配置文件
export default {
	method: "GET", // 默认请求方式
	
	// 数据接口配置
	// request_host: 'https://village.togy.com.cn', // 接口域名
	request_host: 'https://admin.yixiangyipin.com', // 接口域名

	// 登录接口
	login_api: 'mapleLogin', // 普通登录API
	refresh_token_api: '/refreshToken', // 刷新token的API

	// 上传图片接口
	// uploadFile_host: 'https://village.togy.com.cn', // 上传文件接口域名
	uploadFile_host: 'https://admin.yixiangyipin.com', // 上传文件接口域名
	uploadFile_api: 'upload', // 上传文件API
	uploadFile_loading_txt: '上传图片中', // 上传图片默认loading显示的文字


	// 后台返回数据的状态码
	request_no_token_code: 4000, // 请求没有携带token的code值
	request_token_error: 4001, // 被踢下线
	request_token_overdue_code: 4002, // token过期的code值
	request_bing_phone: 2001, // 需要绑定手机号,小程序中会返回session_key
	request_token_unable_renew: 6001, // token无法续期；
	request_password_changed: 6002, // 密码已修改，请重新登录；
	request_user_deleted: 6003, // 用户已被删除/已注销；
};
