<template>
	<div class="content flex-column">
		<div class="paddlr40 paddt40 paddb24 bg-white flex-1 boderra10 flex-column">
			<div class="fz-20 font-bold paddb30">活动报名</div>
			<Ltitle class="marb24" :list="titleList" :titleId="cid" @change="titleChange"></Ltitle>
			<div class="tableheader fz-15">
				<el-row>
					<el-col :span="2">
						<div class="tableheaderone flex-row-center-center">封面图</div>
					</el-col>
					<el-col :span="4">
						<div class="tableheaderone flex-row-center-center">活动名称</div>
					</el-col>
					<el-col :span="4">
						<div class="tableheaderone flex-row-center-center">活动时间</div>
					</el-col>
					<el-col :span="2">
						<div class="tableheaderone flex-row-center-center">报名信息</div>
					</el-col>
					<el-col :span="2">
						<div class="tableheaderone flex-row-center-center">规格</div>
					</el-col>
					<el-col :span="2">
						<div class="tableheaderone flex-row-center-center">活动提供量</div>
					</el-col>
					<el-col :span="2">
						<div class="tableheaderone flex-row-center-center">原价</div>
					</el-col>
					<el-col :span="2">
						<div class="tableheaderone flex-row-center-center">活动价</div>
					</el-col>
					<el-col :span="2">
						<div class="tableheaderone flex-row-center-center">状态</div>
					</el-col>
					<el-col :span="2">
						<div class="tableheaderone flex-row-center-center">操作</div>
					</el-col>
				</el-row>
			</div>
			<div class="flex-1 ">
				<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
					<li v-for="(item, index) in list" :key="index">
						<div class="tableone boderb1-EEEEEE">
							<el-row>
								<el-col :span="2">
									<div class="flex-row-center-center paddtb18 table">
										<img class="cover" :src="item.good.cover" alt="" />
									</div>
								</el-col>
								<el-col :span="4">
									<div class="flex-row-center-center paddtb18 table fz-14 co-343434">
										{{item.cloud.title}}</div>
								</el-col>
								<el-col :span="4">
									<div class="flex-row-center-center paddtb18 table fz-14 co-343434">
										<span class="">{{item.cloud.start_time}}至{{item.cloud.end_time}}</span>
									</div>
								</el-col>
								<el-col :span="2">
									<div class="flex-row-center-center paddtb18 table fz-14 co-343434">{{item.name}}
									</div>
								</el-col>
								<el-col :span="2">
									<div class="flex-row-center-center table paddtb18 fz-14 co-343434">
										{{item.spec.title}}
									</div>
								</el-col>
								<el-col :span="2">
									<div class="flex-row-center-center table paddtb18 fz-14 pointer co-343434">
										<span>{{item.num}}</span>
									</div>
								</el-col>
								<el-col :span="2">
									<div class="flex-row-center-center table paddtb18 fz-14 co-343434">
										<span>￥{{item.spec.price}}</span>
									</div>
								</el-col>
								<el-col :span="2">
									<div class="flex-row-center-center table paddtb18 fz-14 font-blod co-FF3F26">
										<span>￥{{item.price}}</span>
									</div>
								</el-col>
								<el-col :span="2">
									<div class="flex-row-center-center table paddtb18 fz-14 co-343434">
										<span
											:class="item.status == 2 ? 'co-999999' : 'co-343434'">{{item.status_text}}</span>
									</div>
								</el-col>
								<el-col :span="2">
									<div class="flex-row-center-center table paddtb18 fz-14 ">
										<span v-if="item.status == 3">-</span>
										<span class="pointer" v-else @click="delItem(item)">刪除</span>
									</div>
								</el-col>
							</el-row>
						</div>
					</li>
				</ul>
			</div>
			<div class="flex-row-center-center mart28">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
					@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
					:total="total" :page-sizes="[10, 15, 20]">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				titleList: [{
						id: -1,
						title: "全部",
					},
					{
						id: 3,
						title: "待审核",
					},
					{
						id: 1,
						title: "已通过",
					},
					{
						id: 2,
						title: "未通过",
					}
				],
				cid: -1,
				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,
			};
		},
		watch: {
			$route: {
				handler: function(val) {
					if (val.query.cid) {
						this.cid = val.query.cid;
					}
				},
				// 深度观察监听
				deep: true,
				immediate: true,
			},
		},
		mounted() {
			this.onSubmit();
		},
		methods: {
			titleChange(v) {
				if (this.loading) return;
				this.cid = v.id;
				this.onSubmit();
			},
			// 删除
			delItem(v) {
				this.$confirm('此操作将永久删除报名信息, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.tokenRequest({
						method: 'DELETE',
						url: '/store/Cloud',
						isLoading: '加载中...',
						data: {
							id: v.id,
						},
						success: res => {
							this.$message.success('已删除!');
							this.onSubmit();
						},
						fail: data => {
							this.$message.error(data.message);
						},
					})
				})
			},
			// 加载更多
			load() {

			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/Cloud/Mylist',
					isLoading: '加载中...',
					data: {
						status: this.cid,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.tableheader {
			background: #f8f8f8;
			height: 48px;
			border-radius: 8px;
			box-sizing: border-box;

			.tableheaderone {
				color: #999999;
				line-height: 48px;
				box-sizing: border-box;
			}
		}

		.infinite-list {
			width: 100%;
			height: 100%;
			box-sizing: border-box;

			.tableone {
				width: 100%;
				box-sizing: border-box;

				.tableoneheader {
					background: #f8f8f8;
					border-radius: 8px 8px 0 0;
					padding: 12px 24px;

					.icon {
						width: 14px;
						height: 14px;
					}
				}

				.cover {
					width: 50px;
					height: 50px;
					border-radius: 8px;
				}
			}

			.table {
				box-sizing: border-box;
				height: 100px;
			}

			.changebtn {
				height: 27px;
				width: 68px;
				background: #FF3F26;
				border-radius: 6px;
				border-color: transparent;
				color: #ffffff;
				font-size: 13px;
				line-height: 27px;
				padding: 0;
			}
		}
	}

	.start-btn {
		width: 80px;
		height: 27px;
	}
</style>
