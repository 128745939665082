<template>
	<div>
		<div class="bg-white">
			<div class="font-blod fz-20 co-343434 paddt30 paddl40 paddb40 flex-row-center-start">
				<span>发布产品</span>
				<span class="op0">{{changeStatus}}</span>
			</div>
			<div class="paddl60">
				<div class="flex-row-center-start">
					<span class="fz-14 co-343434 w110">产品标题</span>
					<div class="input-area boderra6 boder1-EEEEEE flex-row-center-start">
						<input class="fullh100 boderra6 fz-14 co-343434 flex-1" v-model="params.title"
							placeholder="可输入产品的名称、特征、亮点" type="text">
					</div>
				</div>
				<div class="mart24 flex-row-center-start">
					<span class="fz-14 co-343434 w110">产品简介</span>
					<div class="input-area boderra6 boder1-EEEEEE">
						<input class="w100 fullh100 boderra6 paddl20 fz-14 co-343434" v-model="params.desc"
							placeholder="可用一句话介绍产品和商家保障" type="text">
					</div>
				</div>
				<div class="flex-row mart30">
					<span class="fz-14 co-343434 w110">产品封面图</span>
					<div class="">
						<div class="wh-96 relative pointer">
							<el-upload class="absolute all" action="https://admin.yixiangyipin.com/api/upload"
								:show-file-list="false" :on-success="coverSuccess" :before-upload="beforeAvatarUpload">
								<iconImg v-if="params.cover.length == 0" src='assets/icon_10.png' size='96 96'
									mode='fit'></iconImg>
								<img v-else :src="params.cover[0]" alt="" class="wh-96">
							</el-upload>
						</div>
						<div class="mart12 fz-12 co-999999">请上传图片，最多上传1张</div>
					</div>
				</div>
				<div class="flex-row mart30">
					<span class="fz-14 co-343434 w110">产品轮播图</span>
					<div class="">
						<div class="flex-row-wrap">
							<div class="wh-96 marr10 relative" v-for="(v, i) in params.image" :key="i">
								<img :src="v" alt="" class="wh-96">
								<div class="absolute top0 right0 pointer" @click="DelPicItem(1, i)" style="padding: 4px;">
									<iconImg src='assets/icon_15.png' size='20 20' mode='fit'></iconImg>
								</div>
							</div>
							<el-upload v-if="params.image.length < 5" action="https://admin.yixiangyipin.com/api/upload"
								:show-file-list="false" :on-success="bannerSuccess" :before-upload="beforeAvatarUpload">
								<iconImg src='assets/icon_10.png' size='96 96' mode='fit'></iconImg>
							</el-upload>
						</div>
						<div class="mart12 fz-12 co-999999">请上传图片，最多上传5张</div>
					</div>
				</div>
				<div class="flex-row mart30">
					<span class="fz-14 co-343434 w110">产品详情图</span>
					<div class="">
						<div class="flex-row-wrap">
							<div class="wh-96 marr10 relative" v-for="(v, i) in params.content_image" :key="i">
								<img :src="v" alt="" class="wh-96">
								<div class="absolute top0 right0 pointer" @click="DelPicItem(2, i)" style="padding: 4px;">
									<iconImg src='assets/icon_15.png' size='20 20' mode='fit'></iconImg>
								</div>
							</div>
							<el-upload v-if="params.content_image.length < 9"
								action="https://admin.yixiangyipin.com/api/upload" :show-file-list="false"
								:on-success="contentImageSuccess" :before-upload="beforeAvatarUpload">
								<iconImg src='assets/icon_10.png' size='96 96' mode='fit'></iconImg>
							</el-upload>
						</div>
						<div class="mart12 fz-12 co-999999">请上传图片，最多上传9张</div>
					</div>
				</div>
				<div class="flex-row-center-start mart30">
					<span class="fz-14 co-343434 w110">产品分类</span>
					<el-select class="select-01 select" v-model="oneId" placeholder="请选择分类" @change="oneChange">
						<el-option v-for="item in oneLevel" :key="item.name" :label="item.name" :value="item.id">
							<span style="float: left" class="fz-14">{{ item.name }}</span>
						</el-option>
					</el-select>
					<el-select class="select-01 marl16 select" v-model="twoId" placeholder="请选择分类" @change="twoChange">
						<el-option v-for="item in twoLevel" :key="item.name" :label="item.name" :value="item.id">
							<span style="float: left" class="fz-14">{{ item.name }}</span>
						</el-option>
					</el-select>
					<el-select class="select-01 marl16 select" v-model="params.cate_id" placeholder="请选择分类"
						@change="threeChange">
						<el-option v-for="item in threeLevel" :key="item.name" :label="item.name" :value="item.id">
							<span style="float: left" class="fz-14">{{ item.name }}</span>
						</el-option>
					</el-select>
				</div>
				<div class="flex-row-center-start mart24">
					<span class="fz-14 co-343434 w110">发货地点</span>
					<el-select class="select-01 select" v-model="params.delivery_province_id" placeholder="请选择省"
						@change="provinceChange">
						<el-option v-for="item in province" :key="item.name" :label="item.name" :value="item.id">
							<span style="float: left" class="fz-14">{{ item.name }}</span>
						</el-option>
					</el-select>
					<el-select class="select-01 marl16 select" v-model="params.delivery_city_id" placeholder="请选择市"
						@change="cityChange">
						<el-option v-for="item in city" :key="item.name" :label="item.name" :value="item.id">
							<span style="float: left" class="fz-14">{{ item.name }}</span>
						</el-option>
					</el-select>
					<el-select class="select-01 marl16 select" v-model="params.delivery_area_id" placeholder="请选择区"
						@change="areaChange">
						<el-option v-for="item in area" :key="item.name" :label="item.name" :value="item.id">
							<span style="float: left" class="fz-14">{{ item.name }}</span>
						</el-option>
					</el-select>
				</div>
				<div class="paddt31">
					<div class="flex-row-center-sb paddb20 spec-area">
						<div class="fz-14 co-343434">产品规格</div>
						<div class="flex-row-center-start">
							<iconImg src='assets/icon_11.png' size='12 12' mode='fit'></iconImg>
							<span class="fz-14 co-FF3F26 marl6 pointer" @click="addSpec">添加规格</span>
						</div>
					</div>
					<div class="bg-F9F9F9 spec-item boderra10 paddt24 paddb8">
						<div class="paddlr30 flex-row-center-sb marb16" v-for="(v, i) in params.spec" :key="i">
							<div class="flex-row-center-start">
								<span class="fz-14 co-343434">规格名称</span>
								<div class="select-01 boderra6 boder1-EEEEEE marl16 flex-row-center-start">
									<input class="fullh100 boderra6 fz-14 co-343434 flex-1" v-model="v.title"
										placeholder="例:精选大果" type="text">
								</div>
							</div>
							<div class="flex-row-center-start">
								<span class="fz-14 co-343434">规格单位</span>
								<div class="select-01 boderra6 boder1-EEEEEE marl16 flex-row-center-start">
									<input class="fullh100 boderra6 fz-14 co-343434 flex-1" v-model="v.unit"
										placeholder="例:斤" type="text">
								</div>
							</div>
							<div class="flex-row-center-start">
								<span class="fz-14 co-343434">起批量</span>
								<div class="select-01 boderra6 boder1-EEEEEE marl16 flex-row-center-start">
									<input class="fullh100 boderra6 fz-14 co-343434 flex-1" v-model="v.batch"
										placeholder="请填写最小购买数" type="text">
								</div>
							</div>
							<div class="flex-row-center-start">
								<span class="fz-14 co-343434">单价</span>
								<div class="select-01 boderra6 boder1-EEEEEE marl16 flex-row-center-start">
									<input class="fullh100 boderra6 fz-14 co-343434 flex-1" v-model="v.price"
										placeholder="请填写金额" type="text">
								</div>
								<div class="flex-row-center-center marl16" @click="delItem(i)">
									<iconImg src='assets/icon_13.png' size='16 16' mode='fit'></iconImg>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-row-center-start mart24 fz-14 co-343434">
					<span class="w110">产品推荐</span>
					<div class="flex-row-center-start pointer marr32" v-for="(v, i) in r_list" :key="i"
						@click="chooseType(v)">
						<iconImg :src="`assets/${params.tag_id == v.id ? 'icon_14' : 'icon_144'}.png`" size='14 14'
							mode='fit'>
						</iconImg>
						<span class="marl12">{{v.name}}</span>
					</div>
				</div>
				<div class="flex-row-center-center mart100 h72">
					<el-button class="button" @click="save">保存</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var spec_item = {
		title: "",
		unit: '',
		batch: '',
		price: ''
	};

	export default {
		data() {
			return {
				id: "",
				params: {
					title: "",
					desc: "",
					cover: [],
					image: [],
					content_image: [],
					cate_id: "",
					tag_id: "",
					delivery_province_id: "",
					delivery_city_id: "",
					delivery_area_id: "",
					spec: [],
				},
				oneLevel: [],
				twoLevel: [],
				threeLevel: [],
				oneId: "",
				twoId: "",
				cate: "",
				province: [],
				city: [],
				area: [],
				r_list: [], // 推荐类型
				changeStatus: false,
			}
		},
		mounted() {
			this.getTag();
			this.getClassi();
			this.addSpec();
		},
		methods: {
			DelPicItem(type, index) {
				if (type == 1) {
					this.params.image.splice(index, 1);
				} else {
					this.params.content_image.splice(index, 1);
				}
			},
			oneChange(e) {
				let item = this.oneLevel.find(v => v.id == e);
				this.twoId = "";
				this.twoLevel = item.children;
				this.threeLevel = [];
				this.params.cate_id = "";
			},
			twoChange(e) {
				let item = this.twoLevel.find(v => v.id == e);
				this.threeLevel = item.children;
				this.params.cate_id = "";
			},
			threeChange(e) {
				let item = this.threeLevel.find(v => v.id == e);
				this.params.cate_id = item.id;
			},
			// 省选中
			provinceChange(e) {
				let item = this.province.find(v => v.id == e);
				this.params.delivery_province_id = item.id;
				this.city = item.children;
			},
			// 市选中
			cityChange(e) {
				let item = this.city.find(v => v.id == e);
				this.params.delivery_city_id = item.id;
				this.area = item.children;
			},
			// 区选中
			areaChange(e) {
				let item = this.area.find(v => v.id == e);
				this.params.delivery_area_id = item.id;
			},
			// 添加规格
			addSpec() {
				let spec_item = {
					title: "",
					unit: '',
					batch: '',
					price: ''
				};
				this.params.spec.push(spec_item);
			},
			// 删除规格
			delItem(i) {
				if (this.params.spec.length == 1) {
					this.$message({
						message: '至少保留一种规格',
						type: 'warning'
					});
					return false;
				}
				this.$confirm('此操作将永久删除该规格, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.params.spec.splice(i, 1);
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				})
			},
			// 保存
			save() {
				let data = JSON.stringify(this.params);
				let params = JSON.parse(data);
				params.cover = params.cover.join(",");
				if (!params.title) {
					this.$message.error('请请输入产品标题!');
					return false;
				}
				if (!params.desc) {
					this.$message.error('请输入产品简介!');
					return false;
				}
				if (!params.cover) {
					this.$message.error('请上传产品封面图!');
					return false;
				}
				if (params.image.length == 0) {
					this.$message.error('请上传产品轮播图!');
					return false;
				}
				if (params.content_image.length == 0) {
					this.$message.error('请上传产品详情图!');
					return false;
				}
				if (!params.cate_id) {
					this.$message.error('请选择产品分类!');
					return false;
				}
				if (!params.delivery_province_id || !params.delivery_city_id || !params.delivery_area_id) {
					this.$message.error('请完善发货地址信息!');
					return false;
				}
				let isnull = false;
				params.spec.forEach(v => {
					if (!v.title || !v.unit || !v.batch || !v.price) {
						isnull = true;
					}
				})
				if (isnull) {
					this.$message.error('请完善产品规格信息!');
					return false;
				}
				if (this.id) {
					params.id = this.id;
				}
				this.$api.tokenRequest({
					method: 'post',
					url: '/store/good',
					isLoading: '加载中...',
					data: params,
					success: res => {
						this.$message.success("已提交审核!");
						let a = setTimeout(() => {
							clearTimeout(a);
							this.$router.push({
								path: '/product/product',
								query: {
									status: 4,
								}
							})
						}, 1500);
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 获取推荐类型
			getTag() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/api/common/tag',
					isLoading: '加载中...',
					data: {
						type: 1
					},
					success: res => {
						this.r_list = res.data;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 获取分类
			getClassi() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/api/common/cate',
					isLoading: '加载中...',
					success: res => {
						this.oneLevel = res.data;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})

				this.$api.tokenRequest({
					method: 'GET',
					url: '/api/maple/region',
					isLoading: '加载中...',
					data: {
						type: 1
					},
					success: res => {
						this.province = res.data;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 选择推荐类型
			chooseType(v) {
				if (!this.params.tag_id) {
					this.params.tag_id = v.id;
				} else {
					if (this.params.tag_id == v.id) {
						this.params.tag_id = "";
					} else {
						this.params.tag_id = v.id;
					}
				}
			},
			// 封面图上传
			coverSuccess(res) {
				this.changeStatus = !this.changeStatus;
				this.params.cover[0] = res.data;
			},
			// 轮播图上传
			bannerSuccess(res) {
				this.params.image = this.params.image.concat([res.data]);
			},
			// 详情图片上传
			contentImageSuccess(res) {
				this.params.content_image = this.params.content_image.concat([res.data]);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
		}
	}
</script>

<style scoped>
	.input-area {
		width: 600px;
		height: 44px;
	}

	.select {
		width: 320px;
		height: 44px;
	}

	/deep/.select .el-input {
		height: 44px;
	}

	/deep/.select .el-input__inner {
		height: 44px;
	}

	/deep/.select .el-select__caret {
		transform: rotate(0deg);
	}

	/deep/ .el-upload--picture-card,
	/deep/ .el-upload-list__item-actions,
	/deep/ .el-upload-list--picture-card .el-upload-list__item {
		width: 96px;
		height: 96px;
		border-color: transparent;
	}

	/deep/.select .is-reverse {
		transform: rotate(180deg) !important;
	}

	/deep/.select .el-input__inner:focus {
		border-color: #FF3F26;
	}

	/deep/.select .el-icon-arrow-up::before {
		width: 8px;
		height: 8px !important;
		content: "";
		background: url(../../assets/icon_12.png) no-repeat;
		background-size: 8px 8px;
		background-position: 0 2px;
		padding: 0 0 0 9.5px;
		box-sizing: border-box;
		font-size: 12px;
	}

	.select-01 {
		width: 160px;
		height: 44px;
		border-radius: 6px;
	}

	.spec-area {
		width: 1104px;
	}

	.spec-item {
		width: 1100px;
		border: 2px dashed #EEEEEE;
	}

	.button {
		width: 128px;
		height: 40px;
		background: #FF3F26;
		border: 0;
		color: #ffffff;
		border-color: transparent;
	}

	input {
		outline: none;
		padding: 0;
		margin: 0;
		border: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	::-webkit-input-placeholder {
		/* WebKit browsers，webkit内核浏览器 */
		color: #999999;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #999999;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #999999;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #999999;
	}
</style>
