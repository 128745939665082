import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
	routes: [
		//配置路由的路径
		{
			path: '/',
			component: () => import('../views/login/login.vue'),
		}, {
			path: '/layout',
			component: () => import('../views/layout/layout.vue'),
			children: [{
					path: '/index/index',
					component: () => import('../views/index/index.vue')
				},
				{
					path: '/product/product',
					component: () => import('../views/product/product.vue')
				},
				{
					path: '/product/release',
					component: () => import('../views/product/release.vue')
				},
				{
					path: '/ad/ad',
					component: () => import('../views/ad/ad.vue')
				},
				{
					path: '/ad/release',
					component: () => import('../views/ad/release.vue')
				},
				{
					path: '/customer/index',
					component: () => import('../views/customer/index.vue'),
				},
				{
					path: '/purchase/hall',
					component: () => import('../views/purchase/hall.vue'),
				},
				{
					path: '/broadcast/index',
					component: () => import('../views/broadcast/index.vue'),
				},
				{
					path: '/broadcast/detail',
					component: () => import('../views/broadcast/detail.vue'),
				},
				{
					path: '/merchant/index',
					component: () => import('../views/merchant/index.vue'),
				},
				{
					path: '/digital/index',
					component: () => import('../views/digitalInfras/index.vue'),
				},
				{
					path: '/blind/index',
					component: () => import('../views/blind/index.vue'),
				},
				{
					path: '/message/index',
					component: () => import('../views/message/index.vue'),
				},
				{
					path: '/mine/digitalInfrastructure',
					component: () => import('../views/mine/digitalInfrastructure.vue'),
				},
				{
					path: '/order/index',
					component: () => import('../views/order/index.vue'),
				},
				{
					path: '/order/index',
					component: () => import('../views/order/index.vue'),
				},
				{
					path: '/order/detail',
					component: () => import('../views/order/detail.vue'),
				},
				{
					path: '/order/refundDetail',
					component: () => import('../views/order/refundDetail.vue'),
				},
				{
					path: '/mine/invoice',
					component: () => import('../views/mine/invoice.vue'),
				},
				{
					path: '/mine/activity',
					component: () => import('../views/mine/activity.vue'),
				},
				{
					path: '/mine/capital',
					component: () => import('../views/mine/capital.vue'),
				},
				{
					path: '/mine/myLive',
					component: () => import('../views/mine/myLive.vue'),
				},
				{
					path: '/mine/digitalInfrastructure',
					component: () => import('../views/mine/digitalInfrastructure.vue'),
				},
				{
					path: '/mine/privacy',
					component: () => import('../views/mine/privacy.vue'),
				},
				{
					path: '/mine/evaluation',
					component: () => import('../views/mine/evaluation.vue'),
				},
				{
					path: '/mine/rule',
					component: () => import('../views/mine/rule.vue'),
				},
				{
					path: '/mine/feedback',
					component: () => import('../views/mine/feedback.vue'),
				},
				{
					path: '/mine/storeSet',
					component: () => import('../views/mine/storeSet.vue'),
				}
			]
		}
	]
})
export default router
