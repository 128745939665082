<template>
	<div>
		<div class="bg_1 boderra10 flex-row-center-start paddl40">
			<iconImg src="assets/logo.png" size="80 80" radius="40" mode="fit"></iconImg>
			<div class="marl24">
				<div class="fz-22 color-white font-blod">{{details.store_name}}</div>
				<div class="flex-row-center-center label-01 mart12 pointer" @click="detailopen = true">
					<span class="fz-16 color-white font-blod marr8">战略合作</span>
					<iconImg src="assets/icon_1.png" size="10 10" mode="fit"></iconImg>
				</div>
			</div>
		</div>
		<div class="mart20 flex-row-center-sb">
			<div class="bg-white boderra10 flex-row-center-sb item-01 flex-1 paddl40 pointer"
				:class="i < list.length - 1 ? 'marr24' : ''" v-for="(v, i) in list" :key="i" @click="toPage(v)">
				<div class="item-01-left">
					<div class="fz-16 co-343434">{{ v.title }}</div>
					<div class="co-343434 font-blod mart10 number">{{ details[v.key] }}</div>
				</div>
				<div class="item-01-rght">
					<img class="item-icon" :src="require(`@/${v.icon}`)" alt="" />
				</div>
			</div>
		</div>
		<div class="mart20 bg-white boderra10 paddtb24 paddlr32">
			<div id="charts" class="charts"></div>
		</div>
		<div class="mart20 paddlr32 paddt24 boderra10 bg-white">
			<div class="fz-20 co-343434 font-blod paddb28">消息通知</div>
			<div class="flex-row-wrap flex-ju-sb">
				<div class="item-02 flex-row-center-sb marb24 pointer" v-for="(v, i) in notice" :key="i"
					@click="toDetail">
					<span class="item-02-title fz-14 co-343434 one-row">{{v.title}}</span>
					<iconImg src="assets/icon_9.png" class="marl30" size="10 10" mode="fit"></iconImg>
				</div>
			</div>
		</div>
		<el-drawer class="side-pop" :visible.sync="detailopen" direction="rtl" :show-close="false"
			:destroy-on-close="true" size="1200px">
			<div class="fz-20 co-343434 paddl50 paddt30 paddb36 relative">
				<span class="font-blod">战略合作</span>
				<div class="flex-row-center-center absolute top0 right0 paddr40 paddt37 pointer"
					@click="detailopen = false">
					<iconImg src="assets/icon_16.png" class="marl30" size="12 12" mode="fit"></iconImg>
				</div>
				<div class="mart36" v-html="cooperation_desc"></div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	var option;
	export default {
		data() {
			return {
				list: [{
						icon: "assets/icon_2.png",
						title: "总访客数",
						key: "all_visitor",
						url: "/customer/index",
					},
					{
						icon: "assets/icon_3.png",
						title: "总咨询数",
						key: "all_consultant",
						url: "/message/index",
					},
					{
						icon: "assets/icon_4.png",
						title: "总订单数",
						key: "all_orders",
						url: "/order/index",
					},
					{
						icon: "assets/icon_5.png",
						title: "累计交易额",
						key: "all_turnover",
						url: "/mine/capital",
					}
				],
				details: "",
				notice: [],
				cooperation_desc: "",
				detailopen: false,
			};
		},
		created() {},
		mounted() {
			let userinfo = localStorage.getItem("userinfo");
			if (userinfo) {
				this.details = JSON.parse(userinfo);
			}
			this.getData();
		},
		methods: {
			toPage(v) {
				this.$router.push({
					path: v.url,
				})
			},
			toDetail() {
				// 跳转消息通知详情
				this.$router.push({
					path: '/message/index',
				})
			},
			// 获取信息
			getData() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/notice/index',
					isLoading: '加载中...',
					data: {
						keyword: "",
						page: 1,
						size: 8,
					},
					success: res => {
						this.notice = res.data;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				});
				// 获取图表
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/user/orderTable',
					isLoading: '加载中...',
					success: res => {
						var chartDom = document.getElementById("charts");
						var myChart = echarts.init(chartDom);
						option = {
							title: {
								text: "一周交易额",
							},
							tooltip: {
								trigger: "axis",
								axisPointer: {
									type: "cross",
									label: {
										backgroundColor: "#ff3f26",
									},
								},
							},
							grid: {
								left: "3%",
								right: "4%",
								bottom: "3%",
								containLabel: true,
							},
							xAxis: [{
								type: "category",
								boundaryGap: false,
								data: res.data.date,
							}, ],
							yAxis: [{
								type: "value",
							}, ],
							series: [{
								name: "一周交易额",
								type: "line",
								stack: "Total",
								color: "#FF3F26",
								areaStyle: {
									opacity: 0.8,
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
											offset: 0,
											color: "#fff4f3",
										},
										{
											offset: 1,
											color: "#ffffff",
										},
									]),
								},
								emphasis: {
									focus: "series",
								},
								data: res.data.money_data,
							}, ],
						};
						option && myChart.setOption(option);
					},
					fail: data => {
						this.$message.error(data.message);
					},
				});

				this.$api.tokenRequest({
					method: 'GET',
					url: '/api/common/options',
					isLoading: '加载中...',
					success: res => {
						this.cooperation_desc = res.data.cooperation_desc;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				});
			}
		},
	};
</script>

<style lang="less" scoped>
	.bg_1 {
		height: 137px;
		background: url(../../assets/bg_1.png) no-repeat center;
		background-size: 100% 100%;
	}

	.label-01 {
		width: 118px;
		height: 33px;
		background: rgba(255, 255, 255, 0.15);
		border-radius: 25px;
	}

	.item-02 {
		width: 45%;
	}

	.charts {
		height: 390px;
	}

	@media (max-width: 1400px) {
		.item-01 {
			height: 85px;
		}

		.item-01-left {
			padding-left: 23px;
		}

		.number {
			font-size: 21px;
		}

		.item-icon {
			width: 44px;
			height: 44px;
		}

		.item-01-rght {
			padding-right: 23px;
		}
	}

	@media (min-width: 1401px) {
		.item-01 {
			height: 136px;
		}

		.item-01-left {
			padding-left: 40rpx;
		}

		.number {
			font-size: 34px;
		}

		.item-icon {
			width: 70px;
			height: 70px;
		}

		.item-01-rght {
			padding-right: 40px;
		}
	}

	/deep/.side-pop .el-drawer__header {
		margin: 0;
		padding: 0;
	}
</style>
