<template>
	<el-drawer ref="drawerPop" class="side-pop" :visible.sync="open" direction="rtl" :show-close="false"
		:destroy-on-close="true" size="1100px">
		<div class="fz-20 co-343434 paddl50 paddb36 relative">
			<span class="marb40 font-blod">查看详情</span>
			<div class="flex-row-center-center absolute top0 right0 paddr40 paddt37 pointer" @click="closePop">
				<iconImg src="assets/icon_16.png" class="marl30" size="12 12" mode="fit"></iconImg>
			</div>
			<el-row>
				<el-col :span="9">
					<img class="big-cover marb10" :src="details.cover" alt="" />
					<div class="flex-row-center-start">
						<img :src="e" v-for="(e,i) in details.image" :key="i" class="small-cover" alt="" />
					</div>
				</el-col>
				<el-col :span="15" class="paddr60">
					<div class="fz-20 font-bold marb12">{{details.title}}</div>
					<div class="color-grey fz-14">{{details.desc}}</div>
					<div class="price font-bold">
						<span class="unit">￥</span>
						<span>{{details.price}}</span>
					</div>
					<div class="flex-row-center-start fz-14 mart22">
						<span class="label">发货</span>
						<span>{{details.delivery_addr}}</span>
					</div>
					<div class="flex-row fz-14 mart22">
						<span class="label">规格</span>
						<div class="flex-1">
							<div class="spec flex-row-center-sb marb12" v-for="(e,i) in details.spec" :key="i">
								<span>{{e.title}}</span>
								<span>{{e.batch}}{{e.unit}}起：{{e.price}}/{{e.unit}}</span>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<span class="fz-16 font-bold mart40">商品详情</span>
			<div class="paddr60">
				<el-divider />
			</div>
			<div class="flex-column">
				<img class="detail" v-for="(e, i) in details.content_image" :src="e" alt="" />
			</div>
		</div>
	</el-drawer>
</template>
<script>
	export default {
		data() {
			return {
				open: false, // 详情弹窗
				details: "", // 详情
			};
		},
		methods: {
			show(id) {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/good/detail',
					isLoading: '加载中...',
					data: {
						good_id: id,
					},
					success: res => {
						this.details = res.data;
						this.open = true;
					},
					fail: data => {
						this.message.error(data.message);
					},
				})
			},
			closePop() {
				this.open = false;
			},
			
		},
	};
</script>
<style scoped lang="less">
	.big-cover {
		width: 360px;
		height: 360px;
		border-radius: 12px;
	}

	.small-cover {
		width: 64px;
		height: 64px;
		border-radius: 6px;
		margin-right: 10px;
	}

	.price {
		display: flex;
		align-items: center;
		justify-content: start;
		padding: 8px 12px;
		background: #fff7f6;
		border-radius: 8px;
		color: #FF3F26;
		font-size: 26px;
		margin-top: 16px;
	}

	.unit {
		font-size: 16px;
		margin-top: 6px;
	}

	.label {
		width: 68px;
		color: #999999;
	}

	.spec {
		padding: 12px 24px;
		border: 1px solid #EEEEEE;
		border-radius: 6px;
		font-size: 14px;
	}

	/deep/ .el-divider--horizontal {
		margin-top: 13px;
	}

	.detail {
		width: 990px;
	}
</style>
