<template>
	<div class="content">
		<div class="bg-white paddlr40 paddt30 content">
			<div class="fz-20 co-343434 font-blod">我的产品</div>
			<Ltitle class="paddt40" :list="titleList" :titleId="cid" @change="titleChange"></Ltitle>
			<Litem class="mart28" :list="itemList"></Litem>
			<div class="flex-1">
				<ul class="infinite-list" style="overflow: auto">
					<li class="infinite-list-item" v-for="(v, i) in list" :key="i">
						<goodsItem :render="itemList" :detail="v" :status="cid" ptype="1"
							@clickItem="clickItem($event, v, i)">
						</goodsItem>
					</li>
				</ul>
			</div>
			<div class="flex-row-center-center paddb44">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
					@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
					:total="total" :page-sizes="[10, 15, 20]">
				</el-pagination>
			</div>
		</div>

		<editPopup ref="editRef" @refreshList="onSubmit" />
		<detailPopup ref="detailRef" />
	</div>
</template>

<script>
	import editPopup from "./components/editPopup.vue";
	import detailPopup from "./components/detailPopup.vue";
	export default {
		components: {
			editPopup,
			detailPopup,
		},
		data() {
			return {
				titleList: [{
					id: 1,
					title: "上架中",
				},
				{
					id: 2,
					title: "已下架",
				},
				{
					id: 3,
					title: "被下架",
				},
				{
					id: 4,
					title: "审核中",
				}],
				cid: 1,
				itemList: [{
						title: "产品封面图",
						flex: 1,
						key: "cover",
						type: 2,
					},
					{
						title: "标题",
						flex: 1.5,
						key: "title",
						type: 5,
					},
					{
						title: "简介",
						flex: 1.5,
						key: "desc",
						type: 4,
					},
					{
						title: "单价",
						flex: 1,
						key: "price",
						bold: true,
						type: 1,
					},
					{
						title: "销量",
						flex: 1,
						key: "sale",
						type: 1,
					},
					{
						title: "操作",
						flex: 1.5,
						key: "params6",
						type: 3,
					}
				],
				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,
			};
		},
		watch: {
			$route: {
				handler: function(val) {
					if (val.query.status) {
						this.cid = val.query.status
					} else {
						this.cid = 1
					}
				},
				// 深度观察监听
				deep: true,
				immediate: true,
			},
		},
		mounted() {
			this.onSubmit();
		},
		methods: {
			// 改变状态
			titleChange(v) {
				if (this.loading) {
					return false;
				}
				this.cid = v.id;
				this.onSubmit();
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
				
			},
			// 点击页码
			handleCurrentChange(e) {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = e;
				this.getList();
			},
			// 商品操作
			clickItem(e, v, i) {
				if (e == 1) {
					this.$confirm("是否确认下架此商品", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(() => {
						this.$api.tokenRequest({
							method: 'PUT',
							url: '/store/good/status',
							isLoading: '加载中...',
							data: {
								good_id: v.id,
								status: 0
							},
							success: res => {
								this.$message.success("下架成功!");
								this.onSubmit();
							},
							fail: data => {
								this.$message.error(data.message);
							},
						})
					});
				} else if (e == 2) {
					this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(() => {
						this.$api.tokenRequest({
							method: 'delete',
							url: '/store/good',
							isLoading: '加载中...',
							data: {
								good_id: v.id,
							},
							success: res => {
								this.$message.success("删除成功!");
								this.onSubmit();
							},
							fail: data => {
								this.$message.error(data.message);
							},
						});
					});
				} else if (e == 3) {
					// 编辑
					this.$refs.editRef.show(v.id);
				} else if (e == 4) {
					// 查看
					this.$refs.detailRef.show(v.id);
				} else if (e == 6) {
					// 上架
					this.$confirm("是否确认上架此商品", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(() => {
						this.$api.tokenRequest({
							method: 'PUT',
							url: '/store/good/status',
							isLoading: '加载中...',
							data: {
								good_id: v.id,
								status: 1
							},
							success: res => {
								this.$message.success("上架成功!");
								this.onSubmit();
							},
							fail: data => {
								this.$message.error(data.message);
							},
						})
					});
				}
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/good',
					isLoading: '加载中...',
					data: {
						status: this.cid,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
		},
	};
</script>

<style scoped lang="less">
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
	}

	.infinite-list {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}

	/deep/.paging .btn-prev {
		width: 30px;
		height: 30px;
		background: #ffffff;
		border: 1px solid #eeeeee;
		border-radius: 4px;
	}

	/deep/.paging .btn-next {
		width: 30px;
		height: 30px;
		background: #ffffff;
		border: 1px solid #eeeeee;
		border-radius: 4px;
	}

	/deep/.paging .number {
		width: 30px;
		height: 30px;
		background: #ffffff !important;
		border: 1px solid #eeeeee;
		border-radius: 4px;
		font-size: 13px;
		color: #343434;
		font-weight: normal;
	}

	/deep/.paging .active {
		width: 30px;
		height: 30px;
		background: #ffffff !important;
		border: 1px solid #ff3f26 !important;
		color: #ff3f26 !important;
		border-radius: 4px;
		font-size: 13px;
		font-weight: normal;
	}

	/deep/.paging .el-pager li {
		width: 30px;
		height: 30px;
		border-radius: 4px;
		border: 1px solid #eeeeee;
		background: #ffffff !important;
	}

	/deep/.paging .el-pager li:hover {
		color: #ff3f26 !important;
	}

	/deep/.paging .el-pagination__sizes {
		line-height: 30px;
	}

	/deep/.paging .el-pagination__sizes .el-input {
		width: 94px;
		height: 30px;
		margin: 0;
		margin-left: 8px;
	}
</style>
