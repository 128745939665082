<template>
	<div>
		<el-drawer title="添加盲盒" size="1000px" :visible.sync="drawer" :before-close="handleClose">
			<div class="mart26 paddlr50">
				<div>
					<el-form label-position="left" label-width="100px" class="mart40 paddlr50">
						<div class="fz-14 font-bold marb22">请选择已上架商品进行盲盒添加</div>
						<div class="flex-row flex-wrap infinite-list good_area" v-infinite-scroll="load"
							style="overflow: auto">
							<div class="shop marr20 marb20 pos-r" v-for="(item, index) in shoplist" :key="index">
								<img class="cover" :src="item.cover" alt="" />
								<div class="paddlr12 paddtb6">
									<div class="fz-15">{{item.title}}</div>
									<div class="flex-row-center-start mart6 color-red font-bold">
										<span class="fz-12">￥</span>
										<span class="fz-18">{{item.price}}</span>
									</div>
								</div>
								<div class="chooseicon pointer flex-row-center-center" @click="chooseShop(index,item)">
									<img :src="shopIndex == index ? require('@/assets/icon_14.png') : require('@/assets/icon_48@2x.png')"
										alt="" />
								</div>
							</div>
						</div>
						<div class="fz-14 font-bold marb22">设置价格优惠情况</div>
						<el-form-item label="原价" prop="price">
							<el-col :span="12">
								<el-input v-model="price" :disabled="true" placeholder="请输入原价"></el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="优惠价" prop="price">
							<el-col :span="12">
								<el-input v-model="form.price" placeholder="请设置优惠后金额"></el-input>
							</el-col>
						</el-form-item>
						<el-form-item label="数量" prop="number">
							<el-input v-model="form.number" placeholder="请设置数量"></el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="h100"></div>
			<div class="bottom paddr40">
				<el-divider></el-divider>
				<div class="flex-row-center-center marb16">
					<el-button type="primary" class="submit" @click="onSubmit">确认</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				drawer: false,
				form: {
					good_id: "",
					spec_id: "",
					price: "",
					number: '',
				},
				price: "",
				
				loading: false,
				page: 1,
				shopIndex: -1,
				shoplist: [],
				status: 1,
			};
		},
		mounted() {
			this.getList();
		},
		methods: {
			show() {
				this.drawer = true;
			},
			handleClose() {
				this.drawer = false;
			},
			onSubmit() {
				if (!this.form.good_id) {
					this.$message.error("请选择优惠商品");
					return false;
				}
				if (!this.form.price) {
					this.$message.error("请输入优惠价格");
					return false;
				}
				if (Number(this.form.price) > Number(this.price)) {
					this.$message.error("优惠价格不能大于原价格");
					return false;
				}
				if (!this.form.number) {
					this.$message.error("请输入优惠数量");
					return false;
				}
				if (this.loading) return
				this.loading = true;
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/box',
					isLoading: '加载中...',
					data: this.form,
					success: res => {
						this.loading = false;
						this.$message.success("添加成功");
						this.$emit("change", {})
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
			chooseShop(index, item) {
				this.shopIndex = index;
				this.price = item.price;
				this.form.good_id = item.good_id;
				this.form.spec_id = item.spec_id;
			},
			// 加载
			load() {
				this.getList();
			},
			// 获取商品列表
			getList() {
				if (this.loading) return
				if (this.status == 3) return
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/good',
					isLoading: '加载中...',
					data: {
						status: 1,
						page: this.page,
						size: 20,
					},
					success: res => {
						this.loading = false;
						let arr = [];
						res.data.data.forEach(item => {
							item.spec.forEach(vv => {
								let obj = {
									cover: item.cover,
									desc: item.desc,
									good_id: item.id,
									title: item.title,
									spec_id: vv.id,
									spec_name: vv.title,
									price: vv.price,
								}
								arr.push(obj);
							})
						})
						this.shoplist = this.shoplist.concat(arr);
						if (res.data.data.length > 0) {
							this.page = this.page + 1;
						}
						if (res.data.data.length == 0) {
							this.status = 3;
						}
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			}
		},
	};
</script>
<style lang="less" scoped>
	/deep/ .el-scrollbar {
		width: 100%;
		height: 500px;
	}

	.shop {
		width: 160px;
		border: 1px solid #eeeeee;
		border-radius: 8px;
		flex-shrink: 0;

		.cover {
			width: 160px;
			height: 160px;
			border-radius: 8px 8px 0 0;
		}

		.chooseicon {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 40px;

			img {
				width: 20px;
				height: 20px;
			}
		}
	}

	/deep/ .el-form-item__content {
		line-height: inherit;
	}

	/deep/ .el-drawer__header {
		color: #343434;
		font-weight: bold;
	}

	/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		opacity: 0;
		margin-right: 0;
	}

	.html {
		width: 900px;
		height: 160px;
		border-radius: 10px;
	}

	.bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		.submit {
			width: 128px;
			height: 40px;
			background: #ff3f26;
			border-radius: 6px;
			border: 1px solid #ff3f26;
		}
	}

	.good_area {
		height: 560px;
	}
</style>
