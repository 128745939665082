<template>
	<div class="content flex-column">
		<div class="paddlr40 paddt40 paddb24 bg-white flex-1 boderra10 flex-column">
			<div class="fz-20 font-bold paddb40">隐私管理</div>
			<div class="tableheader fz-15">
				<el-row>
					<el-col :span="9">
						<div class="tableheaderone flex-row-center-center">商品标题</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">规格说明</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">数量</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">运杂费</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">金额</div>
					</el-col>
					<el-col :span="3">
						<div class="tableheaderone flex-row-center-center">操作</div>
					</el-col>
				</el-row>
			</div>
			<div class="flex-1 mart16">
				<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
					<li v-for="(item, index) in list" :key="index" class="marb16">
						<div class="tableone">
							<div class="tableoneheader flex-row-center-start color-grey">
								<div class="fz-12">订单编号：{{item.order_sn}}</div>
								<div class="flex-row-center-start marl48">
									<img class="icon" src="@/assets/icon_53@2x.png" alt="" />
									<div class="fz-12 marl8">买家：{{item.address_info.name}}</div>
								</div>
							</div>
							<el-row>
								<el-col :span="9">
									<div class="flex-row paddtb20 paddlr24 table">
										<img class="cover" :src="item.goods.cover" alt="" />
										<div class="marl10 fz-14">{{item.goods.title}}</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										<div>
											<div v-for="(vv, ii) in item.spec" :key="ii">{{vv.spec_name}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										<div>
											<div v-for="(vv, ii) in item.spec" :key="ii">x{{vv.number}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
										￥{{item.express_price}}
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center font-bold paddtb20 paddlr24 table fz-14">
										￥{{item.price}}
									</div>
								</el-col>
								<el-col :span="3">
									<div class="flex-row-center-center table paddtb20 paddlr24 fz-14 co-1890FF pointer"
										@click="toDetail(item)">
										取消隐藏
									</div>
								</el-col>
							</el-row>
						</div>
					</li>
				</ul>
			</div>
			<div class="flex-row-center-center mart28">
				<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
					@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
					:total="total" :page-sizes="[10, 15, 20]">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,
			};
		},
		mounted() {
			this.getList();
		},
		methods: {
			// 加载更多
			load() {

			},
			// 查看评价
			toDetail(item) {
				this.$confirm('此操作将取消该订单隐藏, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.tokenRequest({
						method: 'POST',
						url: '/store/order/hide',
						isLoading: '加载中...',
						data: {
							order_id: item.id,
						},
						success: res => {
							this.$message.success("已取消!");
							this.onSubmit();
						},
						fail: data => {
							this.$message.error(data.message);
						},
					})
				})
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/hide',
					isLoading: '加载中...',
					data: {
						type: 1,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.$message.error(data.message);
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.tableheader {
			background: #f8f8f8;
			height: 48px;
			border-radius: 8px;
			box-sizing: border-box;

			.tableheaderone {
				color: #999999;
				line-height: 48px;
				box-sizing: border-box;
			}
		}

		.infinite-list {
			width: 100%;
			height: 100%;
			box-sizing: border-box;

			.tableone {
				width: 100%;
				border: 1px solid #EEEEEE;
				border-radius: 8px;
				box-sizing: border-box;

				.tableoneheader {
					background: #f8f8f8;
					border-radius: 8px 8px 0 0;
					padding: 12px 24px;

					.icon {
						width: 14px;
						height: 14px;
					}
				}

				.cover {
					width: 60px;
					height: 60px;
					border-radius: 8px;
				}
			}

			.table {
				border-right: 1px solid #EEEEEE;
				box-sizing: border-box;
				height: 100px;
				box-sizing: border-box;
			}

			.changebtn {
				height: 27px;
				width: 68px;
				background: #FF3F26;
				border-radius: 6px;
				border-color: transparent;
				color: #ffffff;
				font-size: 13px;
				line-height: 27px;
				padding: 0;
			}
		}
	}

	.reply-textarea {
		width: 552px;
		height: 83px;
		border: 1px solid #FF3F26;
		outline: none;
		resize: none;
		padding: 12px 20px;
	}

	.reply-btn {
		width: 96px;
		height: 33px;
	}
</style>
