<template>
	<div>
		<el-dialog title="付款方式" :visible.sync="dialogVisible" width="640px" :before-close="handleClose">
			<div>
				<el-divider />
				<div class="flex-row-center-center mart26">
					<div class="alipay marr40 flex-row-center-center pos-r" @click="choose(1)"
						:class="{ active: way == 1 }">
						<img class="alipayicon" src="@/assets/icon_28@2x.png" alt="" />
						<img class="wayicon" src="@/assets/icon_30@2x.png" alt="" v-if="way == 1" />
					</div>
					<div class="alipay flex-row-center-center pos-r" @click="choose(2)" :class="{ active: way == 2 }">
						<img class="alipayicon" src="@/assets/icon_29@2x.png" alt="" />
						<img class="wayicon" src="@/assets/icon_30@2x.png" alt="" v-if="way == 2" />
					</div>
				</div>
				<div class="mart30 flex-row-center-center" v-if="way == 1">
					<iframe :srcdoc="form" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no"
						width="200" height="200" style="overflow: hidden">
					</iframe>
				</div>
				<div class="mart30 flex-row-center-center" v-else>
					<img class="code" :src="code" alt="" />
				</div>
				<div class="mart14 fz-14 flex-row-center-center" @click="scanCode">
					扫描二维码进行支付
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				way: 2,
				code: "",
				order_id: "", // 订单id
				form: "",
			};
		},
		methods: {
			show(code, order_id) {
				this.order_id = order_id;
				this.code = code;
				this.dialogVisible = true;
			},
			scanCode() {
				this.$router.push({
					path: '/ad/ad',
					query: {
						status: 4
					}
				})
				this.handleClose()
			},
			handleClose() {
				this.dialogVisible = false;
			},
			choose(value) {
				if (value == 1) {
					if (!this.form) {
						this.loading = true;
						this.$api.tokenRequest({
							method: 'POST',
							url: '/store/AdOrder/pay',
							isLoading: '获取支付信息...',
							data: {
								order_id: this.order_id,
								pay_type: 4,
							},
							success: res => {
								this.loading = false;
								this.form = res.data.params;
								this.$emit("startSearch", {});
							},
							fail: data => {
								this.loading = false;
								this.$message.error(data.message);
							},
						})
					}
				}
				this.way = value;
			},
		},
	};
</script>
<style lang="less" scoped>
	.wayicon {
		position: absolute;
		bottom: -1px;
		right: -1px;
		width: 20px;
		height: 20px;
	}

	.el-dialog__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		.alipay {
			width: 184px;
			height: 50px;
			border: 1px solid #eeeeee;
			border-radius: 6px;

			.alipayicon {
				width: 110px;
				height: 26px;
			}
		}

		.active {
			border: 1px solid #ff3f26;
		}
	}

	/deep/ .el-dialog {
		border-radius: 10px;
	}

	/deep/ .el-dialog__body {
		padding: 0 24px 40px 24px;
	}

	.code {
		width: 256px;
		height: 256px;
	}
</style>
