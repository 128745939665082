<template>
  <div class="flex-row-center-start item-height bg-F8F8F8 boderra8">
    <div class="item-height flex-row-center-center" :style="{'flex': v.flex,}" v-for="(v, i) in titlelist" :key="i">
      <div class="fz-16 co-999999">{{v.title}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: function() {
          return []
        }
      },
    },
    data() {
      return {
        titlelist: [],
      }
    },
    created() {
      this.setList(this.list);
    },
    watch: {
      list(n) {
        this.setList(n);
      },
    },
    methods: {
      // 设置渲染数组
      setList(val) {
        this.titlelist = val;
      },
    }
  }
</script>

<style>
  .item-height {
    height: 49px;
  }
</style>
