<template>
	<div @click="clickMesg">
		<img class="icon-img" v-if="type == 1"
			:style="{width: `${width}px`, height: `${height}px`, borderRadius: `${radius}px`}" :src="path" />
		<img class="icon-img w100" v-else-if="type == 2" :style="{height: `${height}px`, borderRadius: `${radius}px`}"
			:src="path" />
	</div>
</template>

<script>
	export default {
		name: "iconImg",
		props: {
			// 尺寸
			size: {
				type: String,
				default: '',
			},
			// 图片路径
			src: {
				type: String,
				default: '',
			},
			// 圆角
			radius: {
				type: [String, Number],
				default: 0,
			},
			imgtype: {
				type: [String, Number],
				default: 1,
			},
			status: {
				type: [String, Number],
				default: 1,
			}
		},
		data() {
			return {
				width: '', // 宽
				height: '', // 高
				path: '', // 图片路径
				type: 1
			};
		},
		watch: {
			size: {
				handler(nval) {
					this.setSize(nval);
				},
				deep: true
			},
			src: {
				handler(nval) {
					this.setSrc(nval);
				},
				deep: true
			},
			imgtype: {
				handler(nval) {
					this.setType(nval);
				},
				deep: true
			},
		},
		created() {
			if (this.size) {
				this.setSize(this.size);
			}
			if (this.src) {
				this.setSrc(this.src);
			}
			this.setType(this.imgtype);
		},
		methods: {
			// 设置尺寸
			setSize(size) {
				this.width = size.split(' ')[0];
				this.height = size.split(' ')[1];
			},
			// 设置图片路径
			setSrc(src) {
				if (this.status == 1) {
					this.path = require(`@/${src}`);
				} else {
					this.path = src;
				}
			},
			// 设置图片显示
			setType(type) {
				this.type = type;
			},
			clickMesg() {
				this.$emit('click')
			}
		}
	};
</script>

<style scoped>
	.icon-img {
		display: block;
		object-fit: cover;
	}
</style>
