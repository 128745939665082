<template>
	<div class="relative">
		<div class="h60 flex-row-center-sb paddl24 paddr32">
			<div class="flex-row-center-start">
				<iconImg src="assets/logo.png" size="36 36" mode="fit"></iconImg>
				<span class="fz-17 co-343434 marl16 font-blod">一乡一品商家端</span>
			</div>
			<div class="flex-row-center-start">
				<el-badge :value="noReadNum > 0 ? noReadNum : ''" class="item">
					<iconImg class="marr28 pointer" src="assets/icon_6.png" size="22 22" mode="fit" @click="toMessage">
					</iconImg>
				</el-badge>
				<el-dropdown>
					<div class="flex-row-center-start pointer">
						<iconImg status="2" :src="userinfo.avatar" size="36 36" radius="18" mode="fit"></iconImg>
						<span class="fz-14 co-343434 marl8 marr16">{{userinfo.store_name}}</span>
						<iconImg src="assets/icon_7.png" size="10 10" mode="fit"></iconImg>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<span @click="loginOut">退出登录</span>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<div class="flex-row w100 content">
			<div class="w240 content">
				<el-row class="tac w240">
					<el-col :span="12">
						<el-menu class="el-menu-vertical-demo" :default-openeds="defaultopeneds"
							background-color="#FFFFFF" :unique-opened="false">
							<div v-for="(v, i) in leftList" :key="i">
								<el-submenu class="w240" :index="i.toString()" v-if="v.children.length > 0">
									<template slot="title">
										<div class="flex-row-center-start">
											<img class="menuicon" :src="findex == i ? v.selectIcon : v.icon" />
											<span class="fz-15" :class="
                          findex == i ? 'font-blod co-FF3F26' : 'co-343434'
                        ">{{ v.title }}</span>
										</div>
									</template>
									<el-menu-item v-for="(vv, ii) in v.children" :key="ii"
										@click="chooseItem(vv, i, ii)">
										<div class="flex-row-center-start">
											<span slot="title" :class="
                          cindex == ii && findex == i
                            ? 'font-blod co-FF3F26'
                            : 'co-343434'
                        ">{{ vv.title }}</span>
										</div>
									</el-menu-item>
								</el-submenu>
								<el-menu-item :index="i.toString()" class="w240" @click="chooseItem(v, i)" v-else>
									<div class="flex-row-center-start">
										<img class="menuicon" :src="findex == i ? v.selectIcon : v.icon" />
										<span class="fz-15"
											:class="findex == i ? 'font-blod co-FF3F26' : 'co-343434'">{{ v.title }}</span>
									</div>
								</el-menu-item>
							</div>
						</el-menu>
					</el-col>
				</el-row>
			</div>
			<div class="bg-F8F8F8 box">
				<router-view class="paddlr20 paddt20" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Bus
	} from 'vue-happy-bus';
	
	export default {
		name: "layout",
		data() {
			return {
				noReadNum: 0,
				userinfo: "",
				findex: -1,
				cindex: -1,
				defaultopeneds: [],
				leftList: [{
						title: "首页",
						children: [],
						router: "/index/index",
						icon: require("@/assets/menu/tab_1@2x.png"),
						selectIcon: require("@/assets/menu/tab_1_pre@2x.png"),
					},
					{
						title: "产品管理",
						icon: require("@/assets/menu/tab_2@2x.png"),
						selectIcon: require("@/assets/menu/tab_2_pre@2x.png"),
						router: "/product",
						children: [{
								title: "发布产品",
								router: "/product/release",
							},
							{
								title: "我的产品",
								router: "/product/product",
							},
						],
					},
					{
						title: "广告管理",
						icon: require("@/assets/menu/tab_3@2x.png"),
						selectIcon: require("@/assets/menu/tab_3_pre@2x.png"),
						router: "/ad",
						children: [{
								title: "发布广告",
								router: "/ad/release",
							},
							{
								title: "我的广告",
								router: "/ad/ad",
							},
						],
					},
					{
						title: "客户管理",
						icon: require("@/assets/menu/tab_5@2x.png"),
						selectIcon: require("@/assets/menu/tab_5_pre@2x.png"),
						children: [],
						router: "/customer/index",
					},
					{
						title: "集采大厅",
						icon: require("@/assets/menu/tab_6@2x.png"),
						selectIcon: require("@/assets/menu/tab_6_pre@2x.png"),
						children: [],
						router: "/purchase/hall",
					},
					{
						title: "商家招募",
						icon: require("@/assets/menu/tab_8@2x.png"),
						selectIcon: require("@/assets/menu/tab_8_pre@2x.png"),
						children: [],
						router: "/merchant/index",
					},
					{
						title: "数字基建",
						icon: require("@/assets/menu/tab_9@2x.png"),
						selectIcon: require("@/assets/menu/tab_9_pre@2x.png"),
						children: [],
						router: "/digital/index",
					},
					{
						title: "添加盲盒",
						icon: require("@/assets/menu/tab_10@2x.png"),
						selectIcon: require("@/assets/menu/tab_10_pre@2x.png"),
						children: [],
						router: "/blind/index",
					},
					{
						title: "订单管理",
						icon: require("@/assets/menu/tab_11@2x.png"),
						selectIcon: require("@/assets/menu/tab_11_pre@2x.png"),
						children: [],
						router: "/order/index",
					},
					{
						title: "个人中心",
						icon: require("@/assets/menu/tab_12@2x.png"),
						selectIcon: require("@/assets/menu/tab_12_pre@2x.png"),
						router: "/mine",
						children: [{
								title: "店铺设置",
								router: "/mine/storeSet",
							},
							{
								title: "发票管理",
								router: "/mine/invoice",
							},
							{
								title: "活动报名",
								router: "/mine/activity",
							},
							{
								title: "资金管理",
								router: "/mine/capital",
							},
							{
								title: "数字基建",
								router: "/mine/digitalInfrastructure",
							},
							{
								title: "隐私管理",
								router: "/mine/privacy",
							},
							{
								title: "评价管理",
								router: "/mine/evaluation",
							},
							{
								title: "规则说明",
								router: "/mine/rule",
							},
							{
								title: "意见反馈",
								router: "/mine/feedback",
							},
						],
					},
				],
			};
		},
		watch: {
			$route: {
				handler: function(val) {
					if (val.path == '/message/index') {
						this.findex = -1;
						this.defaultopeneds = [];
						return
					}
					if (val.path != "/") {
						this.leftList.some((e, i) => {
							if (e.children.length > 0) {
								if (val.path.indexOf(e.router) != -1) {
									this.findex = i;
									e.children.some((item, index) => {
										if (item.router == val.path) {
											this.cindex = index;
										}
									});
								}
							} else {
								if (e.router == val.path) {
									this.findex = i;
								}
							}
						});
					} else {
						this.findex = 0;
					}
					let i = this.defaultopeneds.indexOf(this.findex.toString());
					if (i != -1) {
						this.defaultopeneds.splice(i, 1);
					}
					this.defaultopeneds.push(this.findex.toString());
				},
				// 深度观察监听
				deep: true,
				immediate: true,
			},
		},
		created() {
			let token = localStorage.getItem("token");
			if (token) {
				this.getUserInfo();
			}
			
			this.$webSocket.getUnReadNum()
			
			// 接收到消息
			Bus.$on("onMsg", res => {
				this.noReadNum++;
			});
			// 消息未读
			Bus.$on("onGetUnReadNumResult", res => {
				if (res.total_unread_num > 0) {
					this.noReadNum = res.total_unread_num;
				}
			});
		},
		destroyed() {
			Bus.$off("onMsg");
			Bus.$off("onGetUnReadNumResult");
		},
		methods: {
			// 左导航点击
			chooseItem(v, findex, cindex) {
				this.findex = findex;
				this.cindex = cindex;
				this.$router.push({
					path: v.router,
				});
			},
			toMessage() {
				this.$router.push({
					path: "/message/index",
				});
			},
			loginOut() {
				this.$confirm('是否确认退出登录?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$webSocket.close();
					localStorage.removeItem("userinfo");
					localStorage.removeItem("token");
					this.$message.success("退出登录成功");
					this.$router.replace({
						path: "/",
					});
				})
			},
			// 获取个人信息
			getUserInfo() {
				this.$api.tokenRequest({
					method: 'get',
					url: '/store/user',
					isLoading: '加载中...',
					success: res => {
						localStorage.setItem("userinfo", JSON.stringify(res.data));
						this.$store.commit("setUserInfo", res.data);
						this.userinfo = res.data;
						this.$webSocket.config({
							username: res.data.im_user.username,
							password: res.data.im_user.password
						})
						this.$webSocket.open();
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			}
		},
	};
</script>

<style scoped lang="less">
	.content {
		height: calc(100vh - 60px);
		box-sizing: border-box;

		.tac {
			height: 100%;
			overflow: scroll;
		}
	}

	.item {
		margin-right: 30px;
	}

	/deep/ .el-badge__content.is-fixed {
		right: 40px;
	}

	.box {
		height: calc(100vh - 60px);
		overflow-y: scroll;
		flex: 1;
		min-width: 1200px;
		box-sizing: border-box;

		/deep/.paging .btn-prev {
			width: 30px;
			height: 30px;
			background: #ffffff;
			border: 1px solid #eeeeee;
			border-radius: 4px;
		}

		/deep/.paging .btn-next {
			width: 30px;
			height: 30px;
			background: #ffffff;
			border: 1px solid #eeeeee;
			border-radius: 4px;
		}

		/deep/.paging .number {
			width: 30px;
			height: 30px;
			background: #ffffff !important;
			border: 1px solid #eeeeee;
			border-radius: 4px;
			font-size: 13px;
			color: #343434;
			font-weight: normal;
		}

		/deep/.paging .active {
			width: 30px;
			height: 30px;
			background: #ffffff !important;
			border: 1px solid #ff3f26 !important;
			color: #ff3f26 !important;
			border-radius: 4px;
			font-size: 13px;
			font-weight: normal;
		}

		/deep/.paging .el-pager li {
			width: 30px;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #eeeeee;
			background: #ffffff !important;
		}

		/deep/.paging .el-pager li:hover {
			color: #ff3f26 !important;
		}

		/deep/.paging .el-pagination__sizes {
			line-height: 30px;
		}

		/deep/.paging .el-pagination__sizes .el-input {
			width: 94px;
			height: 30px;
			margin: 0;
			margin-left: 8px;
		}

		/deep/.side-pop .el-drawer__header {
			margin: 0;
			padding: 0;
		}

		/deep/.reply-pop .el-dialog {
			border-radius: 10px;
		}

		/deep/.reply-pop .el-dialog .el-dialog__header {
			padding: 0;
		}

		/deep/.reply-pop .el-dialog__body {
			padding: 0;
		}
	}

	.menuicon {
		width: 18px;
		height: 18px;
		margin-right: 14px;
	}
</style>
