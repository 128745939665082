// 获取当前时间
let $getCurrentTime = function(type = "ms", connector = 1) {
	let mydate = new Date();
	let year = mydate.getFullYear();
	let month = mydate.getMonth() + 1;
	let day = mydate.getDate();
	let hour = mydate.getHours();
	let minute = mydate.getMinutes();
	let second = mydate.getSeconds();
	let time = mydate.getTime();
	// 格式化
	month = month < 10 ? "0" + month : month;
	day = day < 10 ? "0" + day : day;
	hour = hour < 10 ? "0" + hour : hour;
	minute = minute < 10 ? "0" + minute : minute;
	second = second < 10 ? "0" + second : second;
	let str = '';
	if (type == "year") {
		// 年
		str = year;
		if (connector == 2) {
			str += "年";
		}
	} else if (type == "month") {
		// 月
		if (connector == 1) {
			str = [year, month].join("-");
		} else if (connector == 3) {
			str = [year, month].join("/");
		} else if (connector == 2) {
			str = year + "年" + month + "月";
		}
	} else if (type == "day") {
		// 日
		if (connector == 1) {
			str = [year, month, day].join("-");
		} else if (connector == 3) {
			str = [year, month, day].join("/");
		} else if (connector == 2) {
			str = year + "年" + month + "月" + day + "日";
		}
	} else if (type == "min") {
		// 分
		if (connector == 1) {
			str = [year, month, day].join("-") + " " + [hour, minute].join(":");
		} else if (connector == 3) {
			str = [year, month, day].join("/") + " " + [hour, minute].join(":");
		} else if (connector == 2) {
			str = year + "年" + month + "月" + day + "日" + " " + [hour, minute].join(":");
		}
	} else if (type == "sec") {
		// 秒
		if (connector == 1) {
			str = [year, month, day].join("-") + " " + [hour, minute, second].join(":");
		} else if (connector == 3) {
			str = [year, month, day].join("/") + " " + [hour, minute, second].join(":");
		} else if (connector == 2) {
			str = year + "年" + month + "月" + day + "日" + " " + [hour, minute, second].join(":");
		}
	} else if (type == "ms") {
		str = time;
	}
	return str
}

export default {
	getCurrentTime: $getCurrentTime,
};
