<template>
  <div class="content">
    <div class="content detail bg-white borra10 flex-column">
      <div class="flex-row-center-sb">
        <div class="flex-row-center-start">
          <img class="logo" src="@/assets/logo.png" alt="" />
          <span class="fz-18 marl12">江大小S</span>
          <div
            class="
              follow
              flex-row-center-center
              fz-12
              color-white
              marl16
              pointer
            "
            @click="clickFollow"
            v-if="!info.is_follow"
          >
            +关注
          </div>
          <div
            class="followed flex-row-center-center fz-12 marl16 pointer"
            @click="clickFollow"
            v-else
          >
            已关注
          </div>
        </div>
        <div class="btn flex-row-center-center fz-14 pointer" @click="toBack">
          返回直播列表
        </div>
      </div>
      <div class="flex-1 mart24">
        <!-- 直播页面的地方,暂时先用图片代替，后期要更换为直播画面的地方 -->
        <img class="cover" src="@/assets/img_4@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {
        is_follow: false,
      },
    };
  },
  methods: {
    toBack() {
      this.$router.go(-1);
    },
    clickFollow() {
      // 点击是否关注
      let title = "是否确认关注?",
        yesText = "关注成功!";
      if (this.info.is_follow) {
        title = "是否确认取消关注？";
        yesText = "取消关注成功!";
      }
      this.$confirm(title, "提示", {
        type: "warning",
      }).then(() => {
        this.info.is_follow = !this.info.is_follow;
        this.$message({
          type: "success",
          message: yesText,
        });
      });
    },
  },
};
</script>
<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .detail {
    padding: 30px 60px;
    box-sizing: border-box;
    .logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .follow {
      padding: 4px 12px;
      border-radius: 6px;
      background: #ff3f26;
    }
    .followed {
      border: 1px solid #ff3f26;
      color: #ff3f26;
      padding: 4px 12px;
      border-radius: 6px;
    }
    .btn {
      width: 116px;
      height: 32px;
      border: 1px solid #eeeeee;
      border-radius: 5px;
    }
    .cover {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}
</style>
