<template>
	<div>
		<el-drawer title="集采详情" size="1000px" :visible.sync="drawer" :before-close="handleClose">
			<div class="mart26 paddlr50" v-if="details">
				<div class="flex-row-center-start paddb30 boderb1-EEEEEE marb32">
					<iconImg status="2" :src='details.user.avatar' radius="28" size='56 56' mode='fit'></iconImg>
					<div class="h56 flex-column-sb marl16">
						<div class="flex-row-center-start">
							<span class="fz-18 font-blod co-343434">{{details.user.nickname}}</span>
							<div class="label label-01 flex-row-center-start marl12" v-if="details.user.is_auth == 1">
								<iconImg src='assets/icon_31.png' size='14 14' mode='fit'></iconImg>
								<span class="fz-10 color-white marl2">实名</span>
							</div>
							<div class="label label-02 flex-row-center-start marl10" v-if="details.user.auth_type_text">
								<iconImg src='assets/icon_32.png' size='14 14' mode='fit'></iconImg>
								<span class="fz-10 color-white marl2">{{details.user.auth_type_text}}</span>
							</div>
							<div class="label label-03 flex-row-center-start marl10" v-if="details.user.is_store == 1">
								<iconImg src='assets/icon_33.png' size='14 14' mode='fit'></iconImg>
								<span class="fz-10 color-white marl2">店铺</span>
							</div>
						</div>
						<span class="fz-14 co-999999">采集发布日期：{{details.create_time}}</span>
					</div>
				</div>
				<div class="flex-row-wrap">
					<div class="flex-row-center-start detail-item fz-14 marb24">
						<span class="co-999999 w-108">集采号码</span>
						<span class="co-343434">{{details.order_no}}</span>
					</div>
					<div class="flex-row-center-start detail-item fz-14 marb24">
						<span class="co-999999 w-122">集采品种</span>
						<span class="co-343434">{{details.cate_text}}</span>
					</div>
					<div class="flex-row-center-start detail-item fz-14 marb24">
						<span class="co-999999 w-108">集采品类</span>
						<span class="co-343434">{{details.cate_name}}</span>
					</div>
					<div class="flex-row-center-start detail-item fz-14 marb24">
						<span class="co-999999 w-122">集采时点</span>
						<span class="co-343434">{{`${details.type == 1 ? '定时发货' : '即时发货'} ${details.type == 1 ? details.time.split(" ")[0] : ""}`}}</span>
					</div>
					<div class="flex-row-center-start detail-item fz-14 marb24">
						<span class="co-999999 w-108">集采频率</span>
						<span class="co-343434">{{details.frequency_text}}</span>
					</div>
					<div class="flex-row-center-start detail-item fz-14 marb24">
						<span class="co-999999 w-122">集采数量</span>
						<span class="co-343434">{{details.num}}{{details.num_text}}</span>
					</div>
					<div class="flex-row-center-start detail-item fz-14 marb24">
						<span class="co-999999 w-108">期望价格区间</span>
						<span class="font-blod co-FF3F26">{{`${details.low_price}-${details.high_price}`}}元</span>
					</div>
					<div class="flex-row-center-start detail-item fz-14 marb24">
						<span class="co-999999 w-122">是否开票</span>
						<span class="co-343434">{{details.is_ticket == 0 ? '否' : '是'}}</span>
					</div>
					<div class="flex-row-center-start detail-item fz-14 marb24" v-if="details.is_ticket == 1">
						<span class="co-999999 w-108">开票信息</span>
						<div class="flex-row-center-start pointer" @click="showTicket">
							<span class="co-343434 marr4">查看开票信息</span>
							<iconImg src='assets/icon_9.png' size='10 10' mode='fit'></iconImg>
						</div>
					</div>
					<div class="flex-row-center-start detail-item fz-14 marb24">
						<span class="co-999999 w-122">是否接受多接单</span>
						<span class="co-343434">{{details.is_many == 1 ? '是' : '否'}}</span>
					</div>
					<div class="flex-row-center-start detail-item fz-14">
						<span class="co-999999 w-108">备注</span>
						<span class="co-343434">{{details.desc}}</span>
					</div>
				</div>
			</div>
			
			<div class="bottom">
				<el-divider></el-divider>
				<div class="flex-row-center-center marb16">
					<el-button type="primary" class="submit" @click="onSubmit">立即报价</el-button>
				</div>
			</div>
		</el-drawer>
		
		<ticketPop ref="ticketPop" />
		<offerPop ref="offerPop" @offerSuccess="handleClose" />
	</div>
</template>
<script>
	import ticketPop from './ticketPop.vue';
	import offerPop from './offer.vue';
	export default {
		components: {
			ticketPop,
			offerPop,
		},
		data() {
			return {
				drawer: false,
				details: "",
			};
		},
		methods: {
			// 查看开票信息
			showTicket() {
				this.$refs.ticketPop.show(this.details.ticket);
			},
			show(val) {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/Purchase/Detail',
					isLoading: '加载中...',
					data: {
						id: val.id,
					},
					success: res => {
						this.details = res.data;
						this.drawer = true;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			handleClose() {
				this.drawer = false;
			},
			onSubmit() {
				this.$refs.offerPop.show(this.details);
			},
			chooseShop(item) {
				item.is_choose = !item.is_choose;
			},
		},
	};
</script>
<style lang="less" scoped>
	.label {
		padding-right: 8px;
		height: 14px;
		border-radius: 9px;
	}
	
	.label-01 {
		background: #FF3F26;
	}
	
	.label-02 {
		background: #FFA826;
	}
	
	.label-03 {
		background: #7D6AF4;
	}
	
	.detail-item {
		width: 50%;
	}
	
	.bottom {
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 100%;
	
		.submit {
			width: 128px;
			height: 40px;
			background: #ff3f26;
			border-radius: 6px;
			border: 1px solid #ff3f26;
		}
	}
</style>
