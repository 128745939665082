<template>
	<div class="contentcustomer">
		<el-container class="container">
			<div class="flex-row-center-sb">
				<div class="bg-white numwrap marr16 flex-row-center-sb">
					<div>
						<div class="fz-16">今日访客</div>
						<div class="mart10 fz-34 font-bold">{{details.today_count}}</div>
					</div>
					<img class="icon" src="../../assets/icon_17@2x.png" alt="" />
				</div>
				<div class="bg-white numwrap marr16 flex-row-center-sb">
					<div>
						<div class="fz-16">昨日访客</div>
						<div class="mart10 fz-34 font-bold">{{details.yesterday_count}}</div>
					</div>
					<img class="icon" src="../../assets/icon_18@2x.png" alt="" />
				</div>
				<div class="bg-white numwrap flex-row-center-sb">
					<div>
						<div class="fz-16">今月访客</div>
						<div class="mart10 fz-34 font-bold">{{details.month_count}}</div>
					</div>
					<img class="icon" src="../../assets/icon_19@2x.png" alt="" />
				</div>
			</div>
			<el-main class="bg-white mart20">
				<div class="container">
					<div class="fz-20 font-bold">访客列表</div>
					<Ltitle class="paddt40" :list="titleList" :titleId="cid" @change="titleChange"></Ltitle>
					<div class="mart24 pos-r flex-1">
						<el-table :data="tableData" height="100%" style="width: 100%;:">
							<el-table-column fixed prop="name" label="用户信息">
								<template slot-scope="scope">
									<div class="flex-row-center-center">
										<img class="avatar" :src="scope.row.user.avatar" alt="" />
										<span class="fz-14 marl8">{{ scope.row.user.name }}</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="title" label="浏览页面"> </el-table-column>
							<el-table-column prop="operate" label="操作">
								<template slot-scope="scope">
									<div class="flex-row-center-center pointer" @click="toChat(scope.row)">
										<img class="iconchat" src="../../assets/icon_20@2x.png" alt="" />
										<span class="fz-14 marl8 color-red">聊一聊</span>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="flex-row-center-center mart28">
						<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
							@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
							:total="total" :page-sizes="[10, 15, 20]">
						</el-pagination>
					</div>
				</div>
			</el-main>
		</el-container>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				titleList: [{
					id: 1,
					title: "今日",
				},
				{
					id: 2,
					title: "昨日",
				},
				{
					id: 3,
					title: "今月",
				}],
				cid: 1,
				tableData: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,
				details: "",
			};
		},
		mounted() {
			this.getData();
			this.onSubmit();
		},
		methods: {
			// 改变状态
			titleChange(v) {
				this.cid = v.id;
				this.onSubmit();
			},
			toChat(v) {
				this.$router.push({
					path: '/message/index',
					query: {
						type: 1,
						user: v.user,
						im_user: v.im_user,
					}
				})
			},
			getData() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/visitor/count',
					isLoading: '加载中...',
					success: res => {
						this.details = res.data;
					},
					fail: data => {
						this.message.error(data.message);
					},
				});
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/visitor',
					isLoading: '加载中...',
					data: {
						type: this.cid,
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.tableData = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.message.error(data.message);
					},
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.contentcustomer {
		width: calc(100% - 40px);
		height: calc(100% - 20px);

		.container {
			flex-direction: column;
			width: 100%;
			height: 100%;
			display: flex;

			.numwrap {
				padding: 30px 40px;
				border-radius: 10px;
				width: 30%;

				.icon {
					width: 242px;
					height: 70px;
				}
			}

			/deep/ .el-table {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
			}

			/deep/.el-table::before,
			/deep/ .el-table__fixed::before {
				height: 0;
			}

			/deep/.el-table th.el-table__cell.is-leaf {
				background-color: #f8f8f8;
				color: #999999;
				font-size: 15px;
				border-radius: 8px;
				text-align: center;
				border-bottom-color: #f8f8f8;
				font-weight: normal;
			}

			/deep/ .el-table .el-table__cell {
				text-align: center;
				padding: 18px 0;
			}

			.avatar {
				width: 32px;
				height: 32px;
				border-radius: 50%;
			}

			.iconchat {
				width: 14px;
				height: 14px;
			}

			/deep/.paging .btn-prev {
				width: 30px;
				height: 30px;
				background: #ffffff;
				border: 1px solid #eeeeee;
				border-radius: 4px;
			}

			/deep/.paging .btn-next {
				width: 30px;
				height: 30px;
				background: #ffffff;
				border: 1px solid #eeeeee;
				border-radius: 4px;
			}

			/deep/.paging .number {
				width: 30px;
				height: 30px;
				background: #ffffff !important;
				border: 1px solid #eeeeee;
				border-radius: 4px;
				font-size: 13px;
				color: #343434;
				font-weight: normal;
			}

			/deep/.paging .active {
				width: 30px;
				height: 30px;
				background: #ffffff !important;
				border: 1px solid #ff3f26 !important;
				color: #ff3f26 !important;
				border-radius: 4px;
				font-size: 13px;
				font-weight: normal;
			}

			/deep/.paging .el-pager li {
				width: 30px;
				height: 30px;
				border-radius: 4px;
				border: 1px solid #eeeeee;
				background: #ffffff !important;
			}

			/deep/.paging .el-pager li:hover {
				color: #ff3f26 !important;
			}

			/deep/.paging .el-pagination__sizes {
				line-height: 30px;
			}

			/deep/.paging .el-pagination__sizes .el-input {
				width: 94px;
				height: 30px;
				margin: 0;
				margin-left: 8px;
			}
		}
	}
</style>
