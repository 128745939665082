<template>
	<div class="content flex-column">
		<img class="img" src="@/assets/img_23@2x.png" alt="" />
		<el-scrollbar class="mart20" height="100%">
			<div class="flex-wrap scrollwrap">
				<div class="scollitem" v-for="(item, index) in list" :key="index">
					<div class="bg-white boderra10 padd20 fz-16 item flex-row-center-start" @click="goDetail(item)">
						<img class="cover" :src="item.image" alt="" />
						<div class="flex-1 marl10">
							<div class="fz-16 font-bold">{{item.title}}</div>
							<div class="mart6 fz-12 color-grey">
								活动时间：{{item.start_time}}至{{item.end_time}}
							</div>
							<div class="mart6 fz-12 color-grey">
								报名时间：{{item.sign_start_time}}至{{item.sign_end_time}}
							</div>
							<div class="flex-row-center-sb mart16 flex-wrap">
								<div class="fz-12 color-red">剩余时间：{{item.time_text}}</div>
								<div class="flex-row-center-center fz-16 report color-white pointer">立即报名</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-scrollbar>
		<div class="mart20 flex-1"></div>
		<div class="flex-row-center-center mart10 marb20">
			<el-pagination class="paging" background layout="prev, pager, next, sizes, jumper"
				@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
				:total="total" :page-sizes="[10, 15, 20]">
			</el-pagination>
		</div>
		<recruitDiolog ref="dialogRef" />
	</div>
</template>
<script>
	import recruitDiolog from './components/recruitDiolog.vue'
	export default {
		components: {
			recruitDiolog,
		},
		data() {
			return {
				list: [],
				page: 1,
				size: 10,
				total: 0,
				loading: false,
			}
		},
		mounted() {
			this.onSubmit();
		},
		beforeDestroy() {
			this.$refs.dialogRef.removeTime();
		},
		methods: {
			// 修改分页数量
			handleSizeChange(e) {
				this.size = e;
				this.onSubmit();
			},
			// 点击页码
			handleCurrentChange(e) {
				this.page = e;
				this.onSubmit();
			},
			onSubmit() {
				if (this.loading) {
					return false;
				}
				this.list = [];
				this.page = 1;
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/Cloud/list',
					isLoading: '加载中...',
					data: {
						page: this.page,
						size: this.size,
					},
					success: res => {
						this.list = res.data.data;
						this.total = res.data.total;
						this.loading = false;
					},
					fail: data => {
						this.loading = false;
						this.message.error(data.message);
					},
				})
			},
			goDetail(item) {
				this.$refs.dialogRef.show(item.id)
			}
		}
	}
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;

		/deep/ .el-scrollbar {
			min-width: 1200px;

			.scrollwrap {
				min-width: 1200px;
				display: flex;
				align-items: start;
				justify-content: start;

				.scollitem {
					width: 25%;
					box-sizing: border-box;
				}
			}
		}
	}

	.img {
		width: 100%;
		height: 160px;
		border-radius: 10px;
	}

	.report {
		width: 80px;
		height: 28px;
		background-color: #ff3f26;
		border-radius: 50px;
	}

	.item {
		margin-right: 12px;
		margin-bottom: 20px;

		.cover {
			width: 113px;
			height: 113px;
			border-radius: 6px;
		}
	}

	/deep/.el-col-6:nth-child(4n) .item {
		margin-right: 0;
	}
</style>
