<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	import { Bus } from 'vue-happy-bus';
	
	export default {
		name: "App",
		created() {},
		mounted() {
			// 配置websocket监听事件
			this.$webSocket.config({
			    // 登录成功
			    onLoginSuccess: (res) => {
			        // 获取未读消息数
			        console.log(res);
			        // todo
			    },
			    // 接收到最近会话列表数据
			    onRecentList: (res) => {
			        // let list = res.data;
			        // let unReadNum = 0;
			        // list.forEach((item, index) => {
			        //     unReadNum += parseInt(item.unReadNum || 0);
			        // });
			        // res.unReadNum = unReadNum;
			        Bus.$emit('onRecentList', res.data);
			    },
			    // 接收到好友列表数据
			    onFriendList: (res) => {
			        Bus.$emit('onFriendList', res);
			    },
			    // 接收到聊天记录数据
			    onMsgRecordHistory: (res) => {
			        Bus.$emit('onMsgRecordHistory', res);
			    },
			    // 消息发送结果回调
			    onMsgResult: (res) => {
			        Bus.$emit('onMsgResult', res);
			    },
				// 消息未读总数回调
				onGetUnReadNumResult: (res) => {
				    Bus.$emit('onGetUnReadNumResult', res);
				},
			    // 接收到消息
			    onMsg: (res) => {
			        Bus.$emit('onMsg', res);
			        // 播放消息提示音
			        // document.querySelector("#myAudio").play();
			        // this.$webSocket.getRecentList();
			    },
			    // 接收到客服列表
			    onCustomerGroupList: (res) => {
			        Bus.$emit('onCustomerGroupList', res);
			    },
			    // 接收到其他消息回调
			    onOtherMessage: (res) => {
			        Bus.$emit('onOtherMessage', res);
			    },
			    // 关闭回调
			    onClose: (res) => {
			        // todo
			    },
			    // 错误回调
			    onError: (res) => {
			        // todo
			    }
			});
			
			let token = localStorage.getItem("token");
			if (!token) {
				this.$router.replace({
					path: "/"
				});
			} else {
				this.$router.replace({
					path: "/index/index"
				});
			}
		},
		methods: {}
	};
</script>

<style lang="less">
	#app {
		width: 100%;
		height: 100%;
		min-width: 1200px;
	}

	* {
		margin: 0;
		padding: 0;
	}

	body {
		margin: 0;
		padding: 0;
	}

	::-webkit-scrollbar {
		/* 隐藏滚动条 */
		background: #f1f1f1;
		border-radius: 5px;
		height: 6px;
		width: 0 !important;
	}

	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 5px;
		background: #d7dce6;
	}
</style>