<template>
	<div>
		<div class="fz-20 font-bold">订单详情</div>
		<div class="mart24 paddtb24 paddlr40 bg-white boderra10">
			<div class="fz-16 font-bold">订单状态</div>
			<div class="mart50 stwpwrap">
				<el-row>
					<el-col :span="3">
						<div class="flex-row-center-center">
							<div>
								<div class="fz-18 font-bold color-red">
									{{ details.status_text }}
								</div>
								<div class="fz-14 mart10">{{ details.status_desc || "" }}</div>
							</div>
						</div>
					</el-col>
					<el-col :span="20" :offset="1">
						<div class="right">
							<el-steps finish-status="success">
								<el-step v-for="(item, index) in stepList" :key="index"
									:class="{ stepactive: details.status > item.status }">
									<template v-slot:icon>
										<div>
											<img class="icon" v-if="details.status >= item.status" src="@/assets/icon_42@2x.png"
												alt="" />
											<img class="icon" v-else src="@/assets/icon_43@2x.png" alt="" />
										</div>
									</template>
									<template v-slot:title>
										<div>
											<div class="fz-14 color-black"
												:class="{ 'color-red': details.status >= item.status }">
												{{ item.title }}
											</div>
											<div :class="{ 'color-black': details.status >= item.status }">
												<div class="fz-12">{{item.date}}</div>
												<div class="fz-12">{{item.time}}</div>
											</div>
										</div>
									</template>
								</el-step>
							</el-steps>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="mart20 mart24 paddtb24 paddlr40 bg-white boderra10 fz-14" v-if="details">
			<el-row>
				<el-col :span="8" v-if="details.address_info">
					<div class="fz-14 font-bold marb24">收货人信息</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">收货人</span>
						<span>{{details.address_info.name}}</span>
					</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">收货地址</span>
						<span>{{details.address_info.full_address}}{{details.address_info.address}}</span>
					</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">手机号码</span>
						<span>{{details.address_info.phone}}</span>
					</div>
				</el-col>
				<el-col :span="8" v-if="details.express">
					<div class="" v-if="details.express.express_type == 2">
						<div class="fz-14 font-bold marb24">司机信息</div>
						<div class="paddb16 flex-row-center-start">
							<span class="label">司机姓名</span>
							<span>{{details.express.link_username}}</span>
						</div>
						<div class="paddb16 flex-row-center-start">
							<span class="label">车牌号</span>
							<span>{{details.express.car_number}}</span>
						</div>
						<div class="paddb16 flex-row-center-start">
							<span class="label">手机号码</span>
							<span>{{details.express.link_phone}}</span>
						</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="fz-14 font-bold marb24">订单信息</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">订单编号</span>
						<span>{{details.order_sn}}</span>
					</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">买家信息</span>
						<div class="flex-1 flex-row-center-start pointer" @click="toChat">
							<span class="marr16">{{details.address_info.name}}</span>
							<img class="icon marr6" src="@/assets/icon_41@2x.png" alt="" />
							<span class="color-red">联系买家</span>
						</div>
					</div>
					<div class="paddb16 flex-row-center-start">
						<span class="label">订单备注</span>
						<span>{{details.desc}}</span>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="mart20 mart24 paddtb24 paddlr40 bg-white boderra10 fz-14" v-if="details">
			<div class="fz-16 font-bold marb24">商品信息</div>
			<div class="tablewrap">
				<div class="tableheader fz-15">
					<el-row>
						<el-col :span="8">
							<div class="tableheaderone flex-row-center-center">商品</div>
						</el-col>
						<el-col :span="4">
							<div class="tableheaderone flex-row-center-center">规格说明</div>
						</el-col>
						<el-col :span="4">
							<div class="tableheaderone flex-row-center-center">数量</div>
						</el-col>
						<el-col :span="4">
							<div class="tableheaderone flex-row-center-center">单价</div>
						</el-col>
						<el-col :span="4">
							<div class="tableheaderone flex-row-center-center">操作</div>
						</el-col>
					</el-row>
				</div>
				<el-row>
					<el-col :span="8">
						<div class="flex-row paddtb20 paddlr24 table">
							<img class="cover" :src="details.goods.cover" alt="" />
							<div class="marl10 fz-14">{{details.goods.title}}</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
							<div>
								<div v-for="(v, i) in details.spec" :key="i">{{v.spec_name}}</div>
							</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
							<div>
								<div v-for="(v, i) in details.spec" :key="i">x{{v.number}}</div>
							</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
							<div>
								<div v-for="(v, i) in details.spec" :key="i">￥{{v.price}}</div>
							</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="flex-row-center-center paddtb20 paddlr24 table fz-14">
							<div>
								<el-button class="changebtn flex-row-center-center" v-if="details.status == 10 || details.status == 20"
									@click="changePrice">改价</el-button>
								<el-button class="changebtn flex-row-center-center" @click.stop="toExpress" v-else-if="details.status == 30">去发货
								</el-button>
								<div class="pointer" v-else-if="details.status == 40 && details.express.express_type == 1" @click="$refs.expreddRef.show(details)">查看物流</div>
								<div v-else-if="details.status == 40 && details.express.express_type == 2" class="color-red">
									*货运方式请拨打联系电话咨询运输情况
								</div>
								<div v-else-if="details.status == 60" @click="clear">
									<el-popconfirm title="是否确认隐藏订单？" @confirm="hiddenOrder">
										<div class="pointer" slot="reference">{{details.store_is_hide == 0 ? '隐藏订单' : '取消隐藏'}}</div>
									</el-popconfirm>
									<el-popconfirm title="是否确认删除订单？" @confirm="deleteOrder">
										<div class="color-grey pointer" slot="reference">
											删除订单
										</div>
									</el-popconfirm>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="flex-row-center-end mart24 fz-14">
				<span class="text-right labeltxt">商品总额：</span>
				<span>￥{{details.price}}</span>
			</div>
			<div class="flex-row-center-end mart24 fz-14">
				<span class="text-right labeltxt">运费：</span>
				<span>￥{{details.express_price}}</span>
			</div>
			<div class="flex-row-center-end mart24 fz-14 color-red" v-if="details.status > 20">
				<span class="text-right labeltxt">实付款：</span>
				<span class="font-bold">￥{{details.pay_price}}</span>
			</div>
		</div>
		<priceChange ref="changeRef" @refreshList="getData" />
		<deliverGoods ref="goodsRef" @refreshList="getData" />
		<checkExpress ref="expreddRef" />
	</div>
</template>
<script>
	import priceChange from "./components/priceChange.vue";
	import deliverGoods from "./components/deliverGoods.vue";
	import checkExpress from "./components/checkExpress.vue";
	export default {
		components: {
			priceChange,
			deliverGoods,
			checkExpress
		},
		data() {
			return {
				id: "",
				details: {
					express: "",
				},
				active: 1,
				statusInfo: {
					txt: "",
					desc: "",
				},
				stepList: [{
					title: "提交订单",
					status: 1,
					date: "",
					time: "",
				},
				{
					title: "待修改",
					status: 10,
					date: "",
					time: "",
				},
				{
					title: "等待付款",
					status: 20,
					date: "",
					time: "",
				},
				{
					title: "等待发货",
					status: 30,
					date: "",
					time: "",
				},
				{
					title: "等待收货",
					status: 40,
					date: "",
					time: "",
				},
				{
					title: "交易完成",
					status: 60,
					date: "",
					time: "",
				}],
				info: {
					id: 1,
					is_si: 0, //是否是司机运送
				},
			};
		},
		watch: {
			$route: {
				handler: function(val, oldVal) {
					this.id = val.query.id;
					this.getData();
				},
				// 深度观察监听
				deep: true,
				immediate: true,
			},
		},
		methods: {
			changePrice() {
				this.$refs.changeRef.show(this.details);
			},
			toExpress(item) {
				this.$refs.goodsRef.show(this.details);
			},
			clear() {
				// 阻止冒泡
				return;
			},
			// 隐藏订单
			hiddenOrder() {
				this.$api.tokenRequest({
					method: 'POST',
					url: '/store/order/hide',
					isLoading: '加载中...',
					data: {
						order_id: this.id,
					},
					success: res => {
						this.$message.success(details.store_is_hide == 0 ? "隐藏成功" : "已取消");
						if (this.details.store_is_hide == 0) {
							this.details.store_is_hide = 1;
						} else {
							this.details.store_is_hide = 0;
						}
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			// 删除订单
			deleteOrder() {
				this.$api.tokenRequest({
					method: 'DELETE',
					url: '/store/order',
					isLoading: '加载中...',
					data: {
						order_id: this.id,
					},
					success: res => {
						this.$message.success("删除成功");
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			},
			toChat() {
				let data = {
					type: 1,
					im_user: {
						id: this.details.im_user.id,
						password: "",
						username: this.details.im_user.username,
					},
					user: {
						avatar: this.details.im_user.avatar,
						id: this.details.im_user.id,
						nickname: this.details.im_user.nickname,
					}
				}
				// 跳转聊天
				this.$router.push({
					path: '/message/index',
					query: data
				})
			},
			// 获取详情
			getData() {
				this.$api.tokenRequest({
					method: 'GET',
					url: '/store/order/detail',
					isLoading: '加载中...',
					data: {
						order_id: this.id,
					},
					success: res => {
						if (res.data.status == 10) {
							res.data.status_desc = "1天后自动关闭订单";
							this.stepList[0].date = res.data.create_time.split(" ")[0];
							this.stepList[0].time = res.data.create_time.split(" ")[1];
							this.stepList[1].date = res.data.create_time.split(" ")[0];
							this.stepList[1].time = res.data.create_time.split(" ")[1];
						} else if (res.data.status == 30) {
							res.data.status_desc = "订单已付款，我们会尽快发货运输";
						}
						this.details = res.data;
					},
					fail: data => {
						this.$message.error(data.message);
					},
				})
			}
		},
	};
</script>
<style scoped lang="less">
	.stwpwrap {
		/deep/ .el-col-3 {
			border-right: 1px solid #eeeeee;
			height: 90px;
			box-sizing: border-box;
		}
	}

	/deep/ .el-step__title {
		line-height: inherit;
		font-weight: normal !important;
	}

	.right {
		height: 90px;
		width: 100%;
		box-sizing: border-box;

		.icon {
			width: 24px;
			height: 24px;
		}

		.color-black {
			color: #343434;
		}
	}

	/deep/ .el-step__head {
		margin-left: 16px;
	}

	/deep/ .el-step__line {
		border-bottom: 2px dashed #eeeeee;
		background-color: transparent;
	}

	/deep/ .stepactive .el-step__line {
		border-bottom: 2px dashed #ff3f26;
		background-color: transparent;
	}

	.label {
		color: #999999;
		font-size: 14px;
		width: 120px;
	}

	.icon {
		width: 14px;
		height: 14px;
	}

	.tablewrap {
		border: 1px solid #eeeeee;
		border-radius: 8px;
	}

	.tableheader {
		background: #f8f8f8;
		height: 48px;
		border-radius: 8px;
		box-sizing: border-box;

		.tableheaderone {
			color: #999999;
			line-height: 48px;
			box-sizing: border-box;
		}
	}

	.cover {
		width: 60px;
		height: 60px;
		border-radius: 8px;
	}

	.table {
		border-right: 1px solid #eeeeee;
		box-sizing: border-box;
		height: 100px;
		box-sizing: border-box;
	}

	.changebtn {
		height: 27px;
		width: 68px;
		background: #ff3f26;
		border-radius: 6px;
		border-color: transparent;
		color: #ffffff;
		font-size: 13px;
		line-height: 27px;
		padding: 0;
	}

	.labeltxt {
		width: 150px;
		margin-right: 20px;
	}
</style>
