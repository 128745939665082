<template>
	<div class="content flex-row">
		<div class="messageright padd20 bg-white boderra10">
			<div class="fz-18 font-bold marb14">消息（{{no_read_num}}）</div>
			<div class="infinite-list-wrapper fullh100" style="overflow:auto">
				<div infinite-scroll-disabled="disabled">
					<div class="paddtb20 line-bottom flex-row flex-ju-sb pointer" v-for="(item, index) in list" :key="index"
						@click="checkMessage(item, index)" :class="{ active: record_active == index }">
						<div class="flex-row-center-start">
							<iconImg src="assets/icon_36@2x.png" v-if="item.notice == 1" size="40 40" mode="fit"></iconImg>
							<iconImg src="assets/icon_37@2x.png" v-else-if="item.notice == 2" size="40 40" mode="fit"></iconImg>
							<img class="avatar" :src="item.avatar" alt="" v-else />
							<div class="flex-1 marl12">
								<div class="fz-14 font-bold">{{ item.nickname }}</div>
								<div class="fz-12 color-grey">
									{{ item.type == 1 ? item.content : item.type == 2 ? '[图片]' : item.type == 4 ? '' : '[视频]' }}
								</div>
							</div>
						</div>
						<div class="fz-12 color-grey">{{item.time}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-1 marl20 boderra10 flex-column messageleft" v-if="!is_notice">
			<div class="paddlr24 paddtb18 bg-white top fz-18 font-bold">
				{{ record_title }}
			</div>
			<div class="messagewrap flex-1 chat-area">
				<ul class="infinite-list" v-infinite-scroll="load" style="overflow: auto">
					<div class="list-area">
						<li v-for="(item, index) in record" :key="index"
							:class="{recordright: item.from_uid == userinfo.im_user.id}" class="record flex-row">
							<img class="avatar marrr10" :src="item.avatar" alt="" />
							<div class="txt fz-14" v-if="item.type == 1">{{ item.content }}</div>
							<div class="txt fz-14" v-if="item.type == 2">
								<img class="img-box" :src="item.content" alt="" />
							</div>
							<div class="txt" v-if="item.type == 4">
								<video ref="myVideo" :src="item.content" :controls="true" class="video-box"></video>
							</div>
						</li>
					</div>
				</ul>
			</div>
			<div class="bottom bg-white">
				<el-upload class="avatar-uploader" action="https://admin.yixiangyipin.com/api/upload"
					:show-file-list="false" :on-success="imageSuccess" :before-upload="beforeAvatarUpload">
					<i class="el-icon-picture-outline fz-20"></i>
				</el-upload>

				<el-input type="textarea" class="textwrap" v-model="content" resize="none" placeholder="请输入..." />
				<div class="flex-row-center-end">
					<el-button class="submit fz-14 flex-row-center-center" @click="send">发送</el-button>
				</div>
			</div>
		</div>
		<div v-else class="flex-1 marl20 boderra10 flex-column messageleft">
			<notice ref="notice" :record_title="record_title" />
		</div>
		<detail ref="detailRef" />
	</div>
</template>
<script>
	import notice from "./components/notice.vue";
	import detail from "./components/detail.vue";
	import {
		Bus
	} from 'vue-happy-bus';

	export default {
		components: {
			notice,
			detail,
		},
		data() {
			return {
				chat_id: "", // 聊天id
				no_read_num: 0,
				noticeList: [{
					notice: 1,
					nickname: "官方通知",
					content: "今日好物推荐",
					type: 1,
				}, {
					notice: 2,
					nickname: "系统通知",
					content: "你有最新通知待查看",
					type: 1,
				}],
				list: [],
				content: "",
				record: [],
				record_title: "",
				record_active: 0,
				is_notice: 1, // 是否是通知
				userinfo: "",
			};
		},
		watch: {
			$route: {
				handler: function(val) {
					if (val.query.id) {
						this.record_active = 0;
						this.is_notice = 1;
						this.$nextTick(() => {
							this.$refs.detailRef.show();
						});
					} else if (val.query.uid) {
						this.record_active = 1;
						this.is_notice = 0;
					}
				},
				// 深度观察监听
				deep: true,
				immediate: true,
			},
		},
		mounted() {
			let params = this.$route.query;
			console.log(params)

			// 获取个人信息
			let userinfo = this.$store.state.userinfo;
			this.userinfo = userinfo;

			// 获取最近会话列表
			this.$webSocket.getRecentList();

			// 监听会话列表返回数据
			Bus.$on("onRecentList", res => {
				console.log("最近会话列表", res)
				let num = 0;
				res.forEach(item => {
					num += Number(item.unReadNum);
				})
				this.no_read_num = num;
				if (params.type == 1) {
					this.chat_id = params.im_user.id;
					this.record_active = 2;
					this.is_notice = 0;
					this.record_title = params.user.nickname;
					let arr = [{
						avatar: params.user.avatar,
						nickname: params.user.nickname,
						to_uid: params.im_user.id,
						from_uid: params.im_user.id,
						type: 4,
					}];
					let index = -1;
					res.forEach((item, i) => {
						if (item.from_uid == params.im_user.id || item.to_uid == params.im_user.id) {
							index = i
						}
					})
					if (index == -1) {
						this.list = this.noticeList.concat(arr);
					} else {
						this.list = this.noticeList.concat(this.list);
					}
					this.record_active = Number(index) + 2;
					
					this.list = this.list.concat(res);
					this.getMsgList();
				} else {
					this.list = this.noticeList.concat(res);
				}
			});

			// 监听获取聊天记录数据
			Bus.$on("onMsgRecordHistory", res => {
				console.log("聊天记录", res);
				this.record = this.record.concat(res);
			});

			// 消息发送结果
			Bus.$on("onMsgResult", res => {
				console.log("消息发送结果", res);
				this.content = "";
				this.record.push(res);
			});
		},
		destroyed() {
			Bus.$off("onRecentList");
			Bus.$off("onMsgRecordHistory");
			Bus.$off("onMsgResult");
		},
		methods: {
			checkMessage(item, index) {
				this.chat_id = item.from_uid == this.userinfo.im_user.id ? item.to_uid : item.from_uid;
				this.record_title = item.nickname;
				this.record_active = index;
				if (!item.notice) {
					this.record = [];
					this.is_notice = 0;
					this.getMsgList();
					this.removeRead();
				} else {
					this.is_notice = 1;
					let a = setTimeout(() => {
						clearTimeout(a);
						this.$refs.notice.refreshList(item.notice);
					}, 150);
				}
			},
			load() {
				// 滑动到底部加载
			},
			// 获取聊天记录
			getMsgList() {
				this.$webSocket.getMsgRecordHistory({
					data: {
						to_uid: this.chat_id,
						msg_id: "",
					}
				})
			},
			// 清空未读
			removeRead() {
				this.$webSocket.clearUnReadMsg({
					data: {
						to_uid: this.chat_id,
						msg_id: "",
					}
				})
			},
			// 发送消息
			send() {
				this.$webSocket.sendMsg({
					data: {
						to_uid: this.chat_id,
						type: 1,
						content: this.content,
						msg_id: "",
						ext: "",
					}
				})
			},
			// 上传图片
			imageSuccess(res) {
				this.$webSocket.sendMsg({
					data: {
						to_uid: this.chat_id,
						type: 2,
						content: res.data,
						msg_id: "",
						ext: "",
					}
				})
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG / PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 100%;
		height: 100%;
		box-sizing: border-box;

		.messageright {
			width: 360px;
			box-sizing: border-box;
			height: 100%;

			.active {
				background: #f8f8f8;
			}

			.avatar {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}
		}

		.messageleft {
			box-sizing: border-box;
			height: 100%;

			.top {
				border-radius: 10px 10px 0 0;
			}

			.messagewrap {
				padding: 0 40px;
				background: #f5f5f5;

				.infinite-list {
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					padding-top: 36px;

					.record {
						margin-bottom: 45px;
					}

					.recordright {
						transform: rotateY(180deg);

						.avatar {
							transform: rotateY(180deg);
						}

						.txt {
							transform: rotateY(180deg);
							background: #ff3f26;
							color: #ffffff;
						}
					}

					.avatar {
						width: 40px;
						height: 40px;
						border-radius: 50%;
						margin-right: 8px;
					}

					.txt {
						padding: 12px;
						border-radius: 8px;
						background: #ffffff;
						max-width: 666px;
					}
				}
			}

			.bottom {
				border-radius: 0 0 10px 10px;
				padding: 16px 30px;

				.textwrap {
					font-size: 14px;
					width: 100%;
					height: 100px;
				}

				/deep/.el-textarea__inner {
					height: 100px;
					border-color: transparent;
					padding: 10px 0;
				}

				.submit {
					margin-top: 10px;
					width: 90px;
					height: 32px;
					border-radius: 6px;
					background: #ff3f26;
					color: #ffffff;
					line-height: 32px;
					padding: 0;
					border-color: transparent;
				}
			}
		}
	}

	.chat-area {
		transform: rotate(180deg);
	}

	.list-area {
		transform: rotate(-180deg);
	}
	
	.video-box {
		width: 400px;
		height: 220px;
	}
	
	.img-box {
		width: 333px;
		height: 220px;
		object-fit: contain;
	}
</style>
